.loader{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}


.modal{
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  padding-right: 15px;
}
.fade {
  transition: opacity .15s linear;
}
.modal-body {
position:relative;
padding:0px;
}
.close {
position:absolute;
right:-30px;
top:0;
z-index:999;
font-size:2rem;
font-weight: normal;
color:#fff;
opacity:1;
}




.modal-dialog {
  max-width: 900px;
  margin: 30px auto;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}


button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;


}
button.close :hover{
    color: #fff;
    text-decoration: none;
    opacity: .75;
  }
  @media screen and (max-width: 992px) {
    .close{right:0px;
        top:-30px;}
       
    }