.thnkyou-sec {
    width: 100%;
    text-align: center;
    max-width: 740px;
    margin: 0 auto;
}

.thnkyou-sec img {
    max-width: 100%;
}

.thnkyou-sec h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 90px;
    color: #36204D;
}

.thnkyou-sec p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    text-align: center;
    text-transform: capitalize;
    color: #204D36;
    margin: 50px 0;
}

.thnkyou-sec a {
    display: inline-block;
    background: #36204D;
    border-radius: 19px;
    padding: 10px 30px;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #fff;
}

.thnkyou-sec a:hover {
    background: #204D36;
    color: #F1EBBB;
}
.thnkmrgn {
	margin-bottom: 100px;
	padding-bottom: 100px;
}

@media (max-width: 575px) {
    .thnkyou-sec h3 {
        font-size: 40px;
    }
    .thnkyou-sec p {
        font-size: 22px;
        line-height: 33px;
    }
    .thnkyou-sec a {
        font-size: 20px;
    }
}