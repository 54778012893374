


.impctpe .banner-cap h4 {
	font-size: 45px;
	max-width: 842px;
	line-height: 60px;
}
.scale-info {
	background: #ECF7F2;
	padding: 85px 0;
}

.scale-info h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 55px;
	line-height: 82px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
}

.scalepic img {
	max-width: 100%;
}

.scale-million {
	background: #FFFFFF;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 25px;
	width: 100%;
	padding: 23px 70px 35px 50px;
	display: flex;
	align-items: center;
	margin: 12px 0;
}
.allscale{
	width: 100%;
}

.scaleamunt {
	min-width: 355px;
	margin-right: 20px;
}

.scaleamunt h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 50px;
	line-height: 121%;
	text-transform: capitalize;
	color: #204D36;
	margin: 0;
}

.scale-million p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 130%;
	text-transform: capitalize;
	color: #000000;
	margin: 0;
}


.household-waste {
	width: 100%;
	padding: 100px 0;
}

.household-waste .container {
	max-width: 1310px;
}

.household-inner {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.datainsigt-box {
	max-width: 814px;
	display: flex;
	justify-content: space-between;
	align-items: end;
	width: 100%;
}

.datainsigt-box>div:first-child {
	max-width: 360px;
	position: relative;
}

.datainsigt-box>div:first-child::after {
	content: '';
	width: 60%;
	border-top: 3px dashed rgba(32, 77, 54, 0.35);
	position: absolute;
	top: 52%;
	left: 79%;
}

.datainsigt-box>div:last-child {
	max-width: 327px;
}

.rcyclfst {
	padding-bottom: 69px;
}

.rcyclfst::after {
	content: '';
	width: 105%;
	border-top: 3px dashed rgba(32, 77, 54, 0.35);
	position: absolute;
	top: 33%;
	left: -77%;
}

.rcyclscnd {
	padding-bottom: 46px;
}

.rcyclscnd::after {
	content: '';
	width: 138%;
	border-top: 3px dashed rgba(32, 77, 54, 0.35);
	position: absolute;
	top: 18%;
	left: -107%;
}

.rcyclthird::after {
	content: '';
	width: 105%;
	border-top: 3px dashed rgba(32, 77, 54, 0.35);
	position: absolute;
	top: 18%;
	left: -77%;
}

.husehold-inner p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 34px;
	color: #204D36;
}

.repurposed {
	max-width: 398px;
	position: relative;
	flex: 0 0 398px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

.repurposed .reprecycle {
	position: relative;
}

.repurposed .reprecycle a {
	margin-bottom: 20px;
}

.rghtsidemve {
	max-width: 225px;
	width: 100%;
}

.repurposed::after {
	border-left: 3px dashed rgba(32, 77, 54, .35);
	content: "";
	height: 65%;
	left: 0;
	position: absolute;
	top: 47%;
	transform: translateY(-50%);
}

.reprecycle {
	text-align: center;
}

.reprecycle img {
	max-height: 80px;
}

.reprecycle p {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 23px;
	color: #1D1D1D;
	line-height: 34px;
}

.reprecycle span {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: #204D36;
	opacity: 0.75;
	line-height: 20px;
}

.reprecycle a {
	color: #204d36;
	font-family: Poppins, sans-serif;
	font-weight: 500;
	margin: 0;
	font-size: 23px;
	display: block;
}

.operation-sec{
	overflow: hidden;
}

.operation-sec .container {
	max-width: 1530px;
}

.operation-sec h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 65px;
	line-height: 98px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 50px;
}

.mapcities {
	width: 100%;
	position: relative;
	min-height: 1494px;
	left: -4%;
}

.oprtaionmap {
	width: 100%;
	text-align: center;
	padding-top: 25%;
}

.lcitypic {
	position: relative;
	z-index: 1;
}

.lcitypic img {
	max-width: 100%;
	border-radius: 100%;
}

.all-citis>.row>div {
	width: auto;
}

.all-citis>.row>div:first-child {
	position: absolute;
	left: 22%;
	top: 133px;
}

.fltrbx {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	right: -85%;
	bottom: -100%;
}

.fltrshdw {
	background: #FEFFF9;
	opacity: 0.75;
	width: 419.64px;
	height: 397.5px;
	clip-path: polygon(182px 12px, 0.95% 33.33%, 75.45% 97.23%);

}

.fltrbx2 {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	left: -85%;
	bottom: -100%;
}

.fltrshdw2 {
	background: #FEFFF9;
	opacity: 0.75;
	width: 506.64px;
	height: 397.5px;
	clip-path: polygon(489px 90px, 57.47% -22.77%, 13.38% 94.71%);
}

.fltrbx3 {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	right: -85%;
	bottom: -100%;
}

.fltrshdw3 {
	background: #FEFFF9;
	opacity: 0.75;
	width: 419.64px;
	height: 397.5px;
	clip-path: polygon(182px 12px, 10.01% 43.14%, 89.51% 59.24%);
}

.fltrbx4 {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	left: -85%;
	bottom: -60%;
}

.fltrshdw4 {
	background: #FEFFF9;
	opacity: 0.75;
	width: 700px;
	height: 397px;
	clip-path: polygon(438px 218px, 51.96% -9.43%, 4.69% 47.17%);
}

.fltrbx5 {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	right: -41%;
	top: -70%;
}

.fltrshdw5 {
	background: #FEFFF9;
	opacity: 0.75;
	width: 457.64px;
	height: 430.5px;
	clip-path: polygon(45px 261px, 48.86% 133.41%, 82.44% 45.63%);
}

.fltrbx6 {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	left: -85%;
	bottom: -5%;
}

.fltrshdw6 {
	background: #FEFFF9;
	opacity: 0.75;
	width: 700px;
	height: 397px;
	clip-path: polygon(395px 338px, 63.96% 10.97%, 15.27% 26.77%);
}

.fltrbx7 {
	width: auto;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.70));
	position: absolute;
	right: -27%;
	top: -77%;
}

.fltrshdw7 {
	background: #FEFFF9;
	opacity: 0.75;
	width: 457.64px;
	height: 430.5px;
	clip-path: polygon(0px 429px, 49.29% 65.35%, 2.9% 25.42%);
}

.all-citis>.row>div:first-child .optnicon1 {
	position: absolute;
	left: -41%;
	top: -65%;

}

.all-citis>.row>div:nth-child(2) {
	position: absolute;
	right: 20%;
	top: 7%;
}

.all-citis>.row>div:nth-child(2) .optnicon1 {
	position: absolute;
	right: -14%;
	top: -21%;
}

.all-citis>.row>div:nth-child(3) {
	position: absolute;
	left: 12%;
	top: 34%;
}

.all-citis>.row>div:nth-child(3) .optnicon1 {
	position: absolute;
	left: -41%;
	top: -53%;
}

.all-citis>.row>div:nth-child(4) {
	position: absolute;
	right: 2%;
	top: 28%;
}

.all-citis>.row>div:nth-child(4) .optnicon1 {
	position: absolute;
	right: -27%;
	top: -58%;
}

.all-citis>.row>div:nth-child(5) {
	position: absolute;
	left: 14%;
	bottom: 12%;
}

.all-citis>.row>div:nth-child(5) .optnicon1 {
	position: absolute;
	left: -30%;
	top: -38%;
}

.all-citis>.row>div:nth-child(6) {
	position: absolute;
	right: 13%;
	bottom: 27%;
}

.all-citis>.row>div:nth-child(6) .optnicon1 {
	position: absolute;
	right: -52%;
	top: -10%;
}

.all-citis>.row>div:nth-child(7) {
	position: absolute;
	right: 28%;
	bottom: 6%;
}

.all-citis>.row>div:nth-child(7) .optnicon1 {
	position: absolute;
	right: -44%;
	bottom: -7%;
}


.our-coverage {
	width: 100%;
	background: #fff;
	padding: 164px 0 70px;
	overflow: hidden;
}

.our-coverage .container {
	max-width: 1514px;
}

.our-coverage .row {
	margin: 0 -70px;
	border-bottom: 3px dashed rgba(32, 77, 54, 0.12);
}

.our-coverage .row>div {
	padding: 0 70px;
}

.covgetxt h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 65px;
	line-height: 100px;
	text-transform: capitalize;
	color: #000000;
}

.covgetxt p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 42px;
	color: #676767;
}
.covgetxt a {
	font-family: 'Poppins', sans-serif;
	text-decoration: none;
	background: #36204D;
	border-radius: 22px;
	width: 260px;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 22px;
	color: #FFFFFF;
	margin-top: 30px;
}
.covgetxt a:hover {
	background: #204D36;
}

.coverge-box {
	background: #ECF7F2;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.18);
	padding: 32px;
	margin-bottom: 50px;
}

.coverge-box span {
	text-align: center;
	width: 100%;
	display: block;
	margin-top: -32%;
}

.coverge-box span img {
	max-width: 100%;
}

.coverge-box h4 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 64px;
	text-align: center;
	text-transform: capitalize;
	color: #204D36;
	margin: 0;
}

.cvrgemrgn {
	margin-top: 95px !important;
}

.fstrow>div:first-child .coverge-box span {
	margin-top: -35%;
}

.fstrow>div:first-child .coverge-box figure {
	position: relative;
	margin-top: -62px;
}

.fstrow .coverge-box span {
	margin-top: -28%;
}

.fstrow .coverge-box figure {
	position: relative;
	margin-top: -17px;
}

.fstrow2 {
	margin-top: 90px !important;
}


/* .fstrow2>div:first-child .coverge-box {
	padding-bottom: 80px;
} */

.fstrow2>div:first-child .coverge-box span {
	margin-top: -16%;
}

.fstrow2>div:first-child .coverge-box span img {
	max-width: 264px;
}

.fstrow2>div:first-child .coverge-box figure {
	margin-top: 35px;
}

.fstrow2 .coverge-box figure {
	position: relative;
	margin-top: -30px;
}

.fstrow3 {
	margin-top: 90px !important;
}

.fstrow3>div:first-child .coverge-box span {
	margin-top: -25%;
}

.fstrow3>div:first-child .coverge-box figure {
	margin-top: 15px;
}

.fstrow3 .coverge-box figure {
	position: relative;
	margin-top: -10px;
}

.fstrow3 .coverge-box span {
	margin-top: -28%;
}

.our-coverage .row:last-child {
	border: none;
}


.prtnrship .get-inspired-inner {
	max-width: 1385px;
}

.prtnrship .hersey-box {
	max-width: 666px;
	width: 100%;
}

.prtnrship .hersey-box .inspired-box {
	padding: 30px 85px 30px;
}

.prtnrship .inspired-box p {
	max-width: 514px;
	width: 100%;
}

.prtnrship .kellogs .inspired-box p {
	max-width: 482px;
	width: 100%;
}

.prtnrship .kellogs .inspired-box:last-child {
	width: auto;
	display: inline-block;
	max-width: 592px;
}

.prtnrship h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 98px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 60px;
	padding-left: 0;
}

.funder-sec {
	padding: 30px 0 0;
}

.fundrinfo {
	padding: 10px 0 0;
}

.fndrpic {
	width: 92px;
	height: 92px;
	float: left;
	border-radius: 100%;
	display: none;
}

.fndrpic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 100%;
}

.fundrinfo {
	overflow: hidden;
	padding-left: 5px;
}

.fundrinfo p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 38px;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 10px;
}

.fundrinfo strong {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	text-transform: capitalize;
	color: #000000;
}


.ourground-partner {
	width: 100%;
	padding: 50px 0;
}

.ourground-partner .container {
	max-width: 1600px;
}

.ourground-partner h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 98px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 80px;
}

.groundbox {
	width: 100%;
	padding: 24px 24px 80px;
	background: #FFFFFF;
	border: 1.04713px solid #EDEDED;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
	border-radius: 6.84882px;
	margin-bottom: 32px;
}

.grund-upper {
	text-align: center;
}

.grund-upper figure {
	min-height: 156px;
}

.grund-upper img {
	max-height: 156px;
}

.grund-upper span {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 32px;
	text-transform: capitalize;
	color: #000000;
	display: block;
	margin: 10px 0;
}

.grund-upper svg {
	color: #0096D7;
}

.ground-middle-conent {
	border-top: 0.878241px solid #000000;
	border-bottom: 0.878241px solid #000000;
	padding: 12px 0;
	min-height: 272px;
}

.ground-middle-conent a {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 26px;
	line-height: 40px;
	text-align: center;
	text-decoration-line: underline;
	/* text-transform: capitalize; */
	color: #0096D7;
	display: block;
	margin-bottom: 10px;
}

.ground-middle-conent p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 32px;
	text-align: justify;
	text-transform: capitalize;
	color: #36204D;
}







.testimonial-video {
	width: 100%;
	padding: 60px 0;
}

.testimonial-video .container {
	max-width: 1384px;
}

.testibox {
	background: #FFFFFF;
	box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.15);
	padding: 27px 16px 84px;
	width: 100%;
	margin-bottom: 15px;
}

.testibox video {
	margin-bottom: 55px;
	height: 297px;
}

.testibox p {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 28px;
	line-height: 19px;
	letter-spacing: 0.313548px;
	color: #000000;
	text-align: center;
}

.testimonial-video .owl-stage {
	padding-left: 0 !important;
}

.testimonial-video h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 98px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
}



.process-partner {
	background: #ECF7F2;
	padding: 76px 0;
	overflow: hidden;
}

.process-partner .container {
	max-width: 1482px;
}

.precss-heding h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 98px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 0;
}

.precss-heding p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 44px;
	text-align: center;
	color: #000000;
}

.processing-info {
	width: 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}


.stepinformation {
	max-width: 652px;
}

.steb-by-sec {
	width: 100%;
	margin: 80px 0;
	min-height: 350px;
}
.steb-by-sec:nth-child(2){
	min-height: 466px;
}

.steb-by-sec h4 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 52px;
	color: #204D36;
}

.steb-by-sec p {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 28px;
	line-height: 160%;
	color: #000000;
}

.steb-by-sec ul li {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 22px;
	line-height: 160%;
	color: #000000;
}

.stpbx span {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 34px;
	color: #F9F9F9;
	transform: rotate(-90deg);
	display: inline-block;
	margin-right: -13px;
}

.stpbx strong {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 100px;
	line-height: 130px;
	color: #F9F9F9;
}

.fast-shape {
	position: absolute;
	left: 10%;
}
.fast-shape i{display: none;}

.fast-shape img:nth-child(2) {
	display: none;
}

.fast-shape .stpbx {
	position: absolute;
	top: 22%;
	left: 14%;
	display: flex;
	align-items: center;
}

.fast-shape2 {
	position: absolute;
	left: 26.5%;
	top: 26.5%;
	display: flex;
	align-items: center;
}
.fast-shape2 i{display: none;}
.fast-shape2 img:nth-child(2) {
	display: none;
}

.fast-shape2 .stpbx {
	position: absolute;
	top: 29%;
	left: 9%;
	display: flex;
	align-items: center;
}

.fast-shape3 {
	position: absolute;
	left: 9.5%;
	top: 53.5%;
}
.fast-shape3 i{display: none;}
.fast-shape3 img:nth-child(2) {
	display: none;
}

.fast-shape3 .stpbx {
	position: absolute;
	top: 30%;
	left: 14%;
	display: flex;
	align-items: center;
}

.shparw1 {
	position: absolute;
	left: 37%;
	top: 7%;
}

.shparw1 img:last-child {
	display: none;
}

.shparw2 {
	position: absolute;
	left: 46%;
	top: 35%;
}

.shparw2 img:last-child {
	display: none;
}

.shparw3 {
	position: absolute;
	left: 32%;
	bottom: 17%;
}

.shparw3 img:last-child {
	display: none;
}

.steb-by-sec:nth-child(2) h4 {
	padding-left: 75px;
	color: #36204D;
}


.movement-prt {
	text-align: center;
	padding: 100px 0 300px;
}

.movement-prt h3 {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 45px;
	line-height: 72px;
	text-align: center;
	color: #262626;
	margin-bottom: 15px;
}

.movement-prt a {
	font-family: 'Poppins', sans-serif;
	text-decoration: none;
	background: #36204D;
	border-radius: 22px;
	width: 252px;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 22px;
	color: #FFFFFF;
	margin: 0 auto;
}

.movement-prt a:hover {
	background: #204D36;
}

.hushldnone {
	display: none;
}

.impact-detailsinfo {
	margin: 0 0 50px;
	min-height: 500px;
	width: 100%;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding-top: 30px;
	min-height: 1200px;
}
.impact-detailsinfo .impct-hed {
	max-width: 100%;
}
.impact-detailsinfo .impct-hed h3{
	font-size: 55px;
}
.impact-detailsinfo .impct-hed p {
	font-weight: 400;
	font-size: 25px;
	line-height: 40px;
	text-align: center;
	text-transform: capitalize;
	color: #FFFFFF;
}

.sepratecity {
	margin: 25px 0 0;
	display: none;
	position: relative;
	z-index: 1;
}

.sepratecity p {
	font-weight: 500;
	font-size: 25px;
	line-height: 10px;
	color: #204D36;
	font-family: 'Poppins', sans-serif;
}
.sepratecity figure img{
	max-width: 100%;
}
.sepratecity figure span {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
}
.sepratecity figure span img {
	max-width: 95%;
}
.impctpe{
	padding-bottom: 0 !important;
}
.btomspc{
	margin-bottom: 120px;
}

.dkclumnon{
	display: none;
}
.algnitm{
	align-items: start;
}





/* ______________________ */

@media (max-width: 1800px) {
	.ourground-partner .container {
		max-width: 1320px;
	}

	.ground-middle-conent {
		min-height: 317px;
	}

	.prtnrship .hersey-box {
		max-width: 600px;
	}

	.kellogs {
		max-width: 668px;
	}

	.scaleamunt h3 {
		font-size: 45px;
	}

	.scaleamunt {
		min-width: 320px;
		margin-right: 20px;
	}

	.scale-million p {
		font-size: 21px;
	}

	.our-coverage .container {
		max-width: 1320px;
	}
	.ground-middle-conent p {
		font-size: 20px;
	}
	.ground-middle-conent a {
		font-size: 22px;
	}
	.coverge-box h4 {
		font-size: 35px;
	}
	.operation-sec h2 {
		font-size: 55px;
		line-height: 88px;
	}
	.tones-sec p {
		font-size: 18px;
	}
}

@media (max-width: 1699px) {
	.our-coverage .row {
		margin: 0 -50px;
	}

	.our-coverage .row>div {
		padding: 0 50px;
	}
}

@media (max-width: 1600px) {
	.impctpe .banner-cap h4 {
		font-size: 35px;
	}
	.our-coverage .row {
		margin: 0 -40px;
	}

	.our-coverage .row>div {
		padding: 0 40px;
	}

	.stpbx strong {
		font-size: 100px;
	}
	
}


@media (min-width: 1700px) and (max-width: 1799px) {

/* ____________ */
.scaleamunt {min-width: 295px;margin-right: 20px;}
.scale-million p {font-size: 19px;}


}

@media (min-width: 1600px) and (max-width: 1699px) {

/* ____________ */
.scaleamunt {min-width: 295px;margin-right: 20px;}
.scale-million p {font-size: 19px;}

}

@media (max-width: 1599px) {

/* ____________ */
.scaleamunt {min-width: 290px;}
.scale-million p {font-size: 19px;}

.fltrshdw {width: 402.64px;height: 396.5px;}
.fltrbx {right: -72%; bottom: -93%;}
.fltrbx2 {left: -75%;bottom: -90%;}
.fltrbx4 {left: -76%; bottom: -51%;}
.fltrbx3 {right: -54%; bottom: -35px;}
.fltrshdw3 {width: 360px;height: 397px;clip-path: polygon(178px 170px, 16.68% 88.41%, 100% 100%); }
.fltrbx6 {left: -80%;bottom: 5%;}
.fltrbx5 {right: -31%;top: -101%;}
.fltrshdw5 {clip-path: polygon(70px 383px, 70.27% 100.38%, 82.44% 45.63%); height: 555.5px;}
.fltrshdw7 {clip-path: polygon(6px 429px, 59.12% 71.39%, 8.14% 14.5%);}

.stepinformation {max-width: 616px;}
.steb-by-sec:nth-child(2) h4 {padding-left: 0;}
.steb-by-sec:nth-child(2){padding-left: 100px;}

.impactRedsg::before {height: 146px;}
.impactRedsg {padding-bottom: 130px;}



}

@media (max-width: 1399px) {
	.impctpe .banner-cap h4 {
		font-size: 30px;
	}
	.operation-sec h2 {
		font-size: 55px;
		line-height: 70px;
	}
	.ourground-partner h2 {
		font-size: 55px;
		line-height: 70px;
	}
	.impctpe .banner-cap h4 {
		max-width: 680px;
		line-height: 56px;
		font-size: 34px;
	}
	.our-coverage .container {
		max-width: 1140px;
	}

	.covgetxt h2 {
		font-size: 65px;
		line-height: 90px;
	}

	.covgetxt p {
		font-size: 22px;
		line-height: 32px;
	}

	.coverge-box h4 {
		font-size: 35px;
		line-height: 54px;
	}

	.ground-middle-conent p {
		font-size: 20px;
		line-height: 29px;
	}

	.ground-middle-conent {
		min-height: 270px;
	}

	.ground-middle-conent a {
		font-size: 22px;
		line-height: 36px;
	}

	.prtnrship h2 {
		font-size: 55px;
		line-height: 70px;
	}

	.prtnrship .hersey-box .inspired-box {
		padding: 30px 60px 30px;
	}

	.prtnrship .hersey-box {
		max-width: 520px;
	}

	.kellogs {
		max-width: 574px;
	}

	.precss-heding h2 {
		font-size: 55px;
		line-height: 70px;
	}

	.precss-heding p {
		font-size: 29px;
		line-height: 48px;
	}

	.steb-by-sec p {
		font-size: 20px;
		;
	}

	.steb-by-sec ul li {
		font-size: 20px;
	}

	.shparw1 {
		top: 8%;
	}

	.shparw1 img {
		max-width: 77%;
	}

	.shparw2 {
		left: 45%;
		top: 36%;
	}

	.shparw2 img {
		max-width: 77%;
	}

	.shparw3 img {
		max-width: 77%;
	}

	.fast-shape img {
		max-width: 85%;
	}

	.fast-shape2 img {
		max-width: 85%;
	}
	.fast-shape2 {
		top: 27%;
		left: 26%;
	}

	.fast-shape3 img {
		max-width: 85%;
	}

	.steb-by-sec h4 {
		font-size: 32px;
	}

	.testimonial-video h2 {
		font-size: 55px;
	}

	.movement-prt {
		text-align: center;
		padding: 70px 0 150px;
	}

	.testibox {
		padding: 27px 16px 50px;
	}

	.fundrinfo p {
		font-size: 24px;
		line-height: 35px;
		margin-bottom: 0;
	}

	.fundrinfo strong {
		font-size: 20px;
		line-height: 36px;
	}

	.get-inspired {
		padding: 86px 0 60px;
	}

	.ourground-partner .container {
		max-width: 1140px;
	}

	.testimonial-video .container {
		max-width: 1140px;
	}

	.testibox p {
		font-size: 25px;
	}

	.movement-prt h3 {
		font-size: 45px;
	}
	.fltrbx2 {
		left: -85%;
		bottom: -149%;
	}
	.fltrshdw2 {
		clip-path: polygon(447px 38px, 54.90% -23.52%, 21.47% 85.65%);
	}

	.fltrshdw {
		clip-path: polygon(138px 76px, 0.95% 47.42%, 66.87% 90.44%);
	}
	.all-citis > .row > div:first-child {
		left: 21%;
		top: 209px;
	}
	

	.fltrshdw3 {
		clip-path: polygon(134px 78px, 6.91% 53.96%, 76.64% 72.07%);
	}

	.fltrshdw4 {
		clip-path: polygon(438px 253px, 53.82% 4.42%, 13.13% 61.52%);
	}

	.fltrshdw6 {
		clip-path: polygon(386px 354px, 65.96% 26.08%, 21.41% 36.34%);
	}

	.fltrshdw7 {
		clip-path: polygon(8px 309px, 53.44% 61.87%, 13.82% 17.52%);
	}

	.fltrshdw5 {
		clip-path: polygon(18px 209px, 37.93% 105.07%, 71.96% 35.41%);
	}
	.all-citis > .row > div:nth-child(6) {
		bottom: 32%;
	}
	.all-citis > .row > div:nth-child(5) {
		bottom: 21%;
	}
	.all-citis > .row > div:nth-child(7) {
		right: 29%;
		bottom: 15%;
	}

	.household-waste .container {
		max-width: 1140px;
	}

	.husehold-inner p {
		font-size: 20px;
	}

	.datainsigt-box>div:first-child {
		max-width: 279px;
		position: relative;
	}

	.datainsigt-box>div:first-child::after {
		width: 52%;
		left: 98%;
	}

	.husehold-inner p {
		padding: 0 15px;
	}

	.lcitypic img {
		max-width: 80%;
		border-radius: 100%;
	}
	.steb-by-sec {
		min-height: 246px;
	}
	.steb-by-sec:nth-child(2) {
		min-height: 362px;
	}
	

/* ____________ */
.scaleamunt {min-width: 240px;}
.scale-million p {font-size: 17px;}
.scaleamunt h3 {font-size: 36px;}
.scale-million {padding: 25px 55px 25px 40px;}


.all-citis > .row > div:nth-child(4) .optnicon1 {right: -19%; top: -50%;}
.oprtaionmap img{width: 580px;}
.all-citis > .row > div:nth-child(4) {right: -2%;top: 30%;}
.fltrshdw4 {clip-path: polygon(347px 270px, 48.39% 11.47%, 12.13% 44.9%);}
.fltrbx2 {bottom: -100%;}
.fltrshdw2 {clip-path: polygon(440px 145px, 50.75% 20.00%, 31.54% 97.73%);}
.fltrshdw {clip-path: polygon(161px 102px, 6.41% 52.97%, 63.64% 81.61%);}
.fltrshdw3 {clip-path: polygon(137px 205px, 17.75% 90.49%, 78.03% 90.71%);}
.all-citis > .row > div:nth-child(6) .optnicon1 {right: -25%;top: -12%;}
.all-citis > .row > div:nth-child(6) {bottom: 33%; right: 7%;}
.fltrshdw6 {clip-path: polygon(358px 369px, 69.39% 33.38%, 16.84% 29.04%);}
.all-citis > .row > div:nth-child(5) {bottom: 25%;left: 11%;}
.fltrshdw5 {clip-path: polygon(59px 300px, 51.91% 90.31%, 75.46% 41.17%);}
.all-citis > .row > div:nth-child(7) .optnicon1 {right: -27%;bottom: -8%;width: 360px;}
.fltrshdw7 {clip-path: polygon(4px 329px, 57.59% 69.07%, 21.25% -0.83%);}

.stepinformation {max-width: 530px;}
.steb-by-sec:nth-child(2) { padding-left: 60px;}
.steb-by-sec:nth-child(3){margin-left: -60px;}

.impactRedsg::before {height: 115px;}
.impactRedsg {padding-bottom: 100px;}



}

@media (max-width: 1199px) {

/* ____________ */
.scaleamunt h3 {font-size: 30px;}
.scale-million {padding: 22px 45px 22px 35px; border-radius: 17px; }
.scaleamunt {min-width: 200px;}
.scale-million p { font-size: 15px; line-height: 22px;}

.all-citis > .row > div:nth-child(2) .optnicon1 {right: 12%; top: -23%; width: 130px;}
.all-citis > .row > div:nth-child(2) {right: 13%; top: 12%;}
.fltrshdw2 {clip-path: polygon(402px 193px, 51.54% 23.53%, 30.16% 87.41%);}
.all-citis > .row > div:first-child {left: 13%; top: 13%;}
.fltrshdw {clip-path: polygon(141px 143px, 8.65% 60.29%, 68.36% 92.21%);}
.all-citis > .row > div:nth-child(4) {right: -1%; top: 30%;}
.fltrshdw4 {clip-path: polygon(341px 286px, 39.24% 33.39%, 28.28% 65.3%);}
.all-citis > .row > div:nth-child(3) {left: 9%;top: 40%;}
.fltrshdw3 {clip-path: polygon(124px 226px, 18.02% 90.73%, 64.42% 89.2%);}
.lcitypic img {max-width: 70%;}
.mapcities {min-height: 1200px;left: auto;}
.all-citis > .row > div:first-child .optnicon1 {width: 220px;}
.all-citis > .row > div:nth-child(5) .optnicon1 {left: -37%;top: -37%;width: 275px;}
.all-citis > .row > div:nth-child(3) .optnicon1 {left: -35%;top: -52%;width: 190px;}
.all-citis > .row > div:nth-child(7) .optnicon1 {right: -2%; bottom: -7%; width: 235px;}
.all-citis > .row > div:nth-child(6) .optnicon1 {right: -9%; top: -15%; width: 245px;}
.all-citis > .row > div:nth-child(7) {right: 18%; bottom: 3%;}
.all-citis > .row > div:nth-child(5) {bottom: 16%; left: 9%;}
.all-citis > .row > div:nth-child(6) {bottom: 29%;right: -3%;}
.fltrshdw5 {clip-path: polygon(58px 258px, 44.92% 78.79%, 61.48% 39.19%);}
.fltrshdw6 {clip-path: polygon(371px 388px, 62.25% 41.19%, 15.84% 53.22%);}
.fltrshdw7 {clip-path: polygon(2px 306px, 47.76% 55.37%, 7.27% -2.22%);}

.fast-shape2 {left: 30%;}
.stepinformation {max-width: 400px;}
.steb-by-sec {margin: 77px 0;}

.impactRedsg::before {height: 90px;}
.impactRedsg {padding-bottom: 60px;}


}

@media (max-width: 991px) {

.oprtaionmap {padding-top: 15%;}
.lcitypic img {max-width: 60%;}
.all-citis > .row > div:nth-child(2) .optnicon1 {right: 20%; top: -25%; width: 120px;}
.all-citis > .row > div:first-child .optnicon1 {width: 190px; left: -32%; top: -66%;}
.oprtaionmap img {width: 480px;}
.all-citis > .row > div:nth-child(3) .optnicon1 {left: -30%; top: -50%; width: 170px;}
.all-citis > .row > div:nth-child(5) .optnicon1 {left: -22%; top: -45%; width: 225px;}
.all-citis > .row > div:nth-child(7) .optnicon1 {right: 11%;bottom: -10%; width: 210px;}
.all-citis > .row > div:nth-child(4) .optnicon1 {right: 20%;top: -73%;width: 200px;}
.all-citis > .row > div:nth-child(6) .optnicon1 {right: 5%; top: -10%; width: 220px;}
.mapcities {min-height: 1000px;}
.all-citis > .row > div:first-child {left: 9%; top: 2%;}
.all-citis > .row > div:nth-child(2) {right: 5%; top: 4%;}
.all-citis > .row > div:nth-child(3) {left: 4%; top: 39%;}
.all-citis > .row > div:nth-child(5) { bottom: 18%; left: 6%;}
.all-citis > .row > div:nth-child(7) {right: 7%; bottom: 10%;}
.all-citis > .row > div:nth-child(4) {right: -15%; top: 28%;}
.all-citis > .row > div:nth-child(6) {bottom: 31%; right: -11%;}
.fltrshdw {clip-path: polygon(117px 171px, 7.16% 66.34%, 57.93% 96.75%);}
.fltrshdw2 {clip-path: polygon(376px 226px, 52.33% 30.83%, 35.29% 84.64%);}
.fltrshdw3 {clip-path: polygon(70px 233px, 26.35% 89.22%, 56.09% 68.04%);}
.fltrshdw4 {clip-path: polygon(297px 290px, 44.38% 34.15%, 26.71% 50.94%);}
.fltrshdw6 {clip-path: polygon(346px 376px, 61.68% 49.75%, 25.55% 43.14%);}
.fltrshdw5 {clip-path: polygon(26px 231px, 39.02% 64.39%, 49.24% 21.37%);}
.fltrbx7 {top: -92%;}
.fltrshdw7 {clip-path: polygon(1px 293px, 41.86% 56.07%, 5.52% 2.43%);}

.fast-shape img, .fast-shape2 img, .fast-shape3 img {max-width: 70%;}
.fast-shape {left: 5%;}
.stpbx strong {font-size: 65px;}
.stpbx span {font-size: 20px; line-height: 40px;}
.fast-shape3 {left: 5%; top: 56%;}
.fast-shape2 {left: 29%; top: 28.5%;}
.steb-by-sec p {font-size: 17px;}
.steb-by-sec h4 {font-size: 25px;}
.shparw1 {top: 8%;left: 37%; width: 145px;}
.stepinformation {max-width: 340px;}
.steb-by-sec:nth-child(3){margin-top: 0;}
.shparw2 {left: 54%; top: 36%; width: 118px;}
.steb-by-sec:nth-child(2) {min-height: 175px;}
.shparw3 {left: 28%; bottom: 25%; width: 140px;}


.impactRedsg { padding-bottom: 40px; margin: 0;}
.impactRedsg::before {height: 70px;}




}

@media (max-width: 767px) {

/* ____________ */
.scale-million {padding: 20px 40px 20px 30px; border-radius: 15px;}

.oprtaionmap img {width: 410px;}
.lcitypic img {max-width: 47%;}
.mapcities {min-height: 850px;}
.all-citis > .row > div:nth-child(2) .optnicon1 {right: 35%; top: -24%; width: 95px;}
.all-citis > .row > div:first-child .optnicon1 { width: 150px; left: -27%; top: -65%;}
.all-citis > .row > div:nth-child(4) .optnicon1 {right: 30%; top: -75%; width: 170px;}
.all-citis > .row > div:nth-child(3) .optnicon1 {left: -25%;top: -60%;width: 145px;}
.all-citis > .row > div:nth-child(6) .optnicon1 {right: 22%; top: -11%; width: 180px;}
.all-citis > .row > div:nth-child(7) .optnicon1 {right: 28%; bottom: -10%; width: 180px;}
.all-citis > .row > div:nth-child(5) .optnicon1 {left: -17%; top: -50%; width: 185px;}
.all-citis > .row > div:nth-child(2) {right: -11%; top: 5%;}
.all-citis > .row > div:first-child {left: 6%;top: 7%;}
.all-citis > .row > div:nth-child(4) {right: -30%; top: 25%;}
.all-citis > .row > div:nth-child(6) { bottom: 36%; right: -26%;}
.all-citis > .row > div:nth-child(7) {right: -6%; bottom: 17%;}
.all-citis > .row > div:nth-child(5) { bottom: 22%; left: 4%;}
.fltrshdw {clip-path: polygon(91px 221px, 7.66% 74.66%, 44.52% 87.92%);}
.fltrshdw2 {clip-path: polygon(342px 267px, 51.74% 47.43%, 35.29% 87.16%);}
.fltrshdw4 {clip-path: polygon(292px 315px, 41.52% 49.01%, 30.71% 66.81%);}
.fltrshdw3 {clip-path: polygon(54px 260px, 24.41% 87.20%, 37.2% 71.06%);}
.fltrshdw6 {clip-path: polygon(338px 379px, 58.25% 60.58%, 31.41% 59.52%);}
.fltrshdw5 {clip-path: polygon(20px 180px, 29.62% 49.27%, 35.25% 17.59%);}
.fltrshdw7 {clip-path: polygon(2px 222px, 33.56% 45.85%, 8.36% 7.31%);}


.fast-shape img, .fast-shape2 img, .fast-shape3 img {max-width: 50%;}
.stpbx strong {font-size: 40px; line-height: 45px; }
.stpbx span { font-size: 15px;  line-height: 20px; margin: 0 -4px 0 0;}
.fast-shape .stpbx {top: 35%; left: 11%;}
.fast-shape2 .stpbx {top: 39%; left: 8%;}
.fast-shape3 .stpbx {top: 36%; left: 11%;}
.steb-by-sec p {font-size: 16px;}
.steb-by-sec {min-height: 155px;}
.fast-shape {left: 0%;}
.fast-shape3 {left: 0%; top: 65%;}
.fast-shape2 {left: 23%;top: 31.4%;}
.steb-by-sec {margin: 25px 0;}
.stepinformation {max-width: 290px;}
.shparw1 {top: 8%; left: 27%; width: 116px; }
.shparw2 {left: 40%;top: 37%;width: 105px;}
.steb-by-sec:nth-child(3) {margin-left: -5px; margin-bottom: 0;}
.shparw3 {left: 26%; bottom: 15%; width: 120px; }


.impactRedsg {padding-bottom: 30px;}
.impactRedsg::before {height: 55px;}




}

@media (max-width: 575px) {
	.dkclumnon{
		display: block;
	}
	.operation-sec h2 {
		font-size: 35px;
		line-height: 45px;
	}

	.all-citis>.row>div {
		width: auto;
		position: relative !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
	}

	.oprtaionmap {
		padding-top: 0;
		margin-bottom: 60px;
	}

	.mapcities {
		left: 0;
	}

	.fltrbx,
	.fltrbx2,
	.fltrbx3,
	.fltrbx4,
	.fltrbx5,
	.fltrbx6,
	.fltrbx7 {
		display: none;
	}

	.lcitypic img {
		border-radius: 0;
	}

	.optnicon1 {
		position: absolute;
		left: auto !important;
		top: auto !important;
		right: 0;
		bottom: 0;
	}

	.location-box {
		position: relative;
	}

	.covgetxt h2 {
		font-size: 35px;
		line-height: 52px;
	}

	.covgetxt h2 br {
		display: none;
	}

	.covgetxt p {
		font-size: 18px;
		line-height: 27px;
	}

	.coverge-box h4 {
		font-size: 12px;
		line-height: 18px;
	}

	.ourground-partner h2 {
		font-size: 25px;
		line-height: 38px;
		margin-bottom: 24px;
	}

	.grund-upper span {
		font-size: 10px;
		line-height: 15px;
	}

	.ground-middle-conent a {
		font-size: 12px;
		line-height: 19px;
	}

	.ground-middle-conent p {
		font-size: 12px;
		line-height: 19px;
	}

	.ground-middle-conent {
		min-height: auto;
	}

	.groundbox {
		padding: 24px 24px 40px;
		margin-bottom: 20px;
	}

	.grund-upper figure {
		min-height: 74px;
	}

	.grund-upper img {
		max-height: 74px;
	}

	.prtnrship h2 {
		font-size: 25px;
		line-height: 38px;
		margin-bottom: 35px;
	}

	.prtnrship .hersey-box {
		padding-top: 0;
	}

	.prtnrship .hersey-box .inspired-box {
		padding: 20px 30px 30px;
	}

	.prtnrship .inspired-box p {
		font-size: 15px;
		line-height: 23px;
	}

	.prtnrship .cmtsxty {
		left: -27px;
	}

	.fndrpic {
		width: 50px;
		height: 50px;
	}

	.fundrinfo strong {
		font-size: 13px;
		line-height: 19px;
	}

	.fundrinfo {
		padding: 4px 0 0;
	}

	.precss-heding h2 {
		font-size: 25px;
		line-height: 38px;
	}

	.precss-heding p {
		font-size: 18px;
		line-height: 27px;
	}

	.fast-shape {
		position: relative;
		left: -24px;
		margin-bottom: 50px;
	}
	.fast-shape i {
		display: block;
		position: absolute;
		right: 0;
		bottom: -26px;
	}
	.fast-shape i img {
		display: block !important;
	}

	.fast-shape2 {
		position: relative;
		left: -24px;
		margin-bottom: 50px;
	}
	.fast-shape2 i {
		display: block;
		position: absolute;
		left: 0;
		bottom: -30px;
	}
	.fast-shape2 i img {
		display: block !important;
	}
	.fast-shape3 {
		position: relative;
		left: -24px;
		margin-bottom: 50px;
	}
	.fast-shape3 i {
		display: block;
		position: absolute;
		right: 0;
		bottom: -30px;
	}
	.fast-shape3 i img {
		display: block !important;
	}

	.stpbx strong {
		font-size: 53px;
	}

	.stpbx span {
		font-size: 12px;
		margin-right: -5px;
	}

	.steb-by-sec p {
		font-size: 18px;
	}

	.steb-by-sec ul li {
		font-size: 18px;
	}

	.steb-by-sec h4 {
		font-size: 45px;
		position: absolute;
		left: 0;
		top: 3%;
		padding-left: 160px;
	}

	.fast-shape img:nth-child(1) {
		display: none;
	}

	.fast-shape img:nth-child(2) {
		display: block;
	}

	.fast-shape2 img:nth-child(1) {
		display: none;
	}

	.fast-shape2 img:nth-child(2) {
		display: block;
	}

	.fast-shape3 img:nth-child(1) {
		display: none;
	}

	.fast-shape3 img:nth-child(2) {
		display: block;
	}

	.steb-by-sec {
		margin: 40px 0 60px;
		padding-bottom: 15px;
		position: relative;
		/* border-bottom: 2px dashed rgba(32, 77, 54, 0.12); */
	}

	.steb-by-sec:last-child {
		border: none;
	}

	.shparw1 {
		top: -10%;
	}

	.shparw1 img {
		max-width: 100%;
	}

	.shparw2 img {
		max-width: 100%;
	}

	.shparw3 img {
		max-width: 100%;
	}

	.fast-shape .stpbx {
		top: 1%;
		left: 6%;
	}

	.steb-by-sec:nth-child(2) h4 {
		padding-left: 177px;
	}

	.fast-shape2 .stpbx {
		top: 5%;
		left: 7%;
	}

	.fast-shape3 .stpbx {
		top: 4%;
		left: 7%;
	}

	.shparw1 img:first-child {
		display: none;
	}

	.shparw1 img:last-child {
		display: block;
	}

	.shparw2 img:first-child {
		display: none;
	}

	.shparw2 img:last-child {
		display: block;
	}

	.shparw3 img:first-child {
		display: none;
	}

	.shparw3 img:last-child {
		display: block;
	}

	.shparw2 {
		left: 37%;
		top: -5%;
	}

	.shparw3 {
		left: 37%;
		bottom: auto;
		top: -3%;
	}

	.stepinformation {
		/* border: 2px dashed rgba(32, 77, 54, 0.12); */
		padding: 10px;
	}

	.scale-info h2 {
		font-size: 25px;
		line-height: 38px;
	}

	.scale-million {
		display: block;
		text-align: center;
		box-shadow: none;
		padding: 0;
		margin: 20px 0;
	}
	.allscale {
		background: #FFFFFF;
		box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
		border-radius: 25px;
		width: 100%;
		text-align: center;
		padding: 23px 50px 35px;
		position: relative;
		margin-top: -90px;
	}
	.btomspc {
		margin-bottom: 40px;
		padding-top: 50px;
	}

	.scaleamunt {
		min-width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
	}

	.scaleamunt h3 {
		font-size: 35px;
	}

	.scale-million p {
		font-size: 18px;
		padding: 0 19px;
	}

	.household-inner {
		display: none;
	}

	.hushldnone {
		display: block;
	}

	.hushldnone .withbntix-sec {
		box-shadow: none;
		background: none;
	}

	.household-waste {
		padding: 50px 0;
	}

	.impact-detailsinfo {
		min-height: 1400px;
	}

	.impact-detailsinfo {
		margin: 0;
	}

	.all-citis {
		display: none;
	}
	.sepratecity {
		display: block;
	}
	.coverge-box {
		margin-top: 80px;
	}
	.coverge-box {
		padding: 16px;
	}
	.coverge-box span img {
		width: auto !important;
		margin: 0 auto;
		max-width: 80%;
	}
	.coverge-box span {
		margin-top: -52%;
		position: relative;
	}

	.movement-prt h3 {
		font-size: 25px;
		line-height: 38px;
	}
	.testimonial-video h2 {
		font-size: 25px;
	}
	.testibox p {
		font-size: 19px;
	}
	.testibox video {
		margin-bottom: 35px;
		height: 185px;
	}
	.testibox {
		padding: 27px 16px 50px;
	}
	.testibox {
		padding: 16px 16px 30px;
	}
	.impctpe .banner-cap h4 {
		max-width: 680px;
		line-height: 34px;
		font-size: 22px;
	}

	.our-coverage .row {
		margin: 0 -5px;
	}
	.our-coverage .row > div {
		padding: 0 5px;
	}
	.our-coverage .row > div:nth-child(3) {
		margin-top: -64px;
	}
	.coverge-box {
		margin-top: 38px;
		box-shadow: 0px 1.05985px 5.29924px rgba(0, 0, 0, 0.18);

	}
	.covgetxt{
		padding-bottom: 60px;
	}
	.our-coverage .row > div:nth-child(6) figure{
		margin-top: 15px;
	}
	.our-coverage .row > div:nth-child(7) figure{
		margin-top: -15px;
	}
	.our-coverage .row > div:nth-child(8) figure{
		margin-top: 15px;
	}
	.our-coverage .row > div:nth-child(9) figure{
		margin-top: 15px;
	}
	.our-coverage .row > div:nth-child(9) {
		margin-top: -64px;
	}
	.our-coverage {
		padding: 80px 0 50px;
	}

	.showall-city .sepratecity:first-child figure{
		display: flex;
		justify-content: flex-start;
		position: relative;
		left: -50px;
	}
	.showall-city .sepratecity:first-child figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:first-child figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:first-child figure span {
		position: absolute;
		right: 70px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:first-child p{
		margin-bottom: 30px;
	}
	.showall-city .sepratecity:nth-child(2) figure{
		display: flex;
		justify-content: flex-end;
		position: relative;
		right: -50px;
	}
	.showall-city .sepratecity:nth-child(2) figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:nth-child(2) figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:nth-child(2) figure span {
		position: absolute;
		left: 20px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:nth-child(2) p{
		margin-bottom: 30px;
		text-align: right;
	}

	.showall-city .sepratecity:nth-child(3) figure{
		display: flex;
		justify-content: flex-start;
		position: relative;
		left: -50px;
	}
	.showall-city .sepratecity:nth-child(3) figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:nth-child(3) figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:nth-child(3) figure span {
		position: absolute;
		right: 20px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:nth-child(3) p{
		margin-bottom: 30px;
	}

	.showall-city .sepratecity:nth-child(4) figure{
		display: flex;
		justify-content: flex-end;
		position: relative;
		right: -50px;
	}
	.showall-city .sepratecity:nth-child(4) figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:nth-child(4) figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:nth-child(4) figure span {
		position: absolute;
		left: 60px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:nth-child(4) p{
		margin-bottom: 30px;
		text-align: right;
	}

	.showall-city .sepratecity:nth-child(5) figure{
		display: flex;
		justify-content: flex-start;
		position: relative;
		left: -50px;
	}
	.showall-city .sepratecity:nth-child(5) figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:nth-child(5) figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:nth-child(5) figure span {
		position: absolute;
		right: 20px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:nth-child(5) p{
		margin-bottom: 30px;
	}

	.showall-city .sepratecity:nth-child(6) figure{
		display: flex;
		justify-content: flex-end;
		position: relative;
		right: -50px;
	}
	.showall-city .sepratecity:nth-child(6) figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:nth-child(6) figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:nth-child(6) figure span {
		position: absolute;
		left: -28px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:nth-child(6) p{
		margin-bottom: 30px;
		text-align: right;
	}

	.showall-city .sepratecity:nth-child(7) figure{
		display: flex;
		justify-content: flex-start;
		position: relative;
		left: -80px;
	}
	.showall-city .sepratecity:nth-child(7) figure img{
		max-width: 220px;
		border-radius: 100%;
	}
	.showall-city .sepratecity:nth-child(7) figure span img{
		max-width: 100%;
		border-radius: 0;
	}
	.showall-city .sepratecity:nth-child(7) figure span {
		position: absolute;
		right: -60px;
		top: -8px;
		z-index: -1;
	}
	.showall-city .sepratecity:nth-child(7) p{
		margin-bottom: 30px;
	}

	.impact-detailsinfo .impct-hed p{
		display: none;
	}
	.impact-detailsinfo {
		min-height: 900px;
	}
	.covgetxt a {
		border-radius: 13px;
		width: 177px;
		height: 48px;
		font-size: 14px;
	}
	.impact-detailsinfo .impct-hed h3 {
		font-size: 30px;
	}
	.tones-sec {
		margin-bottom: 64px;
	}
	.movement-prt {
		text-align: center;
		padding: 20px 0 150px;
	}
	.movement-prt a {
		border-radius: 17px;
		width: 224px;
		height: 65px;
		font-size: 20px;
	}
	.get-inspired {
		padding: 0 0 60px;
		margin-top: 50px;
	}


/* _________________ */

.stepinformation {max-width: 100%;}
.steb-by-sec:nth-child(2) {padding-left: 0;}
.fast-shape2 .stpbx, .fast-shape .stpbx, .fast-shape3 .stpbx {top: 33%; left: 5%;}

.impactRedsg {padding-bottom: 25px; min-height: auto !important;}





}

@media (max-width: 380px){
.steb-by-sec h4 {
	font-size: 35px;
}
.shparw1 {
	top: -5%;
	left: 33%;
}
.banner-cap h1 {
	font-size: 32px;
}
.impctpe .banner-cap h4 {
	padding: 0;
}
.banner-cap h1 {
	font-size: 35px;
}

/* _________________ */


}