


.ourdatabnr .banner-cap a {
    padding: 10px 70px;
    border-radius: 7px;
}
/* .ourdatabnr .banner-cap h1 {
	font-size: 80px;
	line-height: 121%;
} */
.uniquness-sec {
    width: 100%;
    padding: 50px 0;
}
.dataviw-dkstp{width: 100%;}

.uniquness-sec .container {
    max-width: 1387px;
}

.uniquness-sec h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    text-transform: capitalize;
    color: #262626;
    text-align: center;
    margin-bottom: 68px;
}

.uniqunes-inner {
    background: #FFFFFF;
    box-shadow: 5.5877px 5.5877px 16.7631px 2.23508px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 27px 25px 15px;
    text-align: center;
    min-height: 444px;
    margin-bottom: 50px;
}
.dataviw-dkstp > .row > div{padding: 0 39px;}

.uniqunes-inner figure {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: #FEFFF9;
    box-shadow: inset 4px 5px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.uniqunes-inner figure img {
    max-width: 54%;
}

.uniqunes-inner figcaption p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #323232;
    margin-bottom: 5px;
}

.uniqunes-inner figcaption span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #323232;
}

.evryprdct-content {
    padding-left: 188px;
    position: relative;
    z-index: 1;
}

.evryprdct-content h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 145.2%;
    color: #341F4B;
    max-width: 430px;
}

.evryprdct-content img {
    position: absolute;
    bottom: -93px;
    margin-top: 0;
    z-index: -1;
    right: 168px;
}

.evrdypic img {
    max-width: 100%;
}

.unlok-sec {
    width: 100%;
    text-align: center;
    padding: 130px 0 112px;
}

.unlok-sec h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 139.2%;
    text-align: center;
    color: #000000;
}

.unlok-txt {
    max-width: 1340px;
    margin: 0 auto;
}


.precision-sec {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    background: #36204D;
    padding: 100px;
    position: relative;
}

.prcsion-code {
    position: absolute;
    bottom: 12px;
    left: 0;
}

.prcsion-code img {
    max-width: 100%;
}

.prcsion-txt {
    /* max-width:30%; */
    align-self: flex-start;
    top: 120px;
    position: -webkit-sticky;
    position: sticky;
    /* flex-basis: 30%; */
}

.prcsion-txt p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 139.2%;
    color: #FFFFFF;
}

.prcsion-txt a {
    border: 5px solid #FF8F66;
    border-radius: 40px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 139.2%;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 35px;
    margin-top: 20px;
    display: inline-block;
}

.prcsion-txt a:hover {
    border: 5px solid #FF8F66;
    background: #FF8F66;
}


.erlylunch-sec {
	max-width: 1140px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.erlyinfo {
	padding: 10px 15px;
	border-right: 1.5px solid #FFFFFF;
	width: 48%;
    margin-bottom: 30px;
}
.erlyinfo:nth-child(even){
    border-right: none;
}
/* .erlyinfo:nth-child(2){
    border: none;
} */

/* .erlyinfo:first-child {
    padding-top: 0;
} */

.erlyinfo span {
    font-family: 'Bakbak One', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 85px;
    line-height: 139.2%;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #E6E6E6;
    color: transparent;
}

.erlyinfo p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 139.2%;
    color: #FAF8FC;
}

.mapdata {
    text-align: center;
    padding-top: 68px;
}

.mapdata img {
    max-width: 100%;
}

.data-coverge-sec h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 82px;
    text-align: center;
    color: #341F4B;
    margin-bottom: 104px;
}
.national-coverge{
    margin-bottom: 50px;
}
.national-coverge h4 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 14px;
    color: #36204D;
    position: relative;
    display: inline-block;
    z-index: 1;
    margin-bottom: 60px;
}
/* .national-coverge h4::after {
	background: #D3B2DD;
	height: 6px;
	width: 240px;
	content: '';
	position: absolute;
	right: -6%;
	bottom: -10px;
	z-index: -1;
} */
.covaregae-info>.row>div {
    margin: 15px 0;
}

.cvrge-inner {
    width: 100%;
    padding-left: 50px;
}

.cvrge-inner h5 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #36204D;
    position: relative;
    z-index: 1;
    padding-left: 50px;
}

.cvrge-inner h5::before {
    width: 30px;
    height: 30px;
    background: #D3B2DD;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 5px;
    content: '';
    z-index: -1;
}

.insight-offer {
    width: 100%;
    padding:160px 0 50px 0;
}
.harbingtxt{
    padding: 20px 0 0 60px;
}
.harbingtxt .cvrge-inner{
    margin: 8px 0;
}

.harbingtxt .cvrge-inner h5::before{
    width: 25px;
    height: 25px;
}
.harbingtxt .cvrge-inner h5 {
    font-size: 25px;
}

.cvrge-inner h5 sub {
    bottom: 4px;
    font-size: 20px;
}

.exclusive-sec {
    width: 100%;
    padding-top: 70px;
}



.exclusive-sec p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 56px;
    color: #36204D;
    position: relative;
    z-index: 1;
    padding-left: 20px;
    margin-bottom: 40px;
}

.exclusive-sec p::before {
    background: #D3B2DD;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: -3px;
    content: '';
    z-index: -1;
}




.insight-offer .container {
    max-width: 1468px;
}

.insight-offer h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 75px;
    text-align: center;
    color: #341F4B;
    margin-bottom: 40px;
}

.offer-inner>.row {
    margin: 0 -24px;
}

.offer-inner>.row>div {
    padding: 0 24px;
}

.trax-box {
    position: relative;
    padding: 10px 18px 10px 125px;
    background: #351F4B;
    border-radius: 10px;
    min-height: 274px;
    display: flex;
    align-items: center;
    margin: 23px 0;
}

.trax-box h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    color: #FFFFFF;
}

.trax-box h5 strong {
    font-size: 35px;
    color: #EBBBF1;
}

.trax-box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.ofricon {
    width: 125px;
    height: 125px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -28px;
    background: #DEF0FF;
    box-shadow: 4.31507px 4.31507px 17.2603px rgba(222, 240, 255, 0.25), inset 4.31507px 4.31507px 21.5753px rgba(54, 32, 77, 0.3), inset -8.63014px -8.63014px 12.9452px rgba(54, 32, 77, 0.35);
}

.ofricon img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.lovedclient {
    width: 100%;
    padding: 130px 0 50px 0;
}

.lovclnt-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 82px;
    text-align: center;
    color: #341F4B;
    margin-bottom: 40px;
}

.get-inspired {
    width: 100%;
    background: #fff;
    padding: 146px 0 60px;
}

.get-inspired h2 {
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 69px;
    color: #341F4B;
    position: relative;
    z-index: 1;
    padding-left: 68px;
}

.get-inspired h2 img {
    position: absolute;
    left: 0;
    bottom: 14px;
    z-index: -1;
}

.get-inspired-inner {
    max-width: 1364px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    flex-wrap: wrap;
    gap: 30px;
}

.hersey-box {
    max-width: 740px;
    width: 100%;
    /* padding-top: 92px; */
}

.kellogs {
    max-width: 582px;
    width: 100%;
}

.inspired-box {
    background: rgba(254, 255, 249, 0.65);
    border: 1px solid #EAEAEA;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px 85px 55px;
    
}

.inspired-box span {
    margin-bottom: 20px;
    display: block;
}

.hersey-box .inspired-box span {
    margin-bottom: 80px;
}

.inspired-box p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 25px;
	line-height: 38px;
	text-transform: capitalize;
	color: #36204D;
	/* max-width: 363px; */
	position: relative;
	z-index: 1;
}

.cmtsxty {
    position: absolute;
    left: -38px;
    top: -13px;
    z-index: -1;
    max-width: 60px;
}

.cmtninty {
    position: absolute;
    right: 0;
    bottom: -20px;
    z-index: -1;
    max-width: 60px;
}

.kellogs .inspired-box:last-child {
    width: auto;
    display: inline-block;
}
.lastmxwdth{
    max-width: 1030px;
}



.grahax-sec {
    text-align: center;
    width: 100%;
    padding: 50px 0;
    position: relative;
    z-index: 1;
}
.grahax-sec .container{
    position: relative;
}
.ptrnbg{
    position: absolute;
    left: 3%;
    bottom: -91%;
    z-index: -1;
}

.grahax-sec h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 76px;
    text-align: center;
    text-transform: capitalize;
    color: #331E49;
}

.grahax-sec p {
    font-size: 40px;
    font-weight: 400;
    font-style: italic;
}

.ctgry-knowldge {
	text-align: center;
	position: relative;
	z-index: 1;
}

.ctgry-knowldge h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 76px;
    text-align: center;
    text-transform: capitalize;
    color: #331E49;
}


.anlytic-dshbrd {
    padding: 50px 0 220px;
}

.access-frm {
    max-width: 836px;
    margin: 0 auto;
    text-align: center;
}

.access-frm h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 23px;
}

.access-frm .form-group {
    margin-bottom: 76px;
}

.access-frm .form-group .inputgroup {
    position: relative;
    margin: 0px;
}

.access-frm .form-group .inputgroup .form-control {
    background: 0 0;
    border: 1.40364px solid #000000;
    font-size: 15px;
    height: 72px;
    padding: 0px 180px 0px 15px;
    -webkit-border-radius: 0px;
    color: #8B8B8B;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 0px #fff;
    box-shadow: 0px 0px 0px #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 22px;
}

.access-frm .form-group .inputgroup .subscribebtn {
    position: absolute;
    right: 5px;
    top: 6px;
    padding: 8px;
    text-align: center;
    background: #36204D;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 10px;
    font-size: 22px;
    text-shadow: 4px 9px 10px rgba(0, 0, 0, 0.25);
    transition: 0.3s ease-in-out;
    height: 60px;
    width: 125px;
    font-weight: 500;
}

.access-frm .form-group .inputgroup .subscribebtn:hover {
    background: #204D36;
}

.getbutn {
    background: #36204D;
    border-radius: 19.6509px;
    width: 212px;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22.7619px;
    line-height: 34px;
    color: #FFFFFF;
    margin: 0 auto;
}

.getbutn:hover {
    background: #204D36;
}
.case-data{
    padding: 80px 0 140px;
    background: none;
}
.case-data .case-info::before {
    width: 100%;
}

.case-data .case-pic {
    max-width: 625px;
}

.case-data .case-txt {
    max-width: 860px;
}

.case-data .case-studies-detl>.case-info:nth-child(even) .case-txt {
    max-width: 860px;
}

.case-data .case-studies-detl>.case-info:nth-child(even) .case-txt strong {
    text-align: right;
}

.case-data .case-studies-detl>.case-info:nth-child(7) .case-txt {
    max-width: 860px;
}

.case-data .case-studies-detl>.case-info:nth-child(3) .case-txt {
    max-width: 920px;
}

.case-data .case-studies-detl>.case-info:nth-child(4) .case-txt {
    max-width: 926px;
}

.case-data .case-studies-detl>.case-info:nth-child(5) .case-pic {
    left: 50px;
}

.case-data .case-studies-detl>.case-info:nth-child(5) .case-pic::after {
    left: -26px;
    bottom: -68px;
}

.case-data .case-studies-detl>.case-info:nth-child(5) .case-txt {
    max-width: 900px;
}

.case-data .case-studies-detl>.case-info .case-pic::before {
    right: -128px;
    top: -70px;
}

.case-data .case-studies-detl>.case-info .case-pic::after {
    left: 3px;
    bottom: -68px;
}

.case-data .case-studies-detl>.case-info:first-child .case-pic img {
    top: 20px;
    position: relative;
    left: -50px;
}

.case-data .case-studies-detl>.case-info:nth-child(2) .case-pic::after {
    right: -36px;
    left: inherit;
    bottom: -20px;
}

.case-data .case-studies-detl>.case-info:nth-child(2) .case-pic img {
    right: auto;
    left: 45px;
    top: 0;
    position: relative;
}

.case-data .case-studies-detl>.case-info:nth-child(3) .case-pic {
    left: -15px;
}

.case-data .case-studies-detl>.case-info:nth-child(4) .case-pic::after {
    left: auto;
    bottom: -68px;
    right: -50px;
}

.case-data .case-studies-detl>.case-info:nth-child(4) .case-pic::before {
    right: auto;
    top: -36px;
    left: -20px;
}

.case-data .case-studies-detl>.case-info:nth-child(4) .case-pic img {
    top: -40px;
    right: -20px;
    position: relative;
}

.case-data .case-studies-detl>.case-info:nth-child(6) {
    flex-direction: row-reverse;
}

.case-data .case-studies-detl>.case-info:nth-child(6) .case-pic {
    left: auto;
    right: -70px;
}

.case-data .case-studies-detl>.case-info:nth-child(6) .case-pic::before {
    left: -89px;
    top: -50px;
    right: auto;
}

.case-data .case-studies-detl>.case-info:nth-child(6) .case-pic::after {
    left: auto;
    bottom: -68px;
    right: 0;
}

.case-data .case-studies-detl>.case-info:nth-child(6) .case-txt p {
    text-align: right;
}

.case-data .case-studies-detl>.case-info:nth-child(6) .case-txt strong {
    text-align: right;
}

.case-data .case-studies-detl>.case-info:nth-child(6) .case-txt::after {
    bottom: auto;
    right: auto;
    left: 0;
    top: 0;
}

.case-data .case-studies-detl>.case-info:nth-child(7) {
    flex-direction: row;
}

.case-data .case-studies-detl>.case-info:nth-child(7) .case-pic {
    left: -15px;
    right: auto;
}

.case-data .case-studies-detl>.case-info:nth-child(7) .case-pic::before {
    right: 83px;
    top: -70px;
    left: auto;
}

.case-data .case-studies-detl>.case-info:nth-child(7) .case-txt p {
    text-align: left;
}

.case-data .case-studies-detl>.case-info:nth-child(7)::before {
    width: 100%;
}

.case-studies-detl>.case-info:nth-child(7) .case-txt::after {
    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
}

.case-data .case-info {
    margin-top: 192px;
}

.case-txt strong {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 48px;
    color: #FFFFFF;
    width: 100%;
    display: block;
}

.dgleg {
    position: absolute;
    left: -68px;
    top: 33px;
}

.case-data .case-studies-detl > .case-info:nth-child(2) .dgleg {
	right: -64px;
	top: 1px;
	left: auto;
}
.case-data .case-studies-detl > .case-info:nth-child(3) .dgleg {
	position: absolute;
	left: -43px;
	top: -18px;
}
.case-data .case-studies-detl > .case-info:nth-child(4) .dgleg {
	right: -55px;
	top: 1px;
	left: auto;
}
.case-data .case-studies-detl > .case-info:nth-child(5) .dgleg {
	position: absolute;
	left: 16px;
	top: 8px;
}
.case-data .case-studies-detl > .case-info:nth-child(6) .dgleg {
	right: 33%;
	top: -5px;
	left: auto;
	z-index: 1;
}
.ctgry-mobile{display: none;}

.fndownr {
	max-width: 270px;
}

.lgoslide-inner{
    width: 100%;
    text-align: center;
    height: 150px;
    position: relative;
}
.lgoslide-inner img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    margin: auto;
}

.oudtatmobilelogo{
    display: none;
}

/* responsive */

@media (max-width: 1800px) {
    .erlylunch-sec {
        max-width: 1024px;
    }
   
    .ptrnbg {
        left: -6%;
    }
    .evryprdct-content {
        padding-left: 0;
    }
    .evryprdct-content img {
        right: 60px;
    }
    .case-data .case-txt {
        max-width: 658px;
    }

    .case-data .case-txt p {
        line-height: 56px;
    }
    .case-data .case-studies-detl > .case-info:nth-child(6) .dgleg {
        right: 38%;
        top: -14px;
    }
    .case-data .case-studies-detl>.case-info:nth-child(2) .case-txt {
        max-width: 630px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(3) .case-txt {
        max-width: 70px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(3) .case-txt {
        max-width: 680px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-txt {
        max-width: 746px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-txt {
        max-width: 755px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-txt {
        max-width: 760px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(7) .case-txt {
        max-width: 620px;
    }

    .case-studies-detl>.case-info:nth-child(7) .case-txt::after {
        bottom: 0;
        right: 0;
        left: auto;
        top: auto;
    }
    .ourdatabnr .banner-cap h1 {
        font-size: 70px;
    }

    
}

@media (max-width: 1700px) {
   
}
@media (max-width: 1600px) {
    .uniqunes-inner figcaption p {
        font-size: 32px;
        line-height: 42px;
    }

    .insight-offer .container {
        max-width: 1320px;
    }

    .get-inspired-inner {
        max-width: 1024px;
    }

    .hersey-box {
        max-width: 500px;
    }

    .inspired-box {
        padding: 30px 60px 55px;
    }

    .inspired-box p {
        font-size: 20px;
        line-height: 32px;
    }

    .get-inspired-inner .kellogs {
        max-width: 400px;
    }

    .trax-box p {
        font-size: 20px;
        line-height: 36px;
    }

    .trax-box h5 {
        font-size: 25px;
    }

    .trax-box h5 strong {
        font-size: 36px;
    }

    .cvrge-inner h5 {
        font-size: 34px;
        line-height: 40px;
    }

    .exclusive-sec p {
        font-size: 28px;
        line-height: 54px;
    }

    .erlyinfo p {
        font-size: 45px;
    }

    .evryprdct-content h3 {
        font-size: 50px;
        line-height: 135.2%;
    }

    .evryprdct-content img {
        right: 40px;
    }

    .erlylunch-sec {
        max-width: 890px;
    }

}

@media (max-width: 1399px) {
    .ourdatabnr .banner-cap h1 {
        font-size: 50px;
    }
    .uniqunes-inner figcaption p {
        font-size: 28px;
        line-height: 38px;
    }
    .uniqunes-inner figcaption span {
        font-size: 21px;
        line-height: 30px;
    }
    .case-data .case-pic {
        max-width: 528px;
    }

    .case-data .case-txt {
        max-width: 584px;
    }

    .case-txt strong {
        font-size: 35px;
        line-height: 40px;
    }

    .case-studies-detl>.case-info:nth-child(2) .case-pic {
        max-width: 520px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2) .case-txt {
        max-width: 586px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(3) .case-txt {
        max-width: 604px;
    }

    .case-studies-detl>.case-info:nth-child(3) .case-pic {
        max-width: 466px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-txt {
        max-width: 570px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-txt {
        max-width: 700px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-pic {
        left: 31px;
        max-width: 314px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(7) .case-pic {
        max-width: 500px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(7) .case-txt {
        max-width: 576px;
    }

    .case-data .case-txt p {
        line-height: 50px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-txt {
        max-width: 577px;
    }

    .grahax-sec h2 {
        font-size: 40px;
        line-height: 59px;
    }

    .grahax-sec p {
        font-size: 35px;
    }

    .ctgry-knowldge h2 {
        font-size: 40px;
        line-height: 65px;
    }

    .access-frm h2 {
        font-size: 40px;
        line-height: 60px;
    }
    .national-coverge h4{font-size: 34px;}
    .unlok-sec h3 {
        font-size: 45px;
    }
    .erlyinfo p {
        font-size: 45px;
    }
    .erlyinfo span {
        font-size: 75px;
    }
    .case-data .case-pic {
        max-width: 450px;
    }

    .lastmxwdth {
        max-width: 740px;
    }

}

@media (max-width: 1199px) {
    .dksvrsn > .row > div {
        padding: 0 20px;
    }
    .uniqunes-inner {
        min-height: 400px;
        margin-bottom: 40px;
    }
    .uniqunes-inner figcaption p {
        font-size: 26px;
        line-height: 35px;
    }
    .uniqunes-inner figcaption span {
        font-size: 19px;
        line-height: 30px;
    }
    .evryprdct-content h3 {
        font-size: 44px;
    }
    .evryprdct-content img {
        right: 4px;
    }
    .erlylunch-sec {
        max-width: 520px;
    }
    .prcsion-txt p {
        font-size: 35px;
    }
    

    .case-data .case-txt {
        max-width: 496px;
    }

    .case-data .case-txt p {
        line-height: 36px;
    }

    .case-txt strong {
        font-size: 30px;
        line-height: 35px;
    }

    .case-studies-detl>.case-info:nth-child(2) .case-pic {
        max-width: 395px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2) .case-txt {
        max-width: 534px;
    }

    .case-studies-detl>.case-info:nth-child(3) .case-pic {
        max-width: 370px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(3) .case-txt {
        max-width: 530px;
    }

    .case-studies-detl>.case-info:nth-child(4) .case-pic {
        max-width: 370px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-txt {
        max-width: 530px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-txt {
        max-width: 530px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(7) .case-pic {
        max-width: 392px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(7) .case-txt {
        max-width: 510px;
    }

    .get-inspired-inner {
        max-width: 900px;
    }

    .hersey-box {
        max-width: 380px;
    }

    .kellogs {
        max-width: 500px;
    }

    .get-inspired h2 img {
        max-width: 20%;
    }

    .offer-inner {
        padding-left: 30px;
    }

}

@media (max-width: 991px) {
    .erlylunch-sec {
        max-width: 420px;
    }
    .erlyinfo p {
        font-size: 40px;
    }
    .prcsion-txt p {
        font-size: 30px;
    }
    .prcsion-txt a {
        border: 3px solid #FF8F66;
        font-size: 20px;
        padding: 10px 23px;
    }
    .insight-offer {
        padding: 50px 0 50px 0;
    }
}
@media (max-width: 767px) {
    .uniqunes-inner {
        min-height: 300px;
    }

    .uniquness-sec h2 {
        font-size: 25px;
        line-height: 38px;
    }

    .uniqunes-inner figure {
        width: 89px;
        height: 89px;
    }

    .uniqunes-inner figcaption p {
        font-size: 21px;
        line-height: 31px;
    }

    .uniqunes-inner figcaption span {
        font-size: 14px;
        line-height: 21px;
    }

    .precision-sec {
        display: block;
        padding: 66px 20px;
    }

    .evryprdct-content {
        padding-left: 0;
        text-align: center;
    }

    .evryprdct-content h3 {
        font-size: 24px;
        padding: 0 26px;
        line-height: 162%;
        margin-bottom: 25px;
    }

    .evryprdct-content img {
        max-width: 220px;
        right: auto;
        left: -18%;
    }

    .evrdypic {
        position: relative;
        z-index: 1;
    }

    .prcsion-txt p {
        font-size: 25px;
    }

    .prcsion-txt a {
        border: 2.5px solid #FF8F66;
        font-size: 14px;
        padding: 10px 16px;
    }

    .erlylunch-sec {
        max-width: 100%;
        padding-top: 60px;
        display: block;
    }
    .erlyinfo{
        width: 100%;
        border-right: 0;
    }

    .erlyinfo p {
        font-size: 28px;
    }

    .erlyinfo span {
        font-size: 35px;
        -webkit-text-stroke-width: 1.7px;
        -webkit-text-stroke-color: #fff;
    }

    .erlyinfo {
        padding: 20px 0;
        border-bottom: 1.5px solid #FFFFFF;
    }

    .prcsion-code {
        display: none;
    }

    .data-coverge-sec h2 {
        font-size: 25px;
        line-height: 38px;
        margin-bottom: 0
    }

    .mapdata {
        padding-top: 28px;
        margin-bottom: 40px;
    }

    .insight-offer h2 {
        font-size: 25px;
        line-height: 38px;
        margin-bottom: 20px;
    }

    .offer-inner {
        padding-left: 32px;
    }

    .ofricon {
        width: 87px;
        height: 87px;
    }

    .ofricon img {
        max-width: 62%;
    }

    .trax-box {
        padding: 20px 18px 20px 93px;
        min-height: auto;
        margin: 16px 0;
    }

    .trax-box p {
        font-size: 17px;
        line-height: 26px;
    }

    .trax-box h5 {
        font-size: 20px;
    }

    .trax-box h5 strong {
        font-size: 27px;
    }

    .cvrge-inner{
        padding-left: 15px;
    }
    .cvrge-inner h5::before {
        width: 22px;
        height: 22px;
    }

    .cvrge-inner h5 {
        font-size: 22px;
        line-height: normal;
        padding-left: 32px;
    }

    .cvrge-inner h5 sub {
        font-size: 11px;
    }
    .harbingtxt .cvrge-inner h5 {
        font-size: 16px;
    }
    .harbingtxt .cvrge-inner h5::before {
        width: 15px;
        height: 15px;
    }

    .covaregae-info>.row>div {
        margin: 15px 0;
    }

    .national-coverge h4 {
        font-size: 21px;
        margin-bottom: 45px;
    }

    .national-coverge h4::after {
        height: 6px;
        width: 148px;
    }

    .mblidiv {
        width: 100%;
        text-align: center;
    }
    .offer-inner{overflow: hidden;}

    .exclusive-sec p {
        font-size: 18.5px;
        line-height: 34px;
        padding-left: 12px;
        margin-bottom: 34px;
    }

    .exclusive-sec p::before {
        width: 32px;
        height: 32px;
        top: 0;
    }

    .exclusive-sec {
        width: 100%;
        padding-top: 50px;
    }

    .exclusive-sec h4 {
        padding: 0;
        line-height: 36px;
        max-width: 200px;
        text-align: center;
        margin: 0 auto 32px;
        display: block;
    }

    .exclusive-sec h4::after {
        width: 126px;
        top: 74%;
        right: 14px;
    }

    .lovedclient {
        padding: 7px 0 50px 0;
    }
  
    .study-case-inner{width: 100%;overflow: hidden;}
    

    .lovclnt-heading h2 {
        font-size: 22px;
        line-height: 33px;
        color: #262626;
        margin-bottom: 40px;
        
    }

    .get-inspired {
        padding: 60px 0 60px;
    }

    .get-inspired h2 img {
        max-width: 110px;
        bottom: 7px;
    }

    .get-inspired h2 {
        font-size: 27px;
        line-height: 35px;
        padding-left: 37px;
    }

    .hersey-box {
        max-width: 100%;
        padding-top: 50px;
        width: 100%;
    }

    .get-inspired-inner {
        display: block;
        padding-top: 0;
    }

    .kellogs {
        max-width: 100%;
    }

    .kellogs .inspired-box:last-child {
        width: 100%;
    }

    .inspired-box {
        padding: 30px 60px 55px;
    }

    .inspired-box p {
        font-size: 18px;
        line-height: 28px;
    }

    .cmtsxty {
        max-width: 42px;
    }

    .cmtninty {
        max-width: 42px;
        bottom: -15px;
    }


    .case-data .case-studies-detl>.case-info .case-pic::before {
        right: -10px;
        top: -34px;
    }

    .case-data .case-studies-detl>.case-info:first-child .case-pic img {
        left: 0;
        max-width: 96%;
    }

    .case-data .case-studies-detl>.case-info .case-pic::after {
        left: -19px;
        bottom: -180px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2) .case-pic img {
        left: 0;
        max-width: 98%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2n) .case-txt strong {
        text-align: left;
    }

    .case-txt strong {
        font-size: 25px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2) .case-pic::after {
        bottom: -70%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2) .case-txt::after {
        bottom: 27%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(3) .case-pic img {
        max-width: 95%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(3) .case-pic {
        text-align: center;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-pic {
        text-align: center;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-pic img {
        top: -13px;
        right: 0;
        max-width: 90%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-pic::before {
        top: -4px;
        left: -8px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-pic::after {
        bottom: -60%;
        right: -24px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(4) .case-txt::after {
        bottom: 40%;
    }

    .case-data .case-studies-detl > .case-info:nth-child(5) .case-pic {
        left: 0;
        text-align: center;
        width: 100%;
        max-width: 100%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-pic img {
        max-width: 90%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-pic::before {
        right: auto;
        top: -34px;
        left: -10px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-pic::after {
        left: auto;
        bottom: -60%;
        right: -22px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(5) .case-txt::after {
        bottom: 11px;
        left: 15px;
        right: auto;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-pic {
        left: auto;
        right: auto;
        text-align: center;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-pic img {
        max-width: 98%;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-pic::before {
        left: -10px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-pic::after {
        left: auto;
        bottom: -60%;
        right: -21px;
    }

    .case-data .case-studies-detl>.case-info:nth-child(2n) .case-txt p {
        text-align: left;
    }

    .case-data .case-studies-detl>.case-info:nth-child(6) .case-txt::after {
        bottom: 15%;
        right: auto;
        left: 15px;
        top: auto;
    }

    .case-data .case-studies-detl>.case-info:nth-child(7) .case-pic {
        left: 0;
    }

    .grahax-sec {
        text-align: left;
    }

    .grahax-sec h2 {
        font-size: 25px;
        font-style: normal;
        line-height: 38px;
        text-align: left;
        margin: 0;
    }

    .grahax-sec p {
        font-size: 25px;
        color: #36204D;
    }

    .grahax-sec {
        padding: 0 0 50px 0;
    }

    .ctgry-knowldge h2 {
        font-size: 25px;
        line-height: 38px;
        text-align: left;
    }

    .anlytic-dshbrd {
        display: none;
    }

    .ctgry-knowldge {
        text-align: left;
        padding-bottom: 60px;
    }

    .case-data {
        padding: 72px 0;
    }

    .unlok-sec h3 {
        font-size: 24px;
    }

    .unlok-sec {
        padding: 60px 0 60px;
    }

    .hersey-box .inspired-box span {
        margin-bottom: 50px;
    }
    .prcsion-txt {
        top: 0px;
        /* position: relative; */
        background: #36204d;
        padding: 20px 0 30px;
    }
    .dgleg{display: none;}
    .case-data .case-txt {
        max-width: 100%;
        padding: 15px 40px 23px !important;
    }
    .dkctgry{display: none;}
    .ctgry-mobile{display: block;}
    .ctgry-mobile .owl-stage {
        padding-left: 0 !important;
    }
    .case-data .case-studies-detl > .case-info:nth-child(7) .case-pic::before {
        right: -10px;
        top: -51px;
        left: auto;
    }
    .ptrnbg{display: none;}
    .ourdatabnr .banner-cap h1 {
        font-size: 45px;
    }
    .dataviw-dkstp > .row > div {
        padding: 0 4px;
    }
    .dataviw-dkstp {
        padding: 0 7px;
    }
    .uniqunes-inner figcaption p {
        font-size: 16px;
        line-height: 130%;
    }
    .uniqunes-inner {
        min-height: 290px;
        padding: 27px 17px 30px;
    }
    .uniqunes-inner figure {
        width: 61px;
        height: 61px;
    }
    .uniqunes-inner figure img {
        max-width: 50%;
    }
    .ourdatabnr .banner-cap h1 {
        font-size: 35px;
    }

    .oudtatmobilelogo{
        display: block;
    }
    .dkstdatalgo{
        display: none;
    }

}

/* _____15_09_23___ */

.uniqunes-inner {min-height: 390px;}


@media (max-width: 1399px) {

/* _____15_09_23___ */
.trax-box p {font-size: 18px;line-height: 33px;}
.trax-box h5 strong {font-size: 32px;}
.trax-box h5 {font-size: 24px;}
.offer-inner > .row > div {padding: 0 18px;}
.ofricon {width: 118px;height: 118px;left: -6px;}
.trax-box {min-height: 230px;}


}

@media (max-width: 1199px) {

/* _____15_09_23___ */
.dataviw-dkstp > .row > div {padding: 0 20px;}
.dataviw-dkstp > .row > div:nth-child(1) .uniqunes-inner, 
.dataviw-dkstp > .row > div:nth-child(2) .uniqunes-inner, .dataviw-dkstp > .row > div:nth-child(3) .uniqunes-inner {
min-height: 325px;}
.uniqunes-inner {min-height: 370px;}

.trax-box p {font-size: 15px; line-height: 26px;}
.trax-box h5 {font-size: 19px;}
.trax-box h5 strong {font-size: 22px;}
.trax-box{padding: 10px 18px 10px 100px; min-height: 210px;}
.offer-inner > .row > div {padding: 0 15px;}
.ofricon {width: 102px;height: 102px;left: -20px;padding: 15px;}
.offer-inner {padding: 0 25px;}


.precision-sec {padding: 80px 60px;}
.erlylunch-sec {max-width: 570px;}
.erlyinfo p {font-size: 32px;}
.prcsion-code {width: 310px;}

.case-data .case-studies-detl > .case-info:first-child .case-pic img { left: -36px;}


}

@media (max-width: 991px) {

/* _____15_09_23___ */
.dataviw-dkstp > .row > div:nth-child(1) .uniqunes-inner, 
.dataviw-dkstp > .row > div:nth-child(2) .uniqunes-inner, .dataviw-dkstp > .row > div:nth-child(3) .uniqunes-inner {
min-height: 325px;}
.uniqunes-inner {min-height: 325px;}
.uniqunes-inner figcaption p {font-size: 25px; line-height: 34px;}

.trax-box {min-height: 200px;}

.precision-sec {padding: 60px 35px;}
.erlyinfo p {font-size: 28px;}
.erlyinfo span {font-size: 60px;}
.erlylunch-sec {max-width: 500px;}
.prcsion-code {width: 255px;}



}

@media (max-width: 767px) {

/* _____15_09_23___ */
.dataviw-dkstp > .row > div:nth-child(1) .uniqunes-inner, 
.dataviw-dkstp > .row > div:nth-child(2) .uniqunes-inner, .dataviw-dkstp > .row > div:nth-child(3) .uniqunes-inner {
min-height: 290px;}
.uniqunes-inner {min-height: 290px;}
.uniqunes-inner figcaption p {font-size: 23px;}

.offer-inner > .row > div{width: 50%; padding: 0 12px;}
.offer-inner > .row {margin: 0 -12px;}
.offer-inner { padding: 0 10px; overflow: inherit;}
.trax-box p {font-size: 14px;line-height: 24px;}
.trax-box {padding: 10px 18px 10px 85px; min-height: 185px;}
.trax-box h5 {font-size: 18px;}
.trax-box h5 strong {font-size: 19px;}

.ofricon {width: 88px; height: 88px; left: -15px;}


.precision-sec {padding: 45px 30px;}
.prcsion-txt {padding: 0px 0 30px;}
.erlylunch-sec { max-width: 100%;}
.erlyinfo{margin: 0;}


}


@media (max-width: 575px) {

/* _____15_09_23___ */
.dataviw-dkstp > .row > div:nth-child(1) .uniqunes-inner, 
.dataviw-dkstp > .row > div:nth-child(2) .uniqunes-inner, .dataviw-dkstp > .row > div:nth-child(3) .uniqunes-inner {
min-height: 307px;}
.uniqunes-inner {min-height: 307px;}
.dataviw-dkstp > .row > div {padding: 0 15px;}
.uniqunes-inner figcaption p {font-size: 18px; line-height: 32px;}

.offer-inner > .row > div{width: 100%;}
.trax-box {min-height: 175px;}

.lgoslide-inner { height: 130px; padding: 15px; display: flex; align-items: center; justify-content: center;}
.lgoslide-inner img { max-height: initial; position: static; width: auto !important;}

.dataviw-dkstp > .row > div {padding: 0 4.5px;}
.uniqunes-inner figcaption p { font-size: 14px; line-height: 27px;}
.uniqunes-inner figure { width: 60px; height: 60px; margin-bottom: 18px;}
.uniqunes-inner{padding: 15px; min-height: 260px !important; margin-bottom: 10px;}
.dataviw-dkstp > .row{margin: 0 -4.5px;}


}

@media (max-width: 480px) {

/* _____15_09_23___ */




}















