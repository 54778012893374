

.abutpgebnr .banner-cap h1 {
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 121%;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

.abutpgebnr .banner-cap h4 {
    line-height: 65px;
}

.abutpgebnr .banner-cap a {
    background: #204D36;
    color: #F1EBBB;
    box-shadow: none;
    font-size: 25px;
    font-weight: 600;
    padding: 10px 70px;
    border-radius: 7px;
    margin-top: 96px;
}

.abutpgebnr .banner-cap a:hover {
    background: #36204D;
    color: #F1EBBB;
}

.about-us-sec {
    width: 100%;
    padding: 30px 0;
}

.abuttinner {
    max-width: 1480px;
    margin: 0 auto;
}

.about-inner {
    padding: 0 0;
}

.about-inner h3 {
    font-family: 'Merriweather', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 142.2%;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: #204D36;
}

.about-inner p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 62px;
    color: #204D36;
    text-transform: capitalize;
}

.abutpics {
    text-align: center;
}

.abutpics img {
    max-width: 100%;
}

.recycled-story {
    background: linear-gradient(179.98deg, #36204D 29.45%, #000000 241.57%);
    width: 100%;
    padding: 170px 0;
    position: relative;
}

.recycled-story .container {
    max-width: 1280px;
}

.recycled-txt {
    display: flex;
    justify-content: space-between;
    max-width: 1168px;
    margin: 0 auto;
}

.rcycled-lft h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 88px;
    color: #FFFFFF;
}

.rcycled-lft h3 span {
    color: #0096D7;
}

.rcycled-lft span h1 {
    color: #0096D7;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 88px;
}

.recycled-pra {
    padding-left: 60px;
}

.recycled-pra p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    color: #FFFFFF;
}

.rebrcod {
    position: absolute;
    bottom: 28%;
    left: 3%;
}

.rebrcod img {
    max-width: 162px;
}

.rebrcod2 {
    position: absolute;
    top: -10%;
    right: 20px;
}

.rebrcod2 img {
    max-width: 162px;
}

.core-values-sec {
    padding: 118px 0;
}

.core-values-sec .container {
    max-width: 1360px;
}

.core-values-inner {
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 0 0 35px 90px;
}

.corevalue-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 200px 20px 0;
}

.corevalue-head h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 77px;
    color: #204D36;
}

.step-core {
    padding-right: 50px;
}

.step-core span {
    width: 63px;
    height: 63px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 55px;
    letter-spacing: 0.605769px;
    color: #1D4430;
    display: inline-block;
    border: 2.42308px solid #1D4531;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 30px;
}

.step-core h6 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.605769px;
    color: #000000;
}

.step-core p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 38px;
    color: #000000;
}

.abutmisn {
    width: 100%;
}

.abutmisn .ourvsnmsn {
    margin-top: 0;
}


.about-contact-info {
    padding: 120px 0;
}

.about-contact-info .container {
    max-width: 1660px;
}

.about-contact-info h3 {
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 42.6637px;
    line-height: 63px;
    text-align: center;
    letter-spacing: -0.497743px;
    color: #000000;
    margin-bottom: 20px;
}

.cntctdetl {
    padding: 30px 80px;
}

.cntctdetl span {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #204D36;
    margin: 0 auto 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 45px;
}

.cntctdetl h4 {
    font-family: 'League Spartan', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
}

.cntctdetl p {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #000000;
}

.cntctdetl a {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #000000;
    display: block;
}

.abut-ontact-from {
    margin-bottom: 250px;
}

.cntctpic img {
    max-width: 100%;
}

.cnctfrm .form-control {
    padding: 0 15px;
    background: #F7F7F7;
    border-bottom: 1px solid #161B3D !important;
    height: 60px;
    border-radius: 0;
    margin-bottom: 28px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 19.9097px;
    line-height: 34px;
    color: #7B7485;
}

.cnctfrm textarea {
    min-height: 200px;
}

.cnctfrm button {
    width: 166px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #FFFFFF;
    border: none;
    box-shadow: none;
    background: #36204D;
    float: right;
}

.cnctfrm button:hover {
    background: #204D36;
}



.leadership-team {
    padding: 120px 0;
    position: relative;
}

.rightpic {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    text-align: right;
}

.rightpic img {
    max-width: 100%;
}

.leadership-team .container {
    max-width: 1600px;
}

.leadership-box h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 77px;
    color: #204D36;
    margin-bottom: 32px;
}

.leadership-box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 43px;
    color: #000000;
}

.abut-tab-sec {
    width: 100%;
    padding: 80px 0 0;
}

.abut-tab-sec ul {
    background: none;
    border: none;
}

.nav.nav-tabs .tab-content {
    margin-top: 30px
}

.nav.nav-tabs .tab-content .tab-pane {
    display: none;
    transition: opacity .3s linear
}

.nav.nav-tabs .tab-content .tab-pane.active {
    display: block
}

.nav.nav-tabs .nav-link {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 57px;
    text-align: center;
    color: #132742;
    opacity: 0.65;
    margin-top: 5px;
    transition: 1s;
    border: none;
    border-bottom: 5px solid transparent;
    transition: all .3s ease-in-out
}

.nav.nav-tabs .nav-link.active {
    border-bottom: 5px solid #36204D;
    opacity: 1;
}


.tabcontent {
    margin-top: 300px;
}

.tabdata {
    background: #C7D3C8;
    width: 275px;
    margin-right: 8px;
    text-align: center;
    display: inline-block;
    padding: 14px 10px 11px;
    text-decoration: none;
}

.tabdata strong {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    color: #204D36;
    display: block;
}

.tabdata span {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #444444;
    display: block;
}

.wstageall-pic {
    margin: -135px 0 0;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.sldrinner{
    width: 100%;
}

.inercrsl img {
    width: 100%;
}

.roshan-info {
    padding: 60px 0;
}

.roshan-info-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1480px;
    margin: 0 auto;
}

.roshan-content {
    max-width: 460px;
    margin: 40px 0;
}

.roshan-content p {
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    text-transform: capitalize;
    color: #36204D;
    position: relative;
}

.roshan-content span {
    display: block;
    text-align: right;
    font-family: 'Nova Slim', cursive;
    font-weight: 400;
    font-size: 22px;
    line-height: 62px;
    text-transform: capitalize;
    color: #3A3A3A;
}

.roshan-content p .cmtninty {
    right: 40px;
    bottom: -14px;
}

.roshanpic {
    position: relative;
}

.wait-shall {
    width: 100%;
    padding: 100px 0;
}

.wait-shall-inner {
    max-width: 1058px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.our-waste {
    width: 44%;
}

.our-waste h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #204D36;
}

.our-waste h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 85px;
    line-height: 101px;
    text-transform: uppercase;
    color: #000000;
}

.wtevison {
    width: 44%;
}

.wtevison h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #204D36;
}

.wtevison p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    text-transform: capitalize;
    color: #000000;
}

.wsvison-inner {
    margin: 0 0 50px;
}

.miranda-sec {
    max-width: 540px;
    width: 100%;
    background: #204D36;
    border: 10px solid #FEFFF9;
    border-radius: 150px;
    display: flex;
    justify-content: space-between;
    padding: 40px 50px;
    position: absolute;
    bottom: -50px;
    left: 100px;
}

.miranda-txt {
    color: #fff;
}

.miranda-txt ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.miranda-txt h5 {
    font-family: 'Nova Slim', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0 0 8px;
}

.miranda-txt span {
    font-family: 'Nova Slim', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    text-transform: capitalize;
    color: #85CC9E;
}

.miranda-txt ul {
    margin-top: 10px;
}

.miranda-txt ul li {
    font-family: 'Nova Slim', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.recycled-vdo {
    max-width: 536px;
    width: 100%;
}

.recycled-vdo video {
	border-radius: 50px;
	min-height: 404px;
}

.rcycled-lft {
    max-width: 505px;
    width: 100%;
}

.things-value {
    width: 100%;
    padding: 100px 0;
}

.thing-inner h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 82px;
    color: #204D36;
    margin-bottom: 50px;
}

.value-box {
    width: 100%;
    padding: 50px;
    min-height: 545px;
    position: relative;
}

.value-box h4 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 65px;
    color: #ECFF90;
}

.value-box p {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 43px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.value-box span {
    position: absolute;
    bottom: 30px;
    left: 50px;
}

.value-box img {
    width: auto !important;
    max-width: 100%;
}

.doingbg {
    background: #36204D;
}

.doingbg2 {
    background: #4F2F71;
}

.doingbg3 {
    background: #6A3F97;
}

.doingbg4 {
    background: #804DB5;
}

.doingbg5 {
    background: #A47FCA;
}

.passionate-box {
    padding: 50px 0;
}

.passionate-inner-box {
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 100px;
}

.passionate-inner-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.passionate-pic {
    max-width: 568px;
    width: 100%;
}

.passionate-pic img {
    width: 100%;
    border-radius: 10px;
}
.passionate-pic img:last-child {
    margin-top: 10px;
}

.passionte-txt h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 144px;
    color: #204D36;
}

.leaderbx {
    margin: 30px 0;
}

.leaderbx figure img {
    width: 100%;
}

.leaders-sec {
    width: 100%;
    background: linear-gradient(176.45deg, #36204D 30.72%, #000000 253.43%);
    padding: 180px 0;
    position: relative;
    margin-top: -70px;
}

.leadr-name {
    display: flex;
    justify-content: space-between;
}

.leadr-name h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
}

.laeder-info p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    color: #CFCFCF;
}

.inercrsl {
    max-height: 415px;
    max-width: 618px;
}

.inercrsl img {
    max-width: 100%;
}

.leaders-sec h3{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 98px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}


@media (max-width: 1800px) {
    .abutpgebnr .banner-cap h1 {
        font-size: 80px;
    }

    .abutpgebnr .banner-cap h4 {
        line-height: 53px;
    }

    /* .about-us-sec .container {
        max-width: 1320px;
    } */

    .recycled-story .container {
        max-width: 1200px;
    }

    .leadership-team .container {
        max-width: 1320px;
    }

    .tabdata {
        width: 228px;
        padding: 10px 10px 9px;
    }

    .rightpic img {
        max-width: 80%;
    }

    .cnctfrm {
        padding-left: 20px;
    }

    .about-contact-info .container {
        max-width: 1320px;
    }

    .cntctdetl {
        padding: 30px 40px;
    }

    .value-box h4 {
        font-size: 29px;
        line-height: 47px;
    }

    .value-box p {
        font-size: 20px;
        line-height: 38px;
    }

    .value-box {
        padding: 36px;
    }

    .value-box {
        min-height: 430px;
    }

    .thing-inner h3 {
        font-size: 55px;
        line-height: 72px;
    }
    .leadr-name h6 {
        font-size: 24px;
        line-height: 30px;
    }
    .laeder-info p {
        font-size: 15px;
        line-height: 24px;
    }
    .recycled-txt {
        max-width: 1050px;
    }
    .recycled-vdo {
        max-width: 498px;
    }
    .about-inner p {
        font-size: 38px;
        line-height: 60px;
    }

}

@media (max-width: 1700px) {

    .cntctdetl p {
        font-size: 27px;
        line-height: 35px;
    }

    .cntctdetl a {
        font-size: 27px;
    }
    .passionte-txt h2 {
        font-size: 87px;
        line-height: 110px;
    }
    .passionate-pic {
        max-width: 488px;
    }
    .recycled-vdo video {
        min-height: 360px;
    }

}

@media (max-width: 1399px) {
    .abut-tab-sec {
        padding: 40px 0 0;
    }

    .leadership-box h3 {
        font-size: 60px;
        line-height: 75px;
    }

    .rcycled-lft h3 {
        font-size: 65px;
        line-height: 100px;
    }

    .recycled-pra p {
        font-size: 29px;
        line-height: 43px;
    }

    .abutpgebnr .banner-cap h1 {
        font-size: 60px;
    }

    .abutpgebnr .banner-cap a {
        font-size: 30px;
        margin-top: 80px;
    }

    .about-inner p {
        font-size: 25px;
        line-height: 40px;
    }
    .value-box p {
        font-size: 18px;
        line-height: 31px;
    }
    .rcycled-lft h3 {
        font-size: 55px;
        line-height: 80px;
    }
    .rcycled-lft span h1 {
        font-size: 55px;
        line-height: 80px;
    }
    .our-waste h2 {
        font-size: 70px;
        line-height: 85px;
    }
    .wtevison p {
        font-size: 25px;
        line-height: 36px;
    }
    .roshanpic {
        max-width: 600px;
    }
    .miranda-sec {
        left: 79px;
    }
    .roshan-content p {
        font-size: 26px;
        line-height: 34px;
    }
    .roshan-content {
        max-width: 396px;
    }
    .passionte-txt h2 {
        font-size: 70px;
        line-height: 97px;
    }
    .passionate-pic {
        max-width: 440px;
    }
    .passionate-inner-box {
        padding: 100px 70px;
    }


/* _____15_09_23___ */
.rcycled-lft {max-width: 395px; margin-left: 95px;}
.rebrcod {width: 120px; bottom: 20%;}



}


@media (max-width: 1199px) {

.abutpgebnr .banner-cap h1 {font-size: 40px;}
.abutpgebnr .banner-cap h4 {line-height: 35px; font-size: 22px;}
.abutpgebnr .banner-cap a {font-size: 20px;margin-top: 35px;padding: 11px 40px;}






}

@media (max-width: 991px) {

/* _____15_09_23___ */
.rebrcod {width: 100px; bottom: 15%;}
.rcycled-lft {max-width: 350px; margin-left: 21px;}

.abutpgebnr .banner-cap h1 {font-size: 33px;}
.abutpgebnr .banner-cap h4 {line-height: 30px; font-size: 18px;}
.banrcontent {max-width: 500px;}
.abutpgebnr .banner-cap a {font-size: 16px; margin-top: 30px; padding: 10px 35px;}

.thing-inner .owl-nav{display: none !important;}
.thing-inner .owl-dots{margin: 25px 0 0;}


}


@media (max-width: 767px) {
    .roshan-info-inner {
        display: grid;
    }
    .miranda-sec {
        bottom: 0;
        left: 0;
        position: relative;
        margin-top: -100px;
        padding: 25px 40px;
    }
    .miranda-txt h5 {
        font-size: 14px;
    }
    .miranda-txt span {
        font-size: 9px;
    }
    .miranda-txt ul li {
        font-size: 8px;
    }
    .roshan-content p {
        font-size: 20px;
        line-height: 29px;
    }
    .roshan-content {
        max-width: 440px;
        padding: 0 36px;
        margin-top: 20px;
    }
    .roshan-content span {
        font-size: 15px;
        line-height: normal;
    }
    .roshan-content p .cmtninty {
        right: -6px;
        bottom: -14px;
    }
    .wait-shall-inner {
        display: grid;
    }
    .about-inner p {
        font-size: 22px;
        line-height: 32px;
    }
    .abutpics img {
        max-width: 68%;
    }
    .abutpics {
        margin-top: 15px;
    }
    .abutpgebnr .banner-cap h1 {
        font-size: 36px;
    }
    .abutpgebnr .banner-cap h4 {
        line-height: 41px;
        font-size: 20px;
    }
    .abutpgebnr .banner-cap a {
        font-size: 24px;
        padding: 10px 40px;
    }
    .our-waste h2 {
        font-size: 50px;
        line-height: 60px;
    }
    .wtevison {
        width: 100%;
    }
    .wtevison p {
        font-size: 22px;
        line-height: 34px;
    }
    .wtevison h6 {
        font-size: 24px;
    }
    .our-waste {
        width: 100%;
        margin-bottom: 20px;
    }
    .wsvison-inner {
        margin: 0 0 30px;
    }
    .recycled-txt {
        display: block;
    }
    .rcycled-lft h3 {
        font-size: 50px;
    }
    .rcycled-lft span h1{
        font-size: 50px;
        line-height: 60px;
    }
    .recycled-vdo video {
        border-radius: 50px;
        min-height: 216px;
    }
    .rebrcod {
        display: none;
    }
    .rebrcod2{
        display: none;
    }
    .recycled-story {
        padding: 50px 15px 70px;
    }
    .thing-inner h3 {
        font-size: 30px;
        line-height: normal;
    }
    .things-value {
        width: 100%;
        padding: 50px 0;
    }
    .passionate-inner-box {
        display: block;
        padding: 0;
        box-shadow: none;
    }
    .passionte-txt h2 {
        font-size: 55px;
        line-height: 63px;
        margin-bottom: 25px;
    }
    .wstageall-pic {
        margin: 0;
    }
    .wstageall-pic .owl-dots{
        display: none !important;
    }
    .leaders-sec h3 {
        font-size: 30px;
        line-height: 70px;
    }
    .leaders-sec {
        padding: 120px 0;
    }
    .leadr-name h6 {
        font-size: 15px;
        line-height: 28px;
    }
    .laeder-info p {
        font-size: 10px;
        line-height: 16px;;
    }
    .cntctpic{
        margin-bottom: 50px;
    }
    .cnctfrm {
        padding-left: 0;
    }
    .about-contact-info {
        padding: 80px 0;
    }
    .cntctdetl h4 {
        font-size: 19px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .cntctdetl a {
        font-size: 24px;
    }
    .leadr-name a img{
        max-width: 25px;
    }
    .value-box {
        padding: 35px;
    }
    .thing-inner .owl-stage {
        padding-left: 0 !important;
    }
    .value-box p {
        font-size: 17px;
        line-height: 35px;
    }
    .value-box h4 {
        font-size: 28px;
    }



/* _____15_09_23___ */
.rcycled-lft {max-width: 100%;margin-left: 0;}

.abutpgebnr .banner-cap h1 {font-size: 35px;}
.abutpgebnr .banner-cap h4 {line-height: 30px; font-size: 21px;}
.abutpgebnr .banner-cap a {font-size: 16px;padding: 8px 30px;margin: 12px 0 0;}
.banrcontent {max-width: 370px;}

.value-box {min-height: 345px;}





}


@media (max-width: 480px) {

.value-box {padding: 20px;}
.value-box h4 {font-size: 20px; line-height: 28px;}
.value-box p {font-size: 15px; line-height: 30px;}




}