@media (min-width: 1920px){
.container{max-width: 1720px; width: auto;}
.headercontainer .logoouter{-ms-flex: 0 0 150px; flex: 0 0 150px; max-width: 150px;}
.headercontainer .topmailbx a{font-size: 20px;}

.headercontainer .loginbtx i{width: 40px; height: 40px; line-height: 40px; font-size: 18px;}
.headercontainer .navigation .sf-menu > li > a:after{bottom: -29px !important;}
.headercontainer.sticky .logoouter .logo img{max-width: 94px;}
.headercontainer.sticky .navigation .sf-menu > li > a:after{bottom: -18px !important;}
.bannercontainer .bannerlt h1{font-size: 58px; line-height: 70px;}
.bannercontainer .bannerlt p{font-size: 20px; line-height: 30px;}
.bannercontainer .bannerlt ul li{font-size: 20px; line-height: 32px;}
.bannercontainer .bannerlt ul li:before{font-size: 10px; width: 26px; height: 26px; line-height: 26px;}
.theme-btn{padding: 15px 40px; font-size: 20px;}
.default-btn{padding: 15px 40px; font-size: 20px;}
/* .banrcontent {max-width: 1070px;} */

.banner-cap h4{font-size: 35px;max-width: 700px;}







/* .collect-bntx p{font-size: 20px;} */

.recycleinfo span{font-size: 16px;}


/* .blog-txt h4{font-size: 28px;max-width: 260px;} 
.todyhedng{font-size: 55px;}
.garbj-info h6{font-size: 35px;}
.wstedfrntly{max-width: 850px;}
.wstedfrntly h4{font-family: 'Proza Libre';font-size: 45px;}
.wstedfrntly h3{font-size: 55px;}
/* .study-content h3{font-size: 45px;max-width: 495px;} */
/* .resident-sec h4{font-size: 40px;} */
.resident-sec p{max-width: 315px;}
.resident-sec a{font-size: 17px;}
.recycletxt h3{font-size: 45px;padding:50px}
.recycletxt p{font-size: 30px;line-height: 154.7%; padding:50px}
/* .prtnr-heading{margin-top: 200px;} */
.prtnr-heading h3{font-size: 45px;}
.prtnr-heading p{font-size: 20px;}
.impct-hed{max-width: 800px;}
.impct-hed h3{font-size: 45px;}
.impct-hed p{font-size: 20px;}
.tones-sec p{font-size: 18px;max-width: 260px;}
.winner-sec p{font-size: 30px;}
.making-sec h3{font-size: 45px;}
.making-sec h6{font-size: 35px;}
.newslettr-sec span{font-size: 28.0727px;}
.newslettr-sec h5{font-size: 38px;}
.newslettr-sec p{font-size: 20px;}
.newslettr-sec .form-group .inputgroup .subscribebtn{font-size: 20px;}
.waste-data{max-width: 1120px;}
.team-heding h3{font-size: 45px;}
.team-heding p{font-size: 35px;}
.collect-box h4{font-size: 32px;}
.mthechmp a{font-size: 20px;}
.collect-box p{font-size: 18px;max-width: 361px;}
.collect-box a{font-size: 16px;}
.footer-abut p{font-size: 20px;}
.footer-abut span{font-size: 15px;}
.prvcylnk li{font-size: 16px;}
.foter-menu h4{font-size: 18px;}
.foter-menu ul li a{font-size: 17px;}
.impct-catg h5 sub{font-size: 28px;}
.prvcylnk li a{font-size: 16px;}



.map-sec span {
	font-size: 48px;
	padding-right: 42px;
}
.newslettr-sec span {
	font-size: 24px;
}
.newslettr-sec h5 {
	font-size: 38px;
}


}


@media (max-width: 1800px) {
	.wste-store > img{width: 100%; max-width: 98%;}
	.recycle-sec .squarepic {
		bottom: 24px;
	}
	/* .container{max-width: 1530px; width: auto;} */
	.headercontainer .loginbtx {
		margin-left: 142px;
	}
	.headercontainer .navigation .sf-menu > li a {
		font-size: 18px;
	}
	.blog-txt h4 {
		font-size: 22px;
		max-width: 200px;
	}
	.studypic .squarepic2 {
		bottom: -62px;
	}
	.recyclepic {
		max-width: 424px;
	}
	.squarepic {
		bottom: 445px;
	}
	.studypic .squarepic2 {
		
		right: 0;
	}
	.mission-info figcaption p {
		font-size: 32px;
	}
	.garbj-info p {
		font-size: 22px;
	}
	.garbj-info p span {
		font-size: 40px;
	}
	.garbj-info h6 {
		font-size: 22px;
	}
	.uplifting h3 {
		font-size: 50px;
	}
	.newslettr-sec h5 {
		font-size: 40px;
	}
}
@media (max-width: 1700px) {
	.studypic .squarepic2 {
		bottom: -136px;
	}
	.headercontainer .navigation .sf-menu > li a {
		font-size: 16px;
	}
	.headercontainer .loginbtx {
		margin-left: 55px;
	}
	.headercontainer .loginbtx a {
		font-size: 16px;
	}
	.banner-cap a {
		margin-top: 68px;
	}

	.resident-sec p {
		font-size: 20px;
		line-height: 32px;
	}
	.resident-sec h4 {
		line-height: 36px;
		font-size: 30px;
		min-height: 100px;
	}
	/* .resident-sec {
		padding: 60px 150px 20px 35px;
	} */
	.awrd-panel {
		max-width: 1120px;
	}
	.huse-wste p {
		font-size: 20px;
		line-height: 30px;
	}
	.recycleinfo span {
		font-size: 13px;
	}
	.resident-sec {
		min-height: 438px;
	}
	.recycleinfo p {
		font-size: 20px;
	}
	.wste-store img{max-width: 97%; }
	.collect-bntx p {
		font-size: 20px;
	}
	
	.bpic img {
		max-height: 310px;
	}
	.study-content h3 {
		font-size: 40px;
	}
	.unlocking h3 {
		font-size: 40px;
	}
	.impct-catg span {
		top: -66%;
	}
	.impct-catg span img {
		max-width: 29px;
	}
	.huse-wste img {
		max-height: 100px;
	}
	.collect-bntx img {
		max-height: 100px;
	}
	.recycleinfo img {
		max-height: 90px;
	}
	
}
@media (max-width: 1600px) {
	.mission-info figure {
		height: 420px;
	}
	.mission-info figcaption p {
		font-size: 22px;
	}
	.mission-info figcaption h4 {
		font-size: 32px;
	}
	.removing-content h3 {
		font-size: 38px;

	}
	.removing-content p {
		font-size: 20px;
	}
	.bloginfo .row > div {
		padding: 0 15px;
	}
	
	.collect-sec .container {
		max-width: 1040px;
	}
	.banner-cap h1 {
		font-size: 60px;
	}
	.impact-sec .col-lg-4:first-child .impact-info h4 {
		font-size: 45px;
	}
	.impact-info h4 {
		font-size: 42px;
	}
	.study-content h3 {
		font-size: 28px;
	}
	.recyclepic {
		max-width: 430px;
	}

	.recyclepic .col-6:first-child .recypic {
		margin-top: 10px;
		max-width: 140px;
		float: right;
	}
	.recyclepic .col-6:nth-child(2) .recypic {
		margin-top: -2px;
		max-width: 140px;
		margin-bottom: 20px;
	}
	.recyclepic .col-6:nth-child(4) .recypic {
		margin-top: -2px;
		max-width: 170px;
		margin-bottom: 20px;
	}
	.recyclepic .col-6:nth-child(3) .recypic {
		max-width: 160px;
		float: right;
	}
	.recyclepic .col-6:nth-child(5) .recypic {
		max-width: 208px;
		float: right;
	}
	.wste-store img {
		max-width: 93%;
	}
	.impct-catg h5 {
		font-size: 40px;
	}
	.tones-sec {
		width: 300px;
	}
	.newslettr-sec .form-group .inputgroup .form-control {
		height: 70px;
	}
	.newslettr-sec .form-group .inputgroup .subscribebtn {
		right: 4px;
		top: 5px;
		padding: 15px 19px;
		font-size: 20px;
	}
	.squarepic {
		bottom: 270px;
	}
	.impact-sec .container {
		max-width: 1320px;
	}
	.blogbox {
		min-height: 585px;
	}
	.bpic img {
		max-height: 292px;
	}
	.impact-detailsinfo .impct-hed p {
		font-size: 22px;
	}
	.covgetxt h2 {
		font-size: 60px;
	}
	.covgetxt p {
		font-size: 22px;
	}
	.covgetxt a {
		width: 210px;
		height: 60px;
		font-weight: 500;
		font-size: 19px;
	}
	.precss-heding h2 {
		font-size: 55px;
	}
	

}
@media (max-width: 1399px) {
	.headercontainer .loginbtx {
		margin-left: 18px;
	}
	.headercontainer .navigation .sf-menu > li {
		margin: 0px 10px;
	}
	.headercontainer .navigation .sf-menu > li a {
		font-size: 15px;
	}
	.headercontainer .loginbtx a {
		font-size: 15px;
		padding: 12px 20px;
	}
	.banner-cap h1 {
		font-size: 50px;
	}
	.banrcontent {
		max-width: 866px;
	}

.impact-info h4 {
	font-size: 45px;
}
.impact-sec .col-lg-4:first-child .impact-info h4 {
	font-size: 50px;
}
.impact-info p {
	font-size: 22px;
}
.mission-info figcaption h4 {
	font-size: 32px;
}
.mission-info figcaption p {
	font-size: 22px;
}
.resident-sec h4 {
	font-size: 24px;
	line-height: 25px;
	min-height: 80px;
}
.recycletxt h3 {
	font-size: 35px;
}
.impct-catg h5 {
	font-size: 42px;
}
.tones-sec {
	width: 248px;
}
.tones-sec p {
	font-size: 18px;
}
.impct-catg span img {
	max-width: 28px;
	max-height: 32px;
}
.impct-catg span{
	width: 50px;
	height: 50px;
}

.mrgntop .col-lg-6:nth-child(1) .winner-sec p {
	padding: 20px 80px;
}
/* .mrgntop .col-lg-6:nth-child(2) .winner-sec p {
	padding: 20px 115px;
} */
.winner-sec p {
	font-size: 24px;
}
.impct-catg span {
	top: -62%;
}
.wste-store img {
	height: 100%;
	object-fit: cover;
}
.wststorinfo {
	padding: 28px 0 0 50px;
}
.recycletxt p {
	font-size: 22px;
}
.removing-content p {
	font-size: 19px;
}
.removing-content h3 {
	font-size: 37px;
}
.garbj-info p {
	font-size: 24px;
}
.garbj-info h6 {
	font-size: 22px;
}
.watch-content {
	max-width: 765px;
}
.awrdtxt h3 {
	font-size: 40px;
	line-height: 63px;
}
.awrdtxt p {
	font-size: 22px;
	line-height: 35px;
}
.award-sec {
	padding: 70px 0 0;
}
.rdybtn a {
	font-size: 19px;
	line-height: normal;
	padding: 14px 20px;
}
.digitalbx p {
	font-size: 16px;
}
.appviwpic p {
	left: -105px;
	font-size: 21px;
}
.paper-waste img {
	max-width: 72%;
}
.paper-waste:last-child::after {
	bottom: -211px;
	height: 206px;
	width: 386px;
	background-size: contain;
	left: -208%;
}
.paper-waste:nth-child(3)::after {
	bottom: -181px;
	height: 251px;
	width: 189px;
	left: -42px;
}
.paper-waste:nth-child(2)::after {
	height: 172px;
}
.bintx-lower img {
	max-width: 414px;
}
.wstecrry img {
	max-width: 67%;
}
.paper-waste:nth-child(1)::after {
	bottom: -117px;
	height: 153px;
	width: 150px;
	right: -20px;
}
.wste-store {
	margin: 100px 0 0;
}
.prtnr-heading {
	margin-top: 0;
}
.extrmrgn {
	margin-top: 150px;
}
.resident-sec {
	padding: 60px 85px 20px 35px;
}
.blog-txt h4 {
	font-size: 21px;
}
.blogbox {
	min-height: 500px;
}

.blgnmbr span {
	font-size: 160px;
}
.study-content h3 {
	font-size: 32px;
}
.unlocking {
	max-width: 1024px;
}
.unlocking h3 {
	font-size: 38px;
}
.mission-info figure {
	height: 360px;
}
.huse-wste img {
	max-height: 100px;
}
.recycleinfo img {
	max-height: 100px;
}
.collect-bntx img {
	max-height: 150px;
}
.midlbdr::after {
	width: 140px;
	right: -79px;
}
.blog-txt {
	padding: 30px 30px 18px;
}
.bloghedng h2 {
	font-size: 45px;
}
.wste-store img {
	max-width: 100%;
}
.resident-sec p {
	font-size: 16px;
	line-height: 28px;
}
.resident-sec a {
	left: 35px;
	bottom: 30px;
}
.resident-sec {
	min-height: 380px;
}
/* .associate-sec > .row > div:first-child .resident-sec h4{
	min-height: auto;
} */
.impct-hed h3 {
	font-size: 55px;
}

.procss-pic {
	margin-right: 0;
}
.ytb-vid{height: auto;}

}


@media (max-width: 1200px) {
.headercontainer .loginbtx {margin-left: 0;}
.headercontainer .loginbtx a {
	font-size: 14px;
	padding: 10px 12px;
}
.headercontainer .navigation .sf-menu > li{margin: 0px 10px;}
.banner-cap h1 {font-size: 48px;}

.impct-catg h5 {font-size: 30px;}
.impct-catg h5 sub {font-size: 18px;}
.winner-sec p {
	font-size: 30px;
}
.winner-sec {
	min-height: 297px;
}

.recycleinfo img {
	max-height: 56px;
}
.collect-bntx img {
	max-height: 130px;
}
.scnclct img {
	max-height: 95px;
}
.huse-wste img {
	max-height: 70px;
}
.removing-content h3 {
	font-size: 30px;
}
.removing-content p {
	font-size: 15px;
}
.recycleinfo p {
	font-size: 14px;
}
.mission-info figcaption h4 {
	font-size: 30px;
}
.mission-info figcaption p {
	font-size: 17px;
}
.mission-info figure {
	height: 260px;
}
.impact-info h4 {
	font-size: 30px;
	line-height: normal;
}
.bloghedng h2 {
	font-size: 32px;
}
.blog-txt h4{
  font-size:17px;
}
.todyhedng {
	font-size: 30px;
}
.garbj-info h6 {
	font-size: 25px;
}
.study-content h3 {
	font-size: 30px;
}
.study-content {
	padding-left: 30px;
}

.resident-sec h4{
  font-size: 20px;
}
.tagpic img {
	max-width: 58%;
}
.recycletxt h3 {
	font-size: 35px;
}
.recycletxt p {
	font-size: 22px;
}
.newslettr-sec h5 {
	font-size: 30px;
	line-height: 40px;
}
.newslettr-sec p {
	font-size: 15px;
}
.newslettr-sec .form-group .inputgroup .subscribebtn {
	top: 8px;
	padding: 8px;
	font-size: 14px;
}
.making-sec h3 {
	font-size: 35px;
}
.making-sec h6 {
	font-size: 25px;
}
.team-heding h3 {
	font-size: 40px;
}
.team-heding p {
	font-size: 25px;
}
.collect-box h4 {
	font-size: 25px;
}
.mrgntop .col-lg-6:nth-child(1) .winner-sec p {
	padding: 20px 50px;
}
.mrgntop .col-lg-6:nth-child(2) .winner-sec p {
	padding: 20px 50px;
}
.impct-catg span {
	top: -67%;
}
.wste-store img {
	height: 100%;
}
.wststorinfo {
	padding: 30px 0 0 35px;
}
.garbj-info p {
	font-size: 18px;
}
.rdybtn a {
	font-size: 16px;
	line-height: normal;
	padding: 14px 15px;
}
.digitalbx {
	margin: 10px;
}
.resident-sec p {
	font-size: 16px;
	line-height: 27px;
}
.blog-txt h4 {
	font-size: 21px;
}
.blog-txt {
	padding: 36px 20px 18px;
}
.blog-txt h4 {
	font-size: 19px;
}
.blogbox {
	min-height: 440px;
}
.bpic img {
	max-height: 172px;
}
.garbj-info p {
	font-size: 20px;
}
.garbj-info h6 {
	font-size: 25px;
}
.tones-sec {
	width: 208px;
}
.tones-sec p {
	font-size: 15px;
}



.resident-sec {padding: 55px 40px 20px 35px;}
.championbx .chpiopic{max-width: 330px;}
.uplto {min-width: 305px;max-width: 305px;margin-left: 25px;}
.uplto p {font-size: 22px;line-height: 40px;}
.chpiopic:nth-child(2) .uplto {margin: 0 25px 0 0;}



}
@media (min-width: 992px) {
.NavBar{display: none;}
.headercontainer .navigation .navuser{display: none;}
.headercontainer.sticky{background: #36204D; position: fixed; top: 0 !important; z-index: 99; left: 0; padding: 20px 0px; width: 100%; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); -webkit-animation: stickySlideDown .65s cubic-bezier(.23,1,.32,1) both; animation: stickySlideDown .65s cubic-bezier(.23,1,.32,1) both;}
.headercontainer.sticky .topmailbx{display: none;}
.headercontainer.sticky .navigationouter{border: none; margin: 0px; padding: 0px;}
.headercontainer.sticky .navigationouter:after{display: none;}
.headercontainer.sticky .logoouter .logo img{max-width: 70px;max-height: 41px;}
.headercontainer.sticky .navigation .sf-menu > li > a:after{bottom: -14px;}
.headercontainer .loginbtx a i{display: none;}

}
@media (max-width: 991px) {
.headercontainer{padding: 10px 0px;}
.headercontainer.sticky{background: #36204D; position: fixed; top: 0 !important; z-index: 99; left: 0; padding: 10px 0px; width: 100%; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);}
.headercontainer .headtop .logobx{float: left;}
.headercontainer .logoouter .logo img{max-width: 70px; max-height: 41px; -webkit-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;}
.headercontainer.sticky .logoouter .logo img{max-width: 60px;max-height: 35px;}
.headercontainer .navigation .NavBar{float: right; font-size: 26px; text-decoration: none; color: #fff;}
.headercontainer .topmailbx{display: none;}
.headercontainer .navigation .navigationbox{position: fixed; top: 0; padding: 0; height: 100%; right: 0; margin-left: -284px; background: #204D36; border: none; padding-top: 0px; max-height: 100%; overflow: auto; margin-right: 0; transition: all 0.3s; width: 284px; z-index: 99999;}
.layout-expanded2 .headercontainer .navigation .navigationbox{margin-right: 0px;}
.layout-expanded2{overflow: hidden;}
.layoutovarlay2{position: fixed; z-index: 1000; top: 0; right: 0; height: 100% !important; left: 0; display: none; background: #181C21; opacity: .5; cursor: pointer;}
.layout-expanded2 .layoutovarlay2{display: block;}
.headercontainer .navigation .navuser{float: left; width: 100%; padding: 30px 20px 20px 20px; text-align: center; position: relative; background: #204D36; margin-bottom: 0px;}
.headercontainer .navigation .navuser .userimg{position: relative; width: 90px; height: 90px; background: #003e33; display: inline-block; text-align: center; -webkit-border-radius: 50%; border-radius: 50%;}
.headercontainer .navigation .navuser .userimg img{position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px; text-align: center; vertical-align: middle; margin: auto; width: auto; max-width: 100%; max-height: 100%; padding: 0px;}
.headercontainer .navigation .navuser h3{color: #003e32; text-align: center; margin: 10px 0px 4px 0px; font-size: 19px; padding: 0px; text-transform: capitalize;}
.headercontainer .navigation .navuser p{color: #fff; text-align: center; margin: 0px 0px 8px 0px; font-size: 13px; padding: 0px; font-weight: 400;}
.headercontainer .navigation .sf-menu{padding: 30px 0;}
.headercontainer .navigation .sf-menu > li{float: left; width: 100%; margin: 0px; padding: 0px;}
/* .headercontainer .navigation .sf-menu > li + li{border-top: 1px solid #ccc;} */
.headercontainer .navigation .sf-menu > li a{color: #fff; float: left; width: 100%; text-align: left; padding: 15px 10px; margin: 0px;}
.headercontainer .navigation .sf-menu > li > a:after{display: none;}
.headercontainer .navigation .sf-menu > li.active a{color: #F1EBBB;}
.headercontainer .navigationouter{padding: 0px; border: none;}
.headercontainer .loginbtx {
	margin: 0px;
	width: 100%;
	padding: 14px 30px;
}
.headercontainer .loginbtx > i{display: none;}
.headercontainer .loginbtx span{display: none;}
.headercontainer .loginbtx a{color: #fff; padding: 7px 12px; margin-right: 15px; margin-top: 2px;}
.headercontainer .navigationouter:after{display: none;}
.headercontainer .navigation .sf-menu{width: 100%;
	border-top: 1px solid #fff;
border-bottom: 1px solid #fff;
max-width: 80%;
margin: 0 auto;
}
.headercontainer .navigation .sf-menu-outer {
	padding-top: 80px;
}


.bannercontainer .bannerlt h1{font-size: 25px; line-height: 30px;}

.recycleinfo img {
	max-height: 45px;
}
.midlbbdr::after {
	width: 240%;
	top: -31%;
	left: -70%;
}
.midlbdr::after {
	width: 68px;
	right: -41px;
	top: 33%;
}
.huse-wste img {
	max-height: 57px;
}
.removing-content h3 {
	font-size: 25px;
}
.removing-content p {
	font-size: 14px;
}
.wthouthed h5 {
	font-size: 22px;
}
.bloghedng h2 {
	font-size: 30px;
}
.wstedfrntly h3 {
	font-size: 25px;
}
.wststorinfo {
	padding: 50px 0 0 20px;
	display: grid;
}
.recycletxt {
	order: 2;
	padding: 50px 20px 100px;
}
.recyclepic {
	order: 1;
}

.wste-store {
	clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
}
.recycletxt h3 {
	font-size: 28px;
}
.recycletxt p {
	font-size: 18px;
}
.garbj-info {
	margin: 8px 0;
}
.garbj-info h6 {
	font-size: 18px;
}
.study-content h3 {
	font-size: 30px;
	margin: 0 auto;
}
.study-content {
	padding-left: 0;
	text-align: center;
	margin-bottom: 20px;
}
.study-content img {
	display: none;
}
.prtnrlgo {
	width: 160px;
	height: 160px;
}
.impct-hed h3 {
	font-size: 35px;
}
.newslettr-sec h5 {
	font-size: 21px;
	line-height: 32px;
}
.team-heding h3 {
	font-size: 35px;
}
.team-heding p {
	font-size: 20px;
}
.team-heding {
	margin: 50px 0 20px;
}
.winner-sec span img {
	max-width: 60%;
}
.mrgntop .col-lg-6:nth-child(1) .winner-sec p {
	padding: 20px 20px;
}
.mrgntop .col-lg-6:nth-child(2) .winner-sec p {
	padding: 20px 20px;
}
.winner-sec {
	min-height: 248px;
}
.making-sec h3 {
	font-size: 30px;
}
.making-sec h6 {
	font-size: 20px;
}
.mission-info figcaption {
	padding: 15px 15px;
}
.mission-info figcaption h4 {
	font-size: 30px;
}
.mission-info figcaption p {
	font-size: 17px;
}
.blgnmbr span {
	font-size: 150px;
	line-height: 120px;
}
.impact-sec .col-lg-4:nth-child(3) {
	border-right: 2px dashed #DFDFDF;
}
.impact-sec .col-lg-4:nth-child(4) {
	border-right: none;
	border-bottom: 3px dashed #DFDFDF;
}
.impact-sec .col-lg-4:nth-child(2) {
	border-right: none;
}
.impact-info h4 sub {
	font-size: 18px;
	bottom: 1px;
}
.tones-sec p {
	font-size: 15px;
}
.winner-sec p {
	min-height: 224px;
	font-size: 22px;
}

/* ______15_09_23_____ */

.headercontainer .navigation .navigationbox {
    margin-right: -100%;
}

.impact-sec .container {border: 3px dashed rgba(32, 77, 54, 0.12);}
.impact-sec {padding: 10px;}

.championbx .chpiopic {max-width: 250px;}
.uplto {min-width: 247px; max-width: 247px; margin-left: 15px;}
.chpiopic:nth-child(2) .uplto {margin: 0 15px 0 0; bottom: 25px;}
.uplto em {font-size: 17px;line-height: 25px;}
.uplto p { font-size: 17px; line-height: 30px; }

.championbx{flex-direction: column;}
.championbx .chpiopic {max-width: 100%; display: flex; align-items: center; justify-content: space-between;}
.uplto{position: static; text-align: left; margin: 0 0 0 20px !important;}
.chpiopic:nth-child(2) .uplto p, .chpiopic:nth-child(2) .uplto {text-align: left;}
.championbx .chpiopic:nth-child(2){flex-direction: row-reverse;}


.processing-sec{padding-left: 12px; padding-right: 12px;}



}
@media (max-width: 768px){
	.impact-sec {
		margin: 0 0;
	}
.mobileview{
  display: block;
}
.dekstopviw{
	display: none;
}
.midlbbdr::after {
	width: 235%;
	top: -44%;
	left: -66%;
}
.resident-sec {
	min-height: 295px;
	padding: 50px 72px 30px 30px;
}
.tagpic img {
	max-width: 42%;
}
.resident-sec p {
	font-size: 16px;
}
.resident-sec h4 {
	font-size: 24px;
	min-height: auto;
}
.unlocking h3 {
	font-size: 22px;
}
.study-content h3 {
	font-size: 22px;
}
.prtnrlgo {
	width: 110px;
	height: 110px;
}
.extrmrgn {
	margin-top: 50px;
}

.newslettr-sec {
	max-width: 100%;
}
.making-sec .col-sm-12:nth-child(1){order: 2;}
.making-sec .col-sm-12:nth-child(2){order: 1;}
.collect-box{margin-bottom: 15px;}
.prvcylnk li a {
	font-size: 13px;
}
.foter-menu ul li a {
	font-size: 14px;
}
.foter-menu h4 {
	font-size: 18px;
	margin-bottom: 15px;
	margin-top: 15px;
}
.prtnr-heading h3 {
	font-size: 30px;
}
.prtnr-heading p {
	font-size: 16px;
}
.prtnrlgo {
	margin-top: -24px;
}
.impectN .row:last-child {
	margin: 0 -4px;
}
.winner-sec span img {
	max-width: 150px;
}
.collect-box h4 {
	font-size: 20px;
}
.collect-box p {
	font-size: 15px;
	margin: 20px auto;
}
.making-sec h3 {
	font-size: 25px;
}
.making-sec h6 {
	font-size: 18px;
}
.impct-hed h3 {
	font-size: 30px;
}
.blog-txt {
	padding: 20px 20px;
	min-height: 196px;
}
.bpic img {
	max-height: 200px;
}
.watch-content {
	padding: 40px 0;
}
.watch-content h5 {
	font-size: 18px;
}
.video-box video{height: 120px;}
.todyhedng {
	font-size: 30px;
	text-align: left;
	font-weight: 800;
}

.impct-catg h5 {
	font-size: 32px;
}
.tones-sec p {
	font-size: 14px;
}
.impct-catg span {
	top: -80%;
}
.studying-sec .row:last-child{flex-direction: column-reverse;}
.impact-details {
	margin: 80px 0 50px;
}
.banner-cap h1 {
	font-size: 30px;
}
.banner-cap h4 {
	font-size: 20px;
	line-height: normal;
}
.dkstpimpct{display: none;}
.mobilimpct{display: block;}
.impectN .row:last-child > div {
	width: 50%;
	padding: 0 4px;
}
.impct-catg span img {
	max-width: 26px;
	max-height: 22px;
}
.impct-catg h5 sub {
	font-size: 14px;
}
.impct-catg {
	min-width: 100%;
	width: 100%;
	padding: 10px 14px;
}
.tones-sec p {
	font-size: 10px;
	margin: 0;
	padding: 0 12px;
}

/* ______15_09_23_______ */

.headercontainer .navigation .navigationbox { margin-right: -100%;}

.resident-sec {min-height: 310px;}



}
@media (max-width: 767px){
	.resident-sec a {
		position: relative;
		bottom: 0;
		left: 0;
	}
	.bloginfo .row > div {
		padding: 0 5px;
	}
	.bloginfo {
		max-width: 98%;
	}
	.bpic img {
		max-height: 165px;
	}
	.tones-sec {
		width: 268px;
		margin: 0 auto;
		margin-bottom: 80px;
	}
	.removing-content {
		padding: 0 0;
	}
.impct-hed:first-child h3{
	display: none;
}
.impct-hed p {
	font-size: 16px;
	font-weight: 500;
	text-align: center;
}
.tagpic {
	display: flex;
	justify-content: end;
}
.waste-challenge::before {
	clip-path: none;
}
.resident-sec {
	min-height: 270px;
}
.associate-sec .owl-nav{display: none;}
.associate-sec .owl-dots{margin-bottom: 15px;}
.associate-sec .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
	background: #F9867F;
}
.dksvrsn{display: none;}
.mobile-viw{display: block;}
.winner-sec p {
	font-size: 16.5px;
	line-height: 20px;
	max-width: 227px;
	min-height: 107px;
	padding: 20px;
}
.rckyl::after {
	height: 50px;
	top: -61px;
}
.recycleinfo img {
	max-height: 59px;
}
.lstbdr::after {
	height: 50px;
	top: -61px;
}
.huse-wste p {
	font-size: 16px;
	line-height: 28px;
}
.tonenon{display: none;}

.impcmobiltxt{
	display: block;
  }
  .wstecolect {
	padding-top: 42px;
	background: #FFFFFF;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}
.nav.nav-tabs .nav-link.active {
	border-bottom: 1px solid #36204D;
}
.tabbox-sec{
	display: block;
  }
  .wtoutbntx{
	display: none;
  }
  .wthouthed h5 {
	display: none;
}



.resident-sec {padding: 25px; min-height: auto;}
.resident-sec p br{display: none;}
.resident-sec a{padding: 0 35px;}
.resident-sec p{min-height: 135px;}
.resident-sec h4 {font-size: 20px; min-height: 51px;}




}

@media (max-width: 575px){
.headercontainer .logoouter{-ms-flex: 0 0 100px; flex: 0 0 100px; max-width: 100px;}
.headercontainer .loginbtx a {
	font-size: 12px;
	background: #FFFFFF;
	border-radius: 6px;
	color: #36204D;
}
.bannercontainer .bannerlt h1{font-size: 22px; line-height: 26px;}
.headercontainer .navigation .navigationbox{margin-right: -100%; width: calc(100% - 100px);}


.midlbdr::after {
	width: 120px;
	right: -26%;
}
.banner-cap h4 {
	font-size: 22px;
	line-height: normal;
	margin-top: 15px;
}
.banner-cap h1 {
	font-size: 44px;
}
.banner-cap a {
	margin-top: 50px;
}
.resident-sec {
	min-height: 327px;
}
.squarepic {
	left: 0;
	bottom: 312px;
}
.removing-content {
	margin-top: 70px;
}
.wstedfrntly h3 {
	font-size: 22px;
}
.wstedfrntly h4 {
	font-size: 22px;
	margin-bottom: 20px;
}
.wstedfrntly {
	margin: 50px auto 70px;
}
.study-content h3 {
	font-size: 22px;
}
.study-content {
	margin: 20px 0;
}
.unlocking h3 {
	font-size: 22px;
	line-height: 162.7%;
}
.wste-store {
	clip-path: polygon(-10px -75px, 100.3% -73px, 100.33% 70.81%, 0% 100%);
	background: linear-gradient(179.97deg, #36204D 26.94%, #000000 226.84%);
	padding-bottom: 30px;
	margin: 0;
}
/* .wste-store img {
	height: 65%;
} */
.recycletxt h3 {
	font-size: 25px;
	line-height: 154.7%;
}
.recycletxt p {
	font-size: 20px;
	margin-top: 20px;
}
.recycletxt {
	max-width: 100%;
	padding: 15px 20px 15px;
}
.wststorinfo {
	padding: 30px 0 0 15px;
}
.newslettr-sec .form-group .inputgroup .form-control {
	font-size: 14px;
}
.team-heding h3 {
	font-size: 25px;
	margin-bottom: 0;
}
.team-heding p {
	font-size: 25px;
	max-width: 300px;
	margin: 0 auto;
	line-height: 30px;
}
.our-team {
	margin: 0 0;
}
.newslettr-sec .form-group .inputgroup .subscribebtn {
	font-size: 13px;
}
.making-sec {
	margin: 50px 0 0 0;
}
.mthechmp {
	margin-top: 18px;
}
.blog-sec {
	margin: 50px 0 20px;
}
.watch-jurny {
	margin: 0 0 0;
}
.video-box video {
	height: 90px;
}
.video-box::before {
	height: 60px;
	width: 75px;
}
.video-box::after {
	height: 58px;
	width: 79px;
}
.video-box {
	padding: 9px;
	position: relative;
}
.recycle-sec {
	margin: 0 0;
	z-index: 3;
	position: relative;
	margin-top: 150px;
}

.midlbbdr::after {
	top: -28%;
}
.rckyl::after {
	height: 44px;
	top: -52px;
}
.lstbdr::after {
	height: 44px;
	top: -52px;
}
.wststorinfo {
	padding: 30px 0 0 20px;
}
.associate-sec {
	margin: 0;
	padding: 40px 0 100px;
}
.blog-txt h4 {
	font-size: 17px;
	line-height: 21px;
}
.ocean-sec {
	margin: 0 0;
}
.without-bntx {
	padding: 30px 0;
}
.impact-info h4 {
	font-size: 30px;
}
.impact-sec .col-lg-4:first-child .impact-info h4 {
	font-size: 30px;
	color: #204D36;
}
.team-pic img {height: 72px;}
.team-pic img:first-child {height: auto;}
.impact-info {
	padding: 20px 10px;
}
.dkstbnr{display: none !important;}
.mbnr{display: block !important;}
.midlbdr::after {
	width: 74px;
	right: -25%;
}
.watch-content {
	padding: 30px 0;
}
.collect-sec {
	margin: 30px 0;
}
.prtnviw .squarepic2{
	display: none;
}
.recycle-sec .squarepic {
	display: none;
}
.withbntix-sec .squarepic {
	display: none;
}
.collect-box p {
	font-size: 14px;
}
.collect-box a {
	padding: 8px 15px;
	font-size: 13px;
}
.footer-sec {
	padding:30px 0 10px 0;
}
.foter-menu ul li a {
	font-size: 13px;
}
.impact-sec .container{
	border: 3px dashed rgba(32, 77, 54, 0.12);
}
.impact-sec .col-lg-4 {
	border-right: 3px dashed #DFDFDF;
	border-bottom: 3px dashed #DFDFDF;
}
.impact-sec {
	padding: 10px;
}
.missionsec .owl-nav{
	display: none;
}
.bloginfo .owl-nav{
	display: none;
}
.waste-data .col-4{padding: 8px 10px;}
.team-pic {
	margin-bottom: 0;
}
.studypic .squarepic2 img {
	display: none;
}
.garbej-sec .squarepic2 {
	bottom: -230px;
	right: 0px;
}
.garbej-sec .squarepic2 img {
	max-width: 43%;
}
.recylm{display: none;}
.recyclepic .col-6{padding: 5px;}
.recypic img {
	max-width: 74%;
}
.recypic {
	margin-bottom: 0px;
}
.mobilrecycle {
	position: relative;
	width: 100%;
	z-index: 2;
	top: 0;
	padding: 0 0;
	display: flex;
}
.mobilrecycle .rcylpic img {
	border: 3px solid #fefff9;
	border-radius: 10px;
	-webkit-filter: drop-shadow(10.6428px 10.6428px 26.6069px rgba(0,0,0,.25));
	filter: drop-shadow(10.6428px 10.6428px 26.6069px rgba(0,0,0,.25));
	max-width: 100%;
}
.rcylpic{
	margin: 3px 2px;
}
.mobilrecycle .col-6{
	padding: 0 2px;
}
.mobilrecycle .row {
	max-width: 100%;
	margin: 0 -2px;
}


.wststorinfo {
	padding: 15px;
	display: block;
	margin-top: -45px;
	position: absolute;
	top: -69px;
}
.wste-store img {
	position: relative;
	max-width: 100%;
	height: auto !important;
}
.waste-challenge {
	margin: 50px 0 0;
}

.recyclepic{
	display: none;
}
.recycle-sec .container{padding: 0;margin-bottom:50px;}

.mobilrecycle .row > div:first-child{
	width: 135px;
}
/* .mobilrecycle .row > div:last-child{
	width: 160px;
} */
.mobilrecycle .row > div:last-child .rcylpic:last-child{
	width: 113px;
}
.withbntix-sec {
	padding: 40px 12px 58px;
}
.removing-content h3 {
	font-size: 30px;
	color: #204D36;
}
.removing-content p {
	font-size: 16px;
	color: #121212;
}
.missionsec {
	background: #fff;
	padding-bottom: 0;
	margin-top: 130px;
}
.recycleinfo img {
	max-height: 82px;
}
.huse-wste img {
	max-height: 100px;
}
.midlbdr img{
	max-height: 87px;
}
.lsticn img{
	max-height: 66px;
}
.fstbdr::after {
	height: 270px;
	bottom: -270px;
}
.fstbdr {
	margin-bottom: 108px;
}
.awrd-panel .row > div:nth-child(3) .taginfo{
	margin-top: 10px;
}
.awrd-panel .row > div:nth-child(3) .taginfo img{
	max-width: 80%;
}
.awrd-panel .row > div:nth-child(4) .taginfo{
	margin-top: 10px;
}
.awrd-panel .row > div:nth-child(4) .taginfo img{
	max-width: 80%;
}
.winner-sec {
	padding: 40px 20px 15px;
	margin: 8px 0 30px;
}
.hedbtom > .mobilimpct{
	font-weight: 800;
	text-transform: uppercase;
	display: none;
}
.mobile-viw .owl-stage{padding-left: 0 !important;}
.blogbox {
	min-height: 405px;
}
.ourvsnmsn .owl-stage {
	padding-left: 0 !important;
}
.impact-info p {
	font-size: 14px;
	max-width: 100%;
}
.bloghedng h2 {
	font-size: 30px;
}
.garbj-info h6 {
	font-size: 22px;
}
.garbj-info p {
	line-height: 33px;
	font-size: 18px;
}
.recycletxt p {
	font-size: 22px;
	color: #204D36;
}
.pcthdnon{display: none !important;}
.missionsec .squarepic img{filter: none;}
.bloghedng h2 {
	font-size: 30px;
	text-align: left;
}
.banner-cap a {
	font-size: 20px;
}
.garbjpic{margin-bottom: 15px;}
.impact-info h4 {
	color: #363636;
}
/* .impact-sec .col-lg-4:nth-child(4) .impact-info h4 {
	color: #363636;
}
.impact-sec .col-lg-4:nth-child(5) .impact-info h4 {
	color: #363636;
} */
.mbicn{display: none;}
.dkicn{display: block;}
.newslettr-sec .form-group .inputgroup .form-control {
	height: 50px;
}
.newslettr-sec span {
	font-size: 12px;
}
.impact-sec .col-lg-4:nth-child(4) {
	padding-top: 0;
}
.impact-sec .col-lg-4:nth-child(5) {
	padding-top: 0;
}
.impact-sec .col-lg-4:nth-child(6) {
	padding-top: 0;
}
.impact-sec .col-lg-4:nth-child(3) {
	order: 4;
	border-right: none;
}
.impact-sec .col-lg-4:nth-child(4) {
	order: 3;
	border-right: 3px dashed #DFDFDF;
}
.impact-sec .col-lg-4:nth-child(5) {
	order: 5;
}
.impact-sec .col-lg-4:nth-child(6) {
	order: 6;
}
.container{
	padding: 0 15px;
}

.blogbox {
	min-height: 380px;
}
.bpic img {
	max-height: 150px;
}
.impact-info h4 {
	font-size: 28px;
}
.impact-info {
	padding: 20px 0;
}
.resident-sec {
	min-height: 255px;
}
.associate-sec .row > div {
	padding: 0 5px;
}
.resident-sec h4 {
	font-size: 16px;
	line-height: 19px;
	max-width: 117px;
	margin-bottom:12px;
}
.resident-sec {
	padding: 40px 15px 30px 15px;
	margin-top: 12px;
}
.resident-sec p {
	font-size: 14px;
	line-height: 18px;
	min-height: 100px;
}
.resident-sec a {
	position: absolute;
	bottom: 15px;
	left: 12px;
}
.wste-store {
	clip-path: inherit;
	background: none;
}
.tagpic img {
	max-width: 55px;
}
.resident-sec a {
	padding: 0 15px;
	font-size: 8px;
	height: auto;
	line-height: 21px;
	border-radius: 4px;
}

.associate-sec .row > div:first-child {
	order: 1;
}
.associate-sec .row > div:nth-child(2) {
	order: 2;
}
.associate-sec .row > div:nth-child(5) {
	order: 5;
}
.associate-sec .row > div:nth-child(3) {
	order: 4;
}
.associate-sec .row > div:nth-child(4) {
	order: 4;
}

.associate-sec .row > div:first-child .resident-sec p{
	padding-top: 20px;
}

.processing-sec {
	display: grid;
}
.process-prcnt {
	order: 1;
	gap: 10px;
	padding: 0 12px;
}
.procss-pic {
	margin-right: 0;
	margin-bottom: 15px;
}

.uplto {
	position: relative;
	left: 0;
	margin-left: 26px;
	padding-right: 10px;
	/* max-width: 45%; */
	min-width: auto;
}
.chpiopic {
	position: relative;
	display: flex;
	align-items: center;
}
.championbx {
	display: block;
}
.chpiopic:nth-child(2) .uplto {
	right: 0;
	order: 1;
	bottom: 0;
	margin:0 26px 0 0;
}
.chpiopic:nth-child(2) figure {
	order: 2;
}
.chpiopic figure:nth-child(2){
	display: none;
  }
  .chpiopic figure:first-child{
	display: block;
  }
  .chpiopic figure img {
	border-radius: 0 28px 28px 0;
}
.chpiopic:nth-child(2) figure img{
	border-radius: 28px 0 0 28px;
}
.chpiopic figure{
	max-width: 45%;
	width: 100%;
}
.uplto p {
	font-size: 22px;
	line-height: 30px;
}
.uplto em {
	font-size: 16px;
	line-height: 36px;
}
.uplifting h3 {
	font-size: 28px;
	line-height: normal;
}
.wrkfrcbx h4 {
	font-size: 25px;
	line-height: normal;
}
.wrkfrcbx p {
	font-size: 16px;
	line-height: 23px;
}
.newimpct{
	margin-bottom: 0 !important;
	min-height: 777px !important;
	background-size: 100% 100%;
}
.context-sec{
	display: none;
}
.reflectin-sec{
	display: none;
}
.ustopmrgn {
	margin-top: 0;
}
.customer-sec{
	padding: 50px 0 30px;
}
.scndprcs img{
	max-width: 60%;
  }
  .scndprcs img:last-child {
	max-width: 40%;
}

.tones-sec p {
    font-size: 11px  !important;
}

.dkslgonon{
	display: none;
}
.mobile-viwchng{
	display: block;
}

.championbx .chpiopic:nth-child(2) {flex-direction: initial;}





}

@media (max-width: 480px){

.without-bntx .nav.nav-tabs .nav-link {font-size: 18px;line-height: 25px;padding: 6px 0;}
.without-bntx .nav.nav-tabs .nav-item {padding: 0 10px;}


}

@media (max-width: 440px){

	.recycleinfo a {
		min-height: 20px;
		font-size: 15px;
	}
	.making-sec h3 {
		font-size: 20px;
	}
	.making-sec h6 {
		font-size: 15px;
		border-bottom: 2px dashed #204C35;
	}
	
	.waste-challenge {
		margin: 0 0;
	}
	.watch-jurny {
		margin: 28px 0;
	}

	.garbj-info h6 {
		font-size: 22px;
	}


	/* .prtnrlgo {
		width: 90px;
		height: 90px;
	} */
	.prtnrlgo img {
		max-width: 67%;
	}
	.partner-sec {
		margin: 0 0;
	}
	.impact-details {
		margin: 50px 0 50px;
	}
	.impct-hed h3 {
		font-size: 25px;
		margin-top: 60px;
	}

	.prtnr-heading h3 {
		font-size: 25px;
		margin-bottom: 0;
	}
	.midlbbdr::after {
		width: 254%;
		left: -75%;
	}

	.blog-txt {
		padding: 15px 12px;
	}
	.blgnmbr span {
		font-size: 110px;
		line-height: 110px;
	}
	.newslettr-sec h5 {
		font-size: 18px;
		line-height: 26px;
		max-width: 80%;
		margin: 0 auto;
	}
	.newslettr-sec h5 {
		font-size: 18px;
		line-height: 26px;
	}
	.newslettr-sec p {
		font-size: 13px;
	}

	

}
@media (max-width: 400px){

.bpic img {
	max-height: 132px;
}
.blogbox {
	min-height: 360px;
}
.missionsec .squarepic {
	bottom: inherit;
	top: 12%;
}
.newimpct {
	min-height: 900px !important;
}
}

@media (max-width: 380px){
	.blogbox {
		min-height: 336px;
	}
	.bpic img {
		max-height: 115px;
	}
	.blog-txt h4 {
		font-size: 16px;
	}
	.impact-info h4 {
		font-size: 25px;
	}
	.impct-catg h5 {
		font-size: 28px;
	}
}

@media (max-width: 380px){
	.impct-catg h5 {
		font-size: 25px;
	}
	.impct-catg span {
		left: 15px;
	}
	.banner-cap h1 {
		font-size: 35px;
	}
	.banner-cap h4 {
		font-size: 20px;
	}
}
@media (max-width: 370px){
	.banner-cap h1 {
		font-size: 32px;
	}
}
