.context-sec {
	padding-top: 30px;
	overflow: hidden;
}

/* .context-bg img {
	max-width: 100%;
	width: 100%;
	border-radius: 10px;
} */

.cntxtwopic {
	display: flex;
	padding: 0 0;
	gap: 46px;
	max-width: 1386px;
	margin: 0 auto;
	align-items: flex-start;
}
.cntxtwopic img {
	width: 100%;
	border-radius: 10px;
	object-fit: contain;
	border-radius: 50px;
}

.context-bg h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 55px;
	line-height: 65px;
	text-transform: capitalize;
	color: #36204D;
	text-align: center;
	margin-bottom: 45px;
}

.howdo-sec {
	margin-bottom: 200px;
}

.howdo-sec::before {
	top: 0 !important;
}

.howdo-sec .unlocking {
	position: relative;
	top: 120px;
}

.howdo-sec .wstedfrntly {
	margin-top: 0;
	margin-bottom: 100px;
}

.howdo-sec .wstedfrntly h4 {
	font-size: 55px;
	font-family: 'Poppins', sans-serif;
}

.howdo-sec .squarepic2 {
	display: none;
	position: absolute;
	right: 0;
	top: -55px;
}

.howdo-sec .squarepic2 img {
	max-width: 164px;
}

/* .howdo-sec::before {
	clip-path: polygon(100% 0px, 100.06% 84.03%, 46.98% 82.72%, -9px 89.86%, 0px 0px);
} */

.howdo-sec .unlocking h3 {
	color: #36204D;
	font-size: 45px;
}

.studying-sec {
	position: relative;
}

.howdo-sec>.container>.studying-sec>.row:last-child div .studypic {
	margin-bottom: 0;
}

.reflectin-sec {
	padding: 40px 0 40px;
	display: none;
}

.reflectin-content {
	border-top: 5px solid #36204D;
	width: 100%;
}

.reflectin-content h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 45px;
	line-height: 65px;
	text-transform: capitalize;
	color: #36204D;
	padding: 30px;
}

.refectpra {
	padding: 30px 0 30px 55px;
	position: relative;
}

.refectpra::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 5px;
	height: 50%;
	content: '';
	background: #36204D;
}

.refectpra p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: #36204D;
	margin-bottom: 5px;
}

.harnessing{
	display: none;
}

.harnessing-inner {
	width: 100%;
	padding: 50px 0;
	max-width: 1386px;
	margin: 0 auto;
}

.harness-txt {
	padding: 70px 30px 50px;
}

.harness-txt p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 40px;
	color: #36204D;
	text-align: right;
	margin-bottom: 50px;
}

.harnespic {
	width: 100%;
	padding: 50px 0 30px 55px;
	position: relative;
}

.harnespic::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 5px;
	height: 80%;
	content: '';
	background: #36204D;
}

.harnespic img {
	max-width: 100%;
}

.dublpic {
	display: flex;
}

.dpic {
	margin-bottom: 3px;
}



.cntxt-bg {
	background: #FEFFF9;
}

.client-sec {
	background: #FEFFF9;
	width: 100%;
	overflow: hidden;
}

.client-sec h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 55px;
	line-height: 92px;
	text-transform: capitalize;
	color: #36204D;
	text-align: center;
	margin-bottom: 30px;
}

.listItem {
	margin: 100px 0 0;
}

.listItem>.row {
	margin: 0 -50px 0;
	padding: 75px 0;
}

.listItem>.row>div {
	padding: 0 50px;
}

.chompic {
	text-align: right;
	position: relative;
	z-index: 1;
}

.bcode1 {
	position: absolute;
	top: 122px;
	right: -4px;
}

.bcode2 {
	position: absolute;
	bottom: -80px;
	right: 0;
}

.bcode2 img {
	max-width: 80%;
}

.bcode3 {
	position: absolute;
	bottom: -80px;
	left: 0;
}

.bcode3 img {
	max-width: 80%;
}

.bcode4 {
	position: absolute;
	top: -60px;
	right: 0;
}

.bcode4 img {
	max-width: 80%;
}

.bcode5 {
	position: absolute;
	bottom: -10px;
	right: 0;
	z-index: -1;
}

.bcode5 img {
	max-width: 80%;
}

.listItem>.row:nth-child(even) {
	flex-direction: row-reverse;
}

.listItem>.row:nth-child(2) {
	background: #fff;
}

.listItem>.row:nth-child(4) {
	background: #fff;
}

.listItem>.row:nth-child(even) .clntinr-hdr {
	text-align: right;
}

.listItem>.row:nth-child(even) .clinettxt {
	text-align: right;
}

.listItem>.row:nth-child(even) .clinettxt span {
	float: right;
}

.listItem>.row:nth-child(even) .clinettxt p {
	padding: 0 15px 0 0;
}

.listItem>.row:nth-child(even) .chompic {
	text-align: left;
}

.client-sec h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 56px;
	color: #262626;
	margin-bottom: 50px;
}

.clinettxt {
	margin-bottom: 30px;
}

.clinettxt span {
	float: left;
	padding-top: 8px;
}

.clinettxt p {
	overflow: hidden;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 34px;
	letter-spacing: -0.015em;
	color: #36204D;
	padding-left: 15px;
}

.clinettxt a {
	width: 220px;
	height: 65px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	color: #FFFFFF;
	background: #36204D;
	box-shadow: 0px 9.46667px 9.46667px #E3E9FF;
	border-radius: 10.4005px;
	text-decoration: none;
	margin-top: 20px;
}

.clinettxt a:hover {
	background: #204D36;
}

.client-sec .container {
	max-width: 1450px;
}

.case-studies-sec {
	padding: 130px 0 265px;
	background: #fff;
}

.listItem>.row:last-child {
	margin-bottom: 0;
}

.case-studies-sec h2 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 55px;
	line-height: 60px;
	text-align: center;
	color: #341F4B;
	margin-bottom: 0px;
}

.case-info {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 280px 0 0;
	min-height: 405px;
	max-width: 1545px;
	margin-left: auto;
	margin-right: auto;
	padding: 25px;
}

.case-info {
	position: relative;
	z-index: 1;
}

.case-info:first-child {
	margin: 150px auto 0;
}

.case-studies-detl>.case-info:nth-child(even) {
	flex-direction: row-reverse;
}

.case-info:nth-child(even) .case-pic {
	left: auto;
	right: 0;
}

.case-studies-detl>.case-info:nth-child(even)::before {
	right: inherit;
	left: 0;
}

.case-studies-detl>.case-info:nth-child(even) .case-txt {
	padding-left: 50px;
	max-width: 730px;
}

.case-studies-detl>.case-info:nth-child(even) .case-txt::after {
	bottom: 0;
	right: auto;
	left: 0;
}

.case-studies-detl>.case-info:nth-child(even) .case-pic::before {
	right: inherit;
	left: -30px;
	top: -25px;
}

.case-studies-detl>.case-info:nth-child(1) .case-txt {
	padding-left: 0;
	max-width: 760px;
	width: 100%;
}

.case-studies-detl>.case-info:nth-child(2) .case-pic {
	left: auto;
	right: 0;
	top: 58%;
	max-width: 850px;
}

.case-studies-detl>.case-info:nth-child(2) .case-txt::after {
	bottom: auto;
	right: auto;
	left: 0;
	top: 0;
}

.case-studies-detl>.case-info:nth-child(2) .case-txt {
	padding-left: 0;
	max-width: 760px;
	width: 100%;
}

.case-studies-detl>.case-info:nth-child(2) .case-pic::after {
	right: 92px;
	left: inherit;
	bottom: 50px;
}

.case-studies-detl>.case-info:nth-child(2) .case-txt p {
	text-align: right;
}

.case-studies-detl>.case-info:nth-child(3) .case-txt {
	max-width: 730px;
}

.case-studies-detl>.case-info:nth-child(3) .case-pic::after {
	left: -73px;
	bottom: -34px;
}

.case-studies-detl>.case-info:nth-child(3) .case-pic::before {
	right: -109px;
	top: -40px;
}

.case-studies-detl>.case-info:nth-child(4) .case-pic::after {
	right: -2px;
	left: inherit;
	bottom: -25px;
}

.case-studies-detl>.case-info:nth-child(4) .case-pic::before {
	left: -20%;
	top: -25px;
}

.case-studies-detl>.case-info:nth-child(4) .case-txt {
	padding-left: 29px;
	max-width: 700px;
}

.case-studies-detl>.case-info:nth-child(4) .case-txt::after {
	bottom: auto;
	right: auto;
	left: 0;
	top: 0;
}

.case-studies-detl>.case-info:nth-child(4) .case-txt p {
	text-align: right;
}

.case-studies-detl>.case-info:nth-child(5) .case-pic::after {
	left: -73px;
	bottom: 20px;
}

.case-studies-detl>.case-info:nth-child(5) .case-pic::before {
	right: -109px;
	top: 3px;
}

.case-studies-detl>.case-info:nth-child(6) {
	flex-direction: row;
}

.case-studies-detl>.case-info:nth-child(6) .case-txt::after {
	bottom: 0;
	right: 0;
	left: auto;
}

.case-studies-detl>.case-info:nth-child(6) .case-pic {
	left: 0;
	right: auto;
}

.case-studies-detl>.case-info:nth-child(6)::before {
	right: 0;
	left: auto;
}

.case-studies-detl>.case-info:nth-child(6) .case-pic::after {
	left: -83px;
	bottom: -33px;
}

.case-studies-detl>.case-info:nth-child(6) .case-txt {
	max-width: 850px;
}

.case-studies-detl>.case-info:nth-child(6) .case-txt p {
	text-align: left;
}

.case-studies-detl>.case-info:nth-child(6) .case-pic::before {
	right: -109px;
	top: -70px;
	left: auto;
}

.case-studies-detl>.case-info:nth-child(7) {
	flex-direction: row-reverse;
}

.case-studies-detl>.case-info:nth-child(7) .case-pic {
	left: auto;
	right: 0;
}

.case-studies-detl>.case-info:nth-child(7)::before {
	right: auto;
	left: 0;
	width: 95%;
}

.case-studies-detl>.case-info:nth-child(7) .case-pic::before {
	right: auto;
	left: -46%;
}

.case-studies-detl>.case-info:nth-child(7) .case-pic::after {
	left: auto;
	bottom: -26px;
	right: 0;
}

.case-studies-detl>.case-info:nth-child(7) .case-txt p {
	text-align: right;
}

.case-studies-detl>.case-info:nth-child(7) .case-txt {
	max-width: 778px;
}

.case-studies-detl>.case-info:nth-child(7) .case-txt::after {
	bottom: auto;
	right: auto;
	left: 0;
	top: 0;
}

.case-studies-detl>.case-info:nth-child(7) .case-txt::after {
	bottom: 0;
	right: auto;
	left: 0;
}

.mobilecase:nth-child(2):before {
	background: #204D36 !important;
	width: 100%;
}

.mobilecase:nth-child(2) .case-pic {
	max-width: 590px !important;
}

.mobilecase:nth-child(2) .case-pic::after {
	right: -22px !important;
}

.case-info::before {
	width: 75%;
	position: absolute;
	right: 0;
	background: #36204D;
	border-radius: 40px;
	content: '';
	top: 0;
	z-index: -1;
	bottom: 0;
}

.mobilecase .case-pic {
	width: 100%;
	text-align: center;
	top: 50% !important;
	max-width: 600px;
}

.case-pic {
	max-width: 880px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.case-pic img {
	max-width: 100%;
}

.case-pic::before {
	position: absolute;
	width: 320px;
	height: 320px;
	border-radius: 100%;
	right: 0;
	top: -25px;
	content: '';
	background: #4A2D67;
	z-index: -1;
}

.case-pic::after {
	position: absolute;
	width: 320px;
	height: 320px;
	border-radius: 100%;
	left: 31px;
	bottom: 28px;
	content: '';
	background: #4A2D67;
	z-index: -1;
}

.case-txt {
	max-width: 680px;
	margin: 0 0;
	position: relative;
}

.case-txt::after {
	width: 86px;
	height: 86px;
	background: #4A2D67;
	border-radius: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	content: '';
	z-index: -1;
}

.case-txt p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 55px;
	letter-spacing: 0.025em;
	color: #FFFFFF;
}

.mobilecase::before {
	background: #204D36 !important;
}

/* .mobilecase{
	display: none;
} */


.wstegvlu-sec {
	width: 100%;
	padding: 80px 0;
	position: relative;
	overflow: hidden;
}
.wstlftpic{
	position: absolute;
	left: 0;
	top: 10%;
}
.wstlftpic img{
	max-width: 90%;
}
.wstlftpic2 {
	position: absolute;
	right: 0;
	bottom: 45%;
	text-align: right;
}
.wstlftpic2 img{
	max-width: 90%;
}

.wastevalue-inner {
	width: 100%;
	padding: 50px 0;
	max-width: 1386px;
	margin: 0 auto;
}

.mirrortxt {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mrirleft {
	width: 49%;
	padding: 0 25px;
	border-right: 5px solid #36204D;
}

.mrirleft h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 50px;
	line-height: 71px;
	text-transform: capitalize;
	color: #36204D;
}
.mrirleft h4 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 60px;
	text-transform: capitalize;
	color: #36204D;
}
.mrirleft p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 36px;
	color: #36204D;
}

.mrirright {
	width: 48%;
	position: relative;
	padding: 0 30px 0 15px;
}
/* .mrirright::before {
	position: absolute;
	left: -29px;
	top: 30%;
	background: #36204D;
	height: 185px;
	width: 5px;
	content: '';
} */

.mrirright p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 27px;
	line-height: 46px;
	color: #36204D;
}

.mrorobtompic {
	display: flex;
	padding: 100px 0 0;
}
.mrorobtompic li{
	list-style-type: none;
}
.mrorobtompic li img{
	max-width: 100%;
	border-radius: 50px;
}
.mrorobtompic li:first-child{
	margin: -100px 130px 0 0;
}
.prtnrbtn {
	background: #36204D;
	box-shadow: 0px 9.46667px 9.46667px #E3E9FF;
	border-radius: 10.4005px;
	padding: 21px 60px;
	display: inline-block;
	text-decoration: none;
	font-weight: 700;
	font-size: 20px;
	text-align: center;
	color: #FFFFFF;
	margin-top: 210px;
}
.prtnrbtn:hover {
	background: #204D36;
	color: #F1EBBB;
}

.case-picextra {
	max-width: 600px;
	position: absolute;
	right: 65px;
	bottom: -35px;
}
.case-picextra img{
	max-width: 100%;
}
.case-picextra::after {
	position: absolute;
	width: 380px;
	height: 380px;
	border-radius: 100%;
	right: -103px;
	bottom: -26px;
	content: '';
	background: #4A2D67;
	z-index: -1;
}

.newcasedsgn {
	min-height: 747px;
}
.newcasedsgn::before{
	width: 100%;
}

.newcasedsgn .case-pic {
	top: 25% !important;
	left: -5% !important;
}
.newcasedsgn .case-txt {
	top: -72px;
	padding-right: 280px;
	padding-left: 0 !important;
}
.newcasedsgn .case-txt::after {
	bottom: 82px !important;
	right: 39px !important;
}
.newcasedsgn .case-pic::before {
	right: -143px !important;
	top: -28px !important;
}
.newcasedsgn .case-pic::after {
	left: -10px !important;
	bottom: -114px !important;
}
.mobile-context-sec{
	display: none;
}
.geoglepic {
	position: absolute;
	bottom: -76px;
	left: 40%;
}
.geoglepic2 {
	position: absolute;
	bottom: -84px;
	left: 36%;
}



@media (max-width: 1800px) {
	.clntbn .banner-cap a {
		margin-top: 35px;
	}

	.case-pic {
		max-width: 660px;
	}

	.case-txt {
		max-width: 624px;
	}

	.case-txt p {
		font-size: 28px;
		line-height: 55px;
	}

	.case-info {
		min-height: 284px;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-txt {
		max-width: 590px;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-pic::after {
		bottom: 4px;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-txt {
		max-width: 578px;
		padding-left: 0;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-txt {
		max-width: 584px;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-txt {
		padding-left: 29px;
		max-width: 600px;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-pic::after {
		bottom: -25px;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-txt {
		max-width: 700px;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-pic {
		max-width: 600px;
	}

	.banner-cap h1 {
		font-size: 65px;
	}

	.wastevalue-inner {
		max-width: 1240px;
	}
	.mrirleft h4 {
		font-size: 40px;
		line-height: 61px;
	}
	.mrirright p {
		font-size: 24px;
		line-height: 40px;;
	}
	.case-info:first-child {
		margin: 210px auto 0;
	}
	.newcasedsgn {
		min-height: 747px;
	}
	.newcasedsgn .case-txt {
		padding-right: 200px;
	}
	.geoglepic {
		left: 47%;
	}
	.geoglepic2 {
		left: 33%;
	}

	.case-studies-detl > .case-info:nth-child(1) .case-txt {
		max-width: 600px;
		width: 100%;
	}

}



@media (max-width: 1600px) {
	.banner-cap h1 {
		font-size: 55px;
	}

	.context-bg h3 {
		font-size: 50px;
		line-height: 60px;
	}

	.harness-txt p {
		font-size: 24px;
		line-height: 40px;
	}

	.reflectin-content h3 {
		font-size: 44px;
		line-height: 60px;
	}

	.refectpra p {
		font-size: 22px;
		line-height: 34px;
	}

	.howdo-sec .wstedfrntly h4 {
		font-size: 55px;
	}

	.client-sec h2 {
		font-size: 55px;
		line-height: 60px;
	}

	.howdo-sec .unlocking h3 {
		font-size: 42px;
	}

	.client-sec h3 {
		font-size: 38px;
		line-height: 58px;
	}

	.clinettxt p {
		font-size: 20px;
		line-height: 32px;
	}
	.clinettxt a {
		font-size: 20px;
	}

	.case-txt p {
		font-size: 24px;
		line-height: 45px;
	}
	.wastevalue-inner {
		max-width: 80%;
	}
	.mrirleft h4 {
		font-size: 35px;
		line-height: 55px;
	}
	.mrirright p {
		font-size: 20px;
		line-height: 35px;
	}
	
}

@media (max-width: 1399px) {
	.cntxtwopic img {
		width: 245px;
	}
	.banner-cap h1 {
		font-size: 50px;
	}

	.case-pic {
		max-width: 564px;
	}

	.case-txt {
		max-width: 546px;
	}

	.case-txt p {
		font-size: 28px;
		line-height: 47px;
	}

	.case-pic {
		max-width: 568px;
	}

	.case-txt {
		max-width: 553px;
	}

	.case-txt p {
		font-size: 26px;
		line-height: 50px;
	}

	.case-info {
		min-height: 250px;
		margin: 214px 0 0;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-txt {
		max-width: 516px;
		padding-left: 0;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-pic {
		max-width: 489px;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-txt {
		max-width: 500px;
	}

	.case-studies-detl>.case-info:nth-child(5) .case-pic {
		max-width: 489px;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-pic {
		max-width: 489px;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-pic {
		max-width: 550px;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-txt {
		max-width: 530px;
		padding-left: 0;
	}

	.case-pic::before {
		width: 250px;
		height: 250px;
	}

	.case-pic::after {
		width: 250px;
		height: 250px;
	}

	.howdo-sec .unlocking h3 {
		font-size: 40px;
	}

	.clinettxt p {
		font-size: 22px;
		line-height: 35px;
	}

	.clinettxt a {
		width: 220px;
		height: 68px;
		font-size: 22px;
	}

	.reflectin-content h3 {
		font-size: 35px;
		line-height: 49px;
	}

	.refectpra p {
		font-size: 20px;
		line-height: 30px;
	}

	.harness-txt p {
		font-size: 20px;
		line-height: 32px;
	}
	.wstlftpic img {
		max-width: 85%;
	}
	.wstlftpic2 img {
		max-width: 85%;
	}
	.newcasedsgn {
		min-height: 747px;
	}
	.newcasedsgn .case-txt {
		padding-right: 100px;
		left: -50px;
	}

}

@media (max-width: 1199px) {
	.cntxtwopic img {
		width: 200px;
	}

	.case-pic {
		max-width: 466px;
	}

	.case-txt {
		max-width: 446px;
	}

	.case-txt p {
		font-size: 23px;
		line-height: 40px;
	}

	.case-info {
		min-height: 218px;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-txt {
		max-width: 430px;
		padding-left: 0;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-pic {
		max-width: 407px;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-txt {
		max-width: 473px;
		padding-left: 0;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-txt {
		max-width: 433px;
	}

	.case-studies-detl>.case-info:nth-child(5) .case-pic {
		max-width: 425px;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-pic {
		max-width: 380px;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-txt {
		max-width: 526px;
		padding-left: 0;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-pic {
		max-width: 380px;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-txt {
		max-width: 489px;
	}

	.case-pic::before {
		width: 200px;
		height: 200px;
	}

	.case-pic::after {
		width: 200px;
		height: 200px;
	}

	.wstlftpic img {
		max-width: 50%;
	}
	.wstlftpic2 img {
		max-width: 50%;
	}
	.mrirleft h4 {
		font-size: 28px;
		line-height: 40px;
	}
	.mrirright p {
		font-size: 18px;
		line-height: 31px;
	}
	.wastevalue-inner {
		max-width: 90%;
	}



/* _____15_09_23___ */
.banner-cap a {margin-top: 25px;}
.banner-cap h1 {margin-bottom: 25px;}
.banner-cap h4 {font-size: 25px; line-height: 40px;}
.banner-cap h1 { font-size: 45px;}

.newcasedsgn {min-height: 515px !important;}
.newcasedsgn .case-picextra {max-width: 450px;}
.newcasedsgn .case-pic {left: -1% !important;}
.case-studies-detl > .case-info:nth-child(6) .case-pic {max-width: 325px;}
.case-picextra::after {width: 335px;height: 335px;right: -85px;bottom: -20px;}
.case-studies-detl > .case-info:nth-child(1) .case-txt {max-width: 460px;}



}


@media (max-width: 991px) {
	.cntxtwopic img {
		width: 165px;
		border-radius: 20px;
	}
	.cntxtwopic {
		gap:10px
	}
	.banner-cap h1 {
		font-size: 45px;
	}

	.case-info {
		display: block;
		max-width: 100%;
		min-height: auto;
		padding: 100px 0 0;
		margin-top: 190px;
	}

	.case-info::before {
		width: 100%;
	}

	.case-pic {
		position: relative;
		max-width: 100%;
		top: 0;
		transform: inherit;
		margin-top: -180px;
		text-align: center;
	}

	.case-txt {
		max-width: 100%;
		padding: 15px 40px 15px !important;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-txt {
		max-width: 100%;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-txt p {
		text-align: left;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-txt {
		max-width: 100%;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-txt {
		max-width: 100%;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-txt p {
		text-align: left;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-pic {
		max-width: 100%;
	}

	.case-studies-detl>.case-info:nth-child(7)::before {
		width: 100%;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-pic {
		max-width: 100%;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-pic::before {
		right: -9px;
		left: auto;
		top: -8px;
	}

	.case-pic::before {
		right: -22px;
	}

	.case-studies-sec {
		padding: 100px 0 100px;
	}

	.harness-txt p {
		font-size: 20px;
		line-height: 35px;
		text-align: left;
	}

	.harness-txt {
		padding: 15px 0;
	}

	.harnessing-inner .row {
		flex-direction: column-reverse;
	}

	.reflectin-sec {
		padding: 40px 0 46px;
	}
	.case-picextra {
		max-width: 600px;
		position: relative;
		right: 0;
		bottom: 0;
		padding: 15px;
	}
	.case-picextra::after {
		width: 62px;
		height: 62px;
		right: auto;
		bottom: auto;
		left: 14px;
		top: 35px;
	}
	.geoglepic {
		display: none;
	}
	.geoglepic2 {
		display: none;
	}


/* _____15_09_23___ */
.banner-cap h1 {font-size: 40px;}
.banner-cap h4 {font-size: 22px; line-height: 33px;}
.banner-cap a { margin-top: 15px; font-size: 17px;}


.case-studies-detl > .case-info:nth-child(1) .case-txt { max-width: 100%;}
.case-txt p br{display: none;}
.case-studies-detl > .case-info:nth-child(4) .case-txt {max-width: 100%;}
.case-studies-detl > .case-info:nth-child(6) .case-txt {max-width: 100%; left: auto; top: auto;padding-left: 25px !important;}
.newcasedsgn .case-picextra{padding-top: 0;}



}

@media (max-width: 768px) {
	.howdo-sec .unlocking h3 {
		font-size: 26px;
		line-height: 34px;
	}

	.harnessing{
		display: block;
	}
	.wstegvlu-sec{
		display: none;
	}
	.harnessing{
		display:none;
	}

	.mobile-context-sec{
		margin-bottom: 20px;
		display: block;
	}

	.mobile-context-sec h3 {
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 45px;
		color: #36204D;
		text-align: center;
		margin-bottom: 10px;
	}
	.contextmob{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.contextmob figure{
		width: 50%;
	}
	.mobilcntxt{
		width: 50%;
		padding-left: 15px;
	}
	.mobile-context-sec .container{
		padding: 0;
	}
	.mobilcntxt{
		padding-right: 15px;
	}
	.contextmob:nth-child(2) figure{
		order: 2;
	}
	.contextmob:nth-child(2) .mobilcntxt{
		order: 1;
		text-align: right;
	}


.cntxtwopic img {border-radius: 30px;}
.cntxtwopic {gap: 30px;}





}

@media (max-width: 767px) {


/* _____15_09_23___ */
.banrcontent {max-width: 360px;}
.banner-cap h1 {font-size: 35px; margin: 0 0 15px;}
.banner-cap h4 {font-size: 21px; line-height: 27px;}
.banner-cap a { margin-top: 8px; font-size: 14px;}

.context-sec{display: none;}

}



@media (max-width: 575px) {
	.context-sec {
		padding-top: 0;
	}

	.context-bg h3 {
		font-size: 30px;
		line-height: 45px;
		margin-bottom: 24px;
	}

	.reflectin-content h3 {
		font-size: 30px;
		line-height: 44px;
		padding: 15px;
	}

	.refectpra p {
		font-size: 16px;
		line-height: 32px;
	}

	.refectpra {
		padding: 0 0 0 28px;
		position: relative;
	}

	.refectpra::before {
		height: 100%;
	}

	.harness-txt {
		position: relative;
		padding: 0 0 0 30px;
		margin: 30px 0 50px;
	}

	.harness-txt p {
		font-size: 16px;
		line-height: 32px;
		text-align: left;
		margin-bottom: 25px;
	}

	.harness-txt::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 5px;
		height: 100%;
		content: '';
		background: #36204D;
	}

	.harnespic {
		padding: 30px 0 30px 0;
		border-top: 5px solid #36204D;
	}

	.harnespic::before {
		display: none;
	}

	.harnessing-inner {
		border-top: none;
		padding-bottom: 0;
	}

	.howdo-sec {
		margin: 0 0;
		padding: 50px 0 0;
	}

	.howdo-sec .wstedfrntly {
		margin-top: 0;
		margin-bottom: 46px;
	}

	.howdo-sec::before {
		clip-path: inherit;
		margin: 0 0 100px;
	}

	.howdo-sec .unlocking {
		position: relative;
		top: 40px;
	}

	.howdo-sec .wstedfrntly h4 {
		font-size: 35px;
		line-height: 162.7%;
	}

	.howdo-sec .study-content {
		margin: 20px 0 50px;
	}

	.case-studies-sec h2 {
		font-size: 30px;
		line-height: 45px;
	}

	.case-txt p {
		font-size: 18px;
		line-height: 37px;
	}

	.case-info::before {
		border-radius: 15px;
	}

	.case-pic::after {
		width: 109px;
		height: 109px;
		left: -7%;
		bottom: -70%;
	}

	.case-pic::before {
		right: -8px;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-pic::before {
		left: -10px;
		top: -37px;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-pic::after {
		bottom: -65%;
		right: -7%;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-pic::before {
		right: -10px;
		top: -36px;
	}

	.case-studies-detl>.case-info:nth-child(3) .case-pic::after {
		left: -7%;
		bottom: -50%;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-pic::after {
		right: -7%;
		bottom: -58%;
		left: auto;
	}

	.case-studies-detl>.case-info:nth-child(5) .case-pic::before {
		right: -10px;
		top: -18px;
	}

	.case-studies-detl>.case-info:nth-child(5) .case-pic::after {
		left: -7%;
		bottom: -50%;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-pic::before {
		top: -41px;
		left: -10px;
		right: auto;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-pic::after {
		right: -7%;
		bottom: -65%;
		left: auto;
	}


	.case-studies-detl>.case-info:nth-child(7) .case-pic::after {
		left: -7%;
		bottom: -50%;
		right: auto;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-txt p {
		text-align: left;
	}

	.case-txt::after {
		width: 60px;
		height: 60px;
		bottom: 16px;
		right: 7px;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-txt::after {
		right: auto;
		left: 10px;
		bottom: 16px;
		top: auto;
	}

	.case-studies-detl>.case-info:nth-child(6) .case-txt::after {
		bottom: 17px;
		left: 8px;
		right: auto;
		top: auto;
	}

	.case-studies-detl>.case-info:nth-child(7) .case-txt::after {
		bottom: 40%;
		right: 8px;
		left: auto;
		top: auto;
	}

	.mobilecase {
		display: block;
	}

	.mobilecase:first-child {
		margin-bottom: 180px;
	}

	.mobilecase .case-pic::after {
		bottom: -30%;
	}

	.mobilecase:nth-child(2) .case-pic {
		margin-top: -245px;
	}

	.mobilecase:nth-child(2) .case-pic::before {
		top: 80px !important;
	}

	.mobilecase:nth-child(2) .case-pic::after {
		bottom: -30% !important;
	}

	/* client */
	.listItem {
		margin: 0 0 0;
	}

	.client-sec {
		padding-top: 90px;
	}

	.listItem>.row {
		margin: 0 -50px 0;
		padding: 50px 0;
	}

	.client-sec h2 {
		font-size: 30px;
		line-height: 45px;
	}

	.client-sec h3 {
		font-size: 26px;
		line-height: 39px;
		margin-bottom: 18px;
		padding: 0 10px;
	}

	.clinettxt p {
		font-size: 16px;
		line-height: 26px;
	}

	.clinettxt span {
		max-width: 18px;
	}

	.clinettxt {
		margin-bottom: 18px;
		padding: 0 10px;
	}

	.listItem>.row:nth-child(even) .clntinr-hdr {
		text-align: left;
	}

	.listItem>.row:nth-child(even) .clinettxt {
		text-align: left;
	}

	.listItem>.row:nth-child(even) .clinettxt span {
		float: left;
	}

	.listItem>.row:nth-child(even) .clinettxt p {
		padding: 0 0 0 15px;
	}

	.listItem>.row:first-child .chompic {
		margin-bottom: 10px;
	}

	.chompic {
		margin-bottom: 50px;
	}

	.clinettxt a {
		width: 152px;
		height: 45px;
		font-size: 16px;
		margin-left: 30px;
	}

	.bcode1,
	.bcode2,
	.bcode3,
	.bcode4,
	.bcode5 {
		display: none;
	}

	.case-studies-sec {
		padding: 18px 0 100px;
	}

	.case-info {
		margin-top: 155px;
	}

	.case-studies-detl>.case-info:nth-child(4) .case-pic::before {
		left: -10px;
		top: -25px;
	}

	.case-studies-detl>.case-info:nth-child(2) .case-txt::after {
		bottom: 16px;
		right: auto;
		left: 9px;
		top: auto;
	}

	.howdo-sec .squarepic2 {
		display: block;
	}

	.clntbn .banner-cap {
		padding: 0 4px;
	}

	.clntbn .banner-cap h4 {
		font-size: 21px;
		padding: 0 0;
	}

	.cntxtwopic {
		display: block;
	}

	.dkpnon {
		display: none;
	}

	.howdo-sec .unlocking h3 {
		font-size: 22px;
	}

	.listItem>.row:first-child {
		margin: 0 -50px 0;
		padding: 0 0 50px 0;
	}

	.cntxt-bg {
		background: #fefff9;
	}

	.client-sec {
		background: #FAF8FC;
	}
	.prtnrbtn {
		padding: 14px 38px;
		font-size: 18px;
		margin-top: 70px;
	}




.howdo-sec .unlocking {top: 55px;}



}


@media (max-width: 450px) {
	.howdo-sec::before {
		clip-path: inherit;
		margin: 0 0 101px;
	}

.howdo-sec .unlocking {top: 65px;}



}







