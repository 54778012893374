.partner-box {
    max-width: 1200px;
    margin:100px auto 200px;
    background: #FEFFF9;
    border: 1px solid #D0D0D0;
    border-radius: 25px;
    padding: 50px 0;
}

.partnr-from {
    max-width: 624px;
    margin: 50px auto 150px;
}

.partnr-from h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    letter-spacing: -0.025em;
    color: #36204D;
}

.codeinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.codeinput input {
    max-width: 334px;
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 6.83333px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000;
    padding: 0 15px;
}
.codeinput input::placeholder{
    color: #64607D;
    opacity: 0.4;
}
.codeinput input:focus{
    border: 1px solid #000;
}

.codeinput button {
    max-width: 258px;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2F1C42;
    border-radius: 7px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    letter-spacing: -0.025em;
    color: #EFEFEF;
}

.partnr-from p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: -0.02em;
    color: #B4B4B4;
}

.partnr-from p a {
    color: #2F1C42;
    text-decoration: none;
    font-weight: 600;
    font-size: 19px;
}

.bcklnk {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 41px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #3A6DD1;
}


@media (max-width: 575px){
    .codeinput {
        display: block;
    }
    .codeinput input {
        max-width: 100%;
        height: 60px;
        font-size: 18px;
        margin-bottom: 12px;
    }
    .codeinput button {
        max-width: 180px;
        height: 60px;
        font-size: 20px;
    }
    .partner-box {
        margin: 50px auto 100px;
        padding: 30px 15px;
    }
    .partnr-from {
        margin: 0 auto 80px;
    }
    .partnr-from h5 {
        font-size: 25px;
    }
}