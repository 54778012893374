


.hedrbg{
    background: #f5f5f5;
}
.movement-sec{
 width: 100%;
 padding: 50px 0 50px;
 background: #f5f5f5;
}

.movement-sec .container{
    max-width: 1500px;  
}

.movement-box {
    background: #FEFFF9;
    border-radius: 27px;
    width: 100%;
    padding:50px 0 50px;
    position: relative;
}
.brdpic {
	position: absolute;
	right: 30px;
	top: -30px;
}

.movenent-inner {
    max-width: 486px;
    width: 100%;
    margin: 0 auto;
}

.frmfield {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}
.drpicn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	pointer-events: none;
    color: #76008F;
    font-size: 16px;
}

.inputfldtxt {
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 6px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -0.02em;
    color: #000;
    width: 100%;
    height: 70px;
    padding: 5px 18px;
    appearance: none;
}
.inputfldtxt::placeholder{
    color: #64607D;
    opacity: 0.4;
}
.inputfldtxt:focus{
    border: 1px solid #000;
}

.movenent-inner p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-transform: capitalize;
    color: #000000;
    opacity: 0.65;
}

.movenent-inner h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 41px;
    text-transform: capitalize;
    color: #000000;
    margin-top: 50px;
}

.movenent-inner h3 {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #36204D;
    margin-bottom: 20px;
}

.frmfield button {
    background: #36204D;
    border-radius: 22px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    color: #FFFFFF;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    cursor: pointer;
    text-transform: uppercase;
}
.frmfield button:hover{
    background: #204D36;
    color: #F1EBBB;
}



.detsp{
    margin: 36px 0;
}

.customRadio { position: relative; display: -ms-flexbox; display: inline-block; margin: 0 0 10px 0; }
.customRadio input[type="radio"] { position: absolute; overflow: hidden; clip: rect(0 0 0 0); display: none; }
.customRadio input[type="radio"] + label { position: relative; padding: 0 0 0 40px; cursor: pointer; }
.customRadio input[type="radio"] + label:before { content: ''; background: transparent; border: 2px solid #76008F; height: 25px; width: 25px; border-radius: 50%; position: absolute; top: 7px; left: 0; }
.customRadio input[type="radio"] + label:after { content: ''; background: #76008F; width: 16px; height: 16px; border-radius: 50%; position: absolute; top: 11px; left: 5px; opacity: 0; -webkit-transform: scale(2); transform: scale(2); -webkit-transition: transform 0.3s linear, opacity 0.3s linear; transition: transform 0.3s linear, opacity 0.3s linear; }
.customRadio input[type="radio"]:checked + label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }

.customRadio label{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 21px;
line-height: 33px;
color: #000;
opacity: 0.6;
}
.frmfield .customRadio{
    width: 45%;
}

.custmbcmntbg{
    background: #FBF7FC;
}
.momnfaqbg{
    background: #f5f5f5;
}
.viallbg{
    background: #76008F;
    box-shadow: 0px 7.09361px 7.09361px #E3E9FF;
    border-radius: 7.79333px;
    padding: 15px 60px;
}

.custmbcmntbg h3 {
	font-family: 'Merriweather', serif;
}
.btntpmrgn{
    margin-top: 70px;
}

@media (max-width: 1700px) {
    .movement-sec .container {
	max-width: 1320px;
}
}


@media (max-width: 575px) {
    .movenent-inner h3 {
        font-size: 24px;
        margin-bottom: 30px;
    }
    .movenent-inner h5 {
        font-size: 19px;
    }
    .movenent-inner p {
        font-size: 16px;
        line-height: 26px;
    }
    .inputfldtxt {
        font-size: 14px;
        height: 51px;
    }
    .frmfield button {
        border-radius: 16px;
        font-size: 16px;
        height: 59px;
    }
    .movement-box {
        border-radius: 0;
        padding: 30px 15px;
    }
    .movement-sec .container {
        padding: 0;
    }
    .brdpic {
        display: none;
    }
    .movement-sec {
        padding: 50px 0 0;
    }
    .movmntfq{
        display: none;
    }

}