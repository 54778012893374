




.details-sec .container {
    max-width: 1280px;
}

.detaslheding {
    width: 100%;
    padding: 50px 0 150px;
}

.detaslheding span {
    background: #36204D;
    border-radius: 6.84925px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    padding: 7px 12px;
    margin-bottom: 20px;
    display: inline-block;
}

.detaslheding h3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 57px;
    line-height: 64px;
    color: #181A2A;
    margin-bottom: 25px;
}

.detaslheding .media {
    display: flex;
    align-items: center;
    color: #fff;
}

.detaslheding .mediapic {
    width: 41px;
    height: 41px;
    flex: 0 0 41px;
    border-radius: 100%;
}

.detaslheding .media em {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #97989F;
    font-style: normal;
    padding-left: 15px;
}

.detaslheding .media i {
    font-style: normal;
    margin-left: 20px;
    font-weight: 400;
}

.detailspic {
    margin: 30px 0 20px 0;
}

.detailspic img {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
}

.details-sec p {
    font-family: 'Source Serif Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 31.825px;
    line-height: 51px;
    color: #3B3C4A;
    margin-bottom: 40px;
}

.details-sec h4 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 38.19px;
    line-height: 45px;
    color: #181A2A;
}

.quotetxt {
    background: #F6F6F7;
    border-left: 6.365px solid #E8E8EA;
    border-radius: 19.095px;
    padding: 50px;
}

.quotetxt i {
    font-family: 'Source Serif Pro', serif;
    font-style: italic;
    font-weight: 400;
    font-size: 38.19px;
    line-height: 51px;
    color: #181A2A;
}
.detailhedr{
    position: relative !important;
    background: #fff;
}

.detailhedr .navigation .sf-menu > li a{
    color: #204D36;
}
.detailhedr.sticky .navigation .sf-menu > li a{
    color: #fff !important;
}
.policy-text h2{
    font-size: 40px;
    font-weight: 600;
}
.policy-text h3{
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 15px;
}

.policy-text p{
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 15px;
}

@media (max-width: 1600px){
    .details-sec p {
        font-size: 26px;
        line-height: 40px;
    }
    .details-sec h4 {
        font-size: 32px;
    }
    .quotetxt i {
        font-size: 32px;
        line-height: 48px;
    }
    .policy-text p{
        font-size: 18px;
        line-height: 22px;
    }

}

@media (max-width: 991px){
    .detailhedr .navigation .NavBar{
        color: #000;
    }
    .detailhedr .navigation .sf-menu > li a {
        color: #fff;
    }
}
@media (max-width: 575px){
.detaslheding h3 {
	font-size: 27px;
	line-height: 35px;
	margin-bottom: 20px;
}
.detaslheding .media em {
	font-size: 15px;
	line-height: 20px;
}
.details-sec p {
	font-size: 18px;
	line-height: 26px;
}
.details-sec h4 {
	font-size: 25px;
	line-height: 30px;
}
.quotetxt i {
	font-size: 25px;
	line-height: 32px;
}
.quotetxt {
	padding: 25px;
}

}