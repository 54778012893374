/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Proza+Libre:wght@400;500;600;700;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;1,500;1,600&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400;500;600;700;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Nova+Slim&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,900&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,900&display=swap'); */

@font-face {
  font-family: 'Bakbak One';
  src: url('../fonts/BakbakOne-Regular.woff2') format('woff2'),
      url('../fonts/BakbakOne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Italic.woff2') format('woff2'),
      url('../fonts/WorkSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Regular.woff2') format('woff2'),
      url('../fonts/WorkSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Italic.woff2') format('woff2'),
      url('../fonts/WorkSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-BoldItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-BlackItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-MediumItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Bold.woff2') format('woff2'),
      url('../fonts/WorkSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Black.woff2') format('woff2'),
      url('../fonts/WorkSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Medium.woff2') format('woff2'),
      url('../fonts/WorkSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-ThinItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Regular.woff2') format('woff2'),
      url('../fonts/WorkSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-LightItalic.woff2') format('woff2'),
      url('../fonts/WorkSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-ExtraBold.woff2') format('woff2'),
      url('../fonts/WorkSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-SemiBold.woff2') format('woff2'),
      url('../fonts/WorkSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Light.woff2') format('woff2'),
      url('../fonts/WorkSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-ExtraLight.woff2') format('woff2'),
      url('../fonts/WorkSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Thin.woff2') format('woff2'),
      url('../fonts/WorkSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}




@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
      url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraLight.woff2') format('woff2'),
      url('../fonts/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Black.woff2') format('woff2'),
      url('../fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
      url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
      url('../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
      url('../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.woff2') format('woff2'),
      url('../fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
      url('../fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
      url('../fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Thin.woff2') format('woff2'),
      url('../fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Nova Slim';
  src: url('../fonts/NovaSlim.woff2') format('woff2'),
      url('../fonts/NovaSlim.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Regular.woff2') format('woff2'),
      url('../fonts/PTSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Italic.woff2') format('woff2'),
      url('../fonts/PTSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-BoldItalic.woff2') format('woff2'),
      url('../fonts/PTSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Bold.woff2') format('woff2'),
      url('../fonts/PTSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BlackItalic.woff2') format('woff2'),
      url('../fonts/Poppins-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Black.woff2') format('woff2'),
      url('../fonts/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('../fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
      url('../fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBold.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BoldItalic.woff2') format('woff2'),
      url('../fonts/Poppins-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Italic.woff2') format('woff2'),
      url('../fonts/Poppins-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
      url('../fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-MediumItalic.woff2') format('woff2'),
      url('../fonts/Poppins-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
      url('../fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.woff2') format('woff2'),
      url('../fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-LightItalic.woff2') format('woff2'),
      url('../fonts/Poppins-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLight.woff2') format('woff2'),
      url('../fonts/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Thin.woff2') format('woff2'),
      url('../fonts/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ThinItalic.woff2') format('woff2'),
      url('../fonts/Poppins-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-BlackItalic.woff2') format('woff2'),
      url('../fonts/Merriweather-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Black.woff2') format('woff2'),
      url('../fonts/Merriweather-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-BoldItalic.woff2') format('woff2'),
      url('../fonts/Merriweather-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Light.woff2') format('woff2'),
      url('../fonts/Merriweather-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-LightItalic.woff2') format('woff2'),
      url('../fonts/Merriweather-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Regular.woff2') format('woff2'),
      url('../fonts/Merriweather-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Bold.woff2') format('woff2'),
      url('../fonts/Merriweather-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Italic.woff2') format('woff2'),
      url('../fonts/Merriweather-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}



@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff2') format('woff2'),
      url('../fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BlackItalic.woff2') format('woff2'),
      url('../fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Black.woff2') format('woff2'),
      url('../fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
      url('../fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.woff2') format('woff2'),
      url('../fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-Hairline.woff2') format('woff2'),
      url('../fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff2') format('woff2'),
      url('../fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-LightItalic.woff2') format('woff2'),
      url('../fonts/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Italic.woff2') format('woff2'),
      url('../fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-HairlineItalic.woff2') format('woff2'),
      url('../fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/ProzaLibre-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-BoldItalic.woff2') format('woff2'),
      url('../fonts/ProzaLibre-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-ExtraBold.woff2') format('woff2'),
      url('../fonts/ProzaLibre-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-Bold.woff2') format('woff2'),
      url('../fonts/ProzaLibre-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-Italic.woff2') format('woff2'),
      url('../fonts/ProzaLibre-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-SemiBold.woff2') format('woff2'),
      url('../fonts/ProzaLibre-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-MediumItalic.woff2') format('woff2'),
      url('../fonts/ProzaLibre-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-Medium.woff2') format('woff2'),
      url('../fonts/ProzaLibre-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/ProzaLibre-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../fonts/ProzaLibre-Regular.woff2') format('woff2'),
      url('../fonts/ProzaLibre-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-ExtraLight.woff2') format('woff2'),
      url('../fonts/Manrope-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-SemiBold.woff2') format('woff2'),
      url('../fonts/Manrope-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Bold.woff2') format('woff2'),
      url('../fonts/Manrope-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-ExtraBold.woff2') format('woff2'),
      url('../fonts/Manrope-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Regular.woff2') format('woff2'),
      url('../fonts/Manrope-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Medium.woff2') format('woff2'),
      url('../fonts/Manrope-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-Light.woff2') format('woff2'),
      url('../fonts/Manrope-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-ExtraLight.woff2') format('woff2'),
      url('../fonts/Manrope-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Thin.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Medium.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Bold.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-ExtraBold.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Regular.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-ExtraLight.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-SemiBold.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Black.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Thin.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'League Spartan';
  src: url('../fonts/LeagueSpartan-Light.woff2') format('woff2'),
      url('../fonts/LeagueSpartan-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}




@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-ExtraLight.woff2') format('woff2'),
      url('../fonts/Mulish-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/Mulish-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Mulish-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-BlackItalic.woff2') format('woff2'),
      url('../fonts/Mulish-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-BoldItalic.woff2') format('woff2'),
      url('../fonts/Mulish-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Mulish-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-MediumItalic.woff2') format('woff2'),
      url('../fonts/Mulish-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-LightItalic.woff2') format('woff2'),
      url('../fonts/Mulish-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Italic.woff2') format('woff2'),
      url('../fonts/Mulish-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Black.woff2') format('woff2'),
      url('../fonts/Mulish-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-ExtraLightItalic.woff2') format('woff2'),
      url('../fonts/Mulish-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Regular.woff2') format('woff2'),
      url('../fonts/Mulish-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Bold.woff2') format('woff2'),
      url('../fonts/Mulish-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Light.woff2') format('woff2'),
      url('../fonts/Mulish-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Medium.woff2') format('woff2'),
      url('../fonts/Mulish-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-ExtraBold.woff2') format('woff2'),
      url('../fonts/../fonts/Mulish-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-SemiBold.woff2') format('woff2'),
      url('../fonts/Mulish-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-ExtraLight.woff2') format('woff2'),
      url('../fonts/Mulish-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


/* @font-face {
  font-family: 'clash_displaybold';
  src: url('../fonts/clashdisplay-bold-webfont.woff2') format('woff2'),
       url('../fonts/clashdisplay-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} */

@font-face {
  font-family: 'aperculight';
  src: url('../fonts/apercu_light-webfont.woff2') format('woff2'),
       url('../fonts/apercu_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercumedium';
  src: url('../fonts/apercu_medium-webfont.woff2') format('woff2'),
       url('../fonts/apercu_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercu_monoregular';
  src: url('../fonts/apercu_mono-webfont.woff2') format('woff2'),
       url('../fonts/apercu_mono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercu_probold';
  src: url('../fonts/apercu_pro_bold-webfont.woff2') format('woff2'),
       url('../fonts/apercu_pro_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercu_prolight';
  src: url('../fonts/apercu_pro_light-webfont.woff2') format('woff2'),
       url('../fonts/apercu_pro_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercu_promedium';
  src: url('../fonts/apercu_pro_medium-webfont.woff2') format('woff2'),
       url('../fonts/apercu_pro_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercu_promono';
  src: url('../fonts/apercu_pro_mono-webfont.woff2') format('woff2'),
       url('../fonts/apercu_pro_mono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'clash_displaylight';
  src: url('../fonts/clashdisplay-light-webfont.woff2') format('woff2'),
       url('../fonts/clashdisplay-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'clash_displaymedium';
  src: url('../fonts/clashdisplay-medium-webfont.woff2') format('woff2'),
       url('../fonts/clashdisplay-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'clash_displayregular';
  src: url('../fonts/clashdisplay-regular-webfont.woff2') format('woff2'),
       url('../fonts/clashdisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'clash_displaysemibold';
  src: url('../fonts/clashdisplay-semibold-webfont.woff2') format('woff2'),
       url('../fonts/clashdisplay-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


body{font-family: 'Poppins', sans-serif; font-size: 14px; background: #FEFFF9;}


/* html, body{overflow-x: hidden;} */
img{max-width: 100%;}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/svg/icomoon.eot?7vfwjk');
  src:  url('../fonts/svg/icomoon.eot?7vfwjk#iefix') format('embedded-opentype'),
    url('../fonts/svg/icomoon.ttf?7vfwjk') format('truetype'),
    url('../fonts/svg/icomoon.woff?7vfwjk') format('woff'),
    url('../fonts/svg/icomoon.svg?7vfwjk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.icon{font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}
/* 21112022 */
.icon-desktop-gallery:before {
  content: "\e92d";
}
.icon-women-hair:before {
  content: "\e92e";
}
.icon-trash-o:before {
  content: "\e92f";
}
.icon-image-transparent:before {
  content: "\e930";
}
.icon-image-plus:before {
  content: "\e931";
}
.icon-image-border:before {
  content: "\e932";
}
.icon-laptop-gallery:before {
  content: "\e933";
}
.icon-image-edit:before {
  content: "\e934";
}
/* 21112022 end */
.icon-mobile-lock:before {
  content: "\e926";
}
.icon-fire-paper-plane:before {
  content: "\e927";
}
.icon-long-arrow-right:before {
  content: "\e91e";
}
.icon-check-s:before {
  content: "\e925";
}
.icon-envelope-o:before {
  content: "\e919";
}
.icon-user-c:before {
  content: "\e91a";
}
.icon-check-circle:before {
  content: "\e91b";
}
.icon-place:before {
  content: "\e900";
}
.icon-comment:before {
  content: "\e901";
}
.icon-pencil-square:before {
  content: "\e902";
}
.icon-subject:before {
  content: "\e903";
}
.icon-quote:before {
  content: "\e904";
}
.icon-clock:before {
  content: "\e905";
}
.icon-guaranteed:before {
  content: "\e906";
}
.icon-setting:before {
  content: "\e907";
}
.icon-shield:before {
  content: "\e908";
}
.icon-output:before {
  content: "\e909";
}
.icon-notification:before {
  content: "\e90a";
}
.icon-server:before {
  content: "\e90b";
}
.icon-share:before {
  content: "\e90c";
}
.icon-upload:before {
  content: "\e90d";
}
.icon-paper-plane:before {
  content: "\e90e";
}
.icon-angle-right:before {
  content: "\e90f";
}
.icon-angle-left:before {
  content: "\e910";
}
.icon-video-camera:before {
  content: "\e911";
}
.icon-camera:before {
  content: "\e912";
}
.icon-arrow-right:before {
  content: "\e913";
}
.icon-play:before {
  content: "\e914";
}
.icon-check-m:before {
  content: "\e915";
}
.icon-user:before {
  content: "\e916";
}
.icon-envelope:before {
  content: "\e917";
}
.icon-phone:before {
  content: "\e918";
}
.icon-other:before {
  content: "\e924";
}
.icon-plus:before {
  content: "\e938";
}
.icon-minus:before {
  content: "\e939";
}
.icon-arrow-left-p:before {
  content: "\e91d";
}
.icon-arrow-right-p:before {
  content: "\e91c";
}
.icon-menu1:before {
  content: "\e923";
}
.icon-segment:before {
  content: "\e922";
}
.icon-align-left:before {
  content: "\e920";
}
.icon-align-right:before {
  content: "\e91f";
}
.icon-clearclose:before {
  content: "\e928";
}
.icon-done:before {
  content: "\e929";
}
.icon-done_all:before {
  content: "\e92a";
}
.icon-check:before {
  content: "\e921";
}
.icon-chevron-thin-left1:before {
  content: "\e92b";
}
.icon-chevron-thin-right1:before {
  content: "\e92c";
}

@-webkit-keyframes stickySlideDown {
from {
transform:translateY(-100%);
}
to {
transform:translateY(0);
}
}
@keyframes stickySlideDown {
from {
transform:translateY(-100%);
}
to {
transform:translateY(0);
}
}
:focus{outline: none !important;}
button:focus{outline: none !important}

.headercontainer{padding: 30px 0px; background: none; position: absolute; z-index: 9; width: 100%;}
.headercontainer .logoouter{-ms-flex: 0 0 120px; flex: 0 0 120px; max-width: 120px; padding: 0px 15px;}
.headercontainer .logoouter .logo{float: left; width: 100%;}
.headercontainer .logoouter .logo img{max-width: 100%; max-height: 100%;}
.headercontainer .topmailbx{float: left; width: 100%; padding-bottom: 20px; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-pack: end; justify-content: flex-end; -ms-flex-align: center; align-items: center;}
.headercontainer .topmailbx a{color: #1E1E1E; font-size: 15px; font-weight: 300; letter-spacing: 1px; text-decoration: none;}
.headercontainer .topmailbx a + a{margin-left: 35px;}
.headercontainer .topmailbx a i{display: inline-block; vertical-align: middle; margin-right: 5px;}
.headercontainer .topmailbx a span{display: inline-block; vertical-align: middle;}
.headercontainer .navigation{-ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%; padding: 0px 15px;}
.headercontainer .navigation .navigationbox{float: left;}
.headercontainer .navigation .sf-menu-outer{float: left; width: 100%; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-pack: end; justify-content: flex-end; -ms-flex-align: center; align-items: center;}
.headercontainer .navigationouter{float: left; width: 100%; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-pack: end; justify-content: flex-end; -ms-flex-align: center; align-items: center; padding-bottom: 15px; position: relative;}

.headercontainer .navigation .sf-menu{float: left; margin: 0px; padding: 0px;}
.headercontainer .navigation .sf-menu > li{float: none; display: inline-block; margin: 0px 15px;}
.headercontainer .navigation .sf-menu > li a{position: relative; font-family: 'Poppins';font-style: normal; color: #fff; display: block; font-size: 18px; text-decoration: none; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}


.headercontainer .navigation .sf-menu > li.active a{color: #F1EBBB;}
.headercontainer .navigation .sf-menu > li.active a:after{opacity: 1;}
.headercontainer .navigation .sf-menu > li a:hover{color: #F1EBBB;}
.headercontainer .navigation .sf-menu > li a:hover:after{opacity: 1;}
.headercontainer .loginbtx{float: left; margin-left: 230px; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -ms-flex-align: center; align-items: center;}
.headercontainer .loginbtx > i{width: 36px; height: 36px; line-height: 36px; margin-right: 10px; text-align: center; color: #000; border: 1px solid #fff; -webkit-border-radius: 50%; border-radius: 50%;}
.headercontainer .loginbtx a{font-family: 'Poppins';font-style: normal; color: #000; font-size: 18px; text-transform: capitalize; text-decoration: none; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; background: #36204D;padding: 14px 30px;border-radius: 6px;color: #fff;font-weight: 500;}
.headercontainer .loginbtx a:hover{color: #000; background:#F1EBBB ;}
.headercontainer .loginbtx a i{color: #fff; margin-right: 6px;}
.headercontainer .loginbtx a em{font-style: normal;}
.headercontainer .loginbtx span{color: #fff; padding: 0px 6px;}




.theme-btn{outline: none; border: none; display: inline-block; background: #3FE2C0; position: relative; padding: 12px 20px; font-size: 15px; color: #0D3C32; text-decoration: none; -webkit-clip-path: polygon(100% 0, 100% 57%, 90% 100%, 0 100%, 0 0); clip-path: polygon(100% 0, 100% 57%, 90% 100%, 0 100%, 0 0); -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.theme-btn span{padding-right: 8px;}
.theme-btn i{font-size: 78%;}
.theme-btn:hover{background: #0C3A31; color: #fff;}

.default-btn{outline: none; border: none; display: inline-block; background: #0C3A31; letter-spacing: 2px; position: relative; padding: 10px 20px; font-size: 15px; color: #fff; text-decoration: none; -webkit-clip-path: polygon(100% 0, 100% 57%, 90% 100%, 0 100%, 0 0); clip-path: polygon(100% 0, 100% 57%, 90% 100%, 0 100%, 0 0); -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out;}
.default-btn span{padding-right: 8px;}
.default-btn i{font-size: 78%;}
.default-btn:hover{background: #3FE2C0; color: #fff;}

.bannercontainer{padding: 0 0px 40px 0px; position: relative;}

.bannercontainer .owl-carousel .owl-item img{
  height: auto;
}

.banner-cap {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.banrcontent{
  /* max-width: 1062px; */
  margin: 0 auto;
  text-align: center;
}
.mbnr{display: none !important;}
.banner-cap h1{
  font-family: 'Poppins';font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 121%;
  text-align: center;
  text-transform: capitalize;
  color: #FEFFF9;
}
.banner-cap h4{
  font-family: 'Poppins';font-style: normal;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #F1EBBB;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 485px;
}
.banner-cap a{
  background: #36204D;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  padding: 10px 30px;
  border-radius: 12px;
  display: inline-block;
  font-size: 25px;
}
.banner-cap a:hover{
  box-shadow: rgba(216, 216, 216, 0.5) 0px 3px 20px 4px;
}

.impact-sec{margin: 50px 0;}
.impact-sec .container{max-width: 1404px;}
.impact-info{padding: 20px 0; max-width: 370px; margin: auto;width: 100%;}
.mbicn{display: none;}
.impact-info h4{font-family: 'Poppins';font-style: normal;font-weight: 700;font-size: 55px;line-height: 142.2%;text-transform: capitalize;color: #204D36;}
.impact-info p{font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 22px;color: #121212; max-width: 277px;text-transform: capitalize;}
.impact-sec .col-lg-4{border-right: 4px dashed #DFDFDF; border-bottom: 4px dashed #DFDFDF;position: relative;}

.impact-sec .col-lg-4:first-child .impact-info{margin: inherit;}
.impact-sec .col-lg-4:nth-child(3){border-right: none;}
.impact-sec .col-lg-4:nth-child(4){border-bottom: none; padding-top: 50px;}
.impact-sec .col-lg-4:nth-child(4) .impact-info{margin: inherit;}
.impact-sec .col-lg-4:nth-child(5){border-bottom: none; padding-top: 50px;}
.impact-sec .col-lg-4:nth-child(6){border-bottom: none; border-right: none; padding-top: 50px;}
.impact-info span img{max-width: 50px;}
.impact-sec .col-lg-4:nth-child(4) .impact-info h4{color: #36204D;}
.impact-sec .col-lg-4:nth-child(5) .impact-info h4{color: #36204D;}
.impact-info h4 sub{bottom: 4px;font-size: 20px;}
.impact-sec .col-lg-4:first-child .impact-info h4 {font-size: 60px;}

.missionsec {
	padding: 50px 0 50px;
	position: relative;
	background: #36204D;
  margin-top: 200px;
}
.missionsec .squarepic img{filter: brightness(0) invert(1); width: auto; height: auto;}
.squarepic {
	position: absolute;
	left: 28%;
	bottom:330px;
}
.squarepic img{max-width: 70%;}
.mission-info{position: relative;}
.mission-info figure{height: 430px}
.mission-info figure img {height: 100%; object-fit: cover;width: 100%;}
.mission-info figcaption {position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; padding: 50px 30px;}
.mission-info figcaption::before {content:''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(352.42deg, rgba(0, 0, 0, 0.85) 3.35%, rgba(54, 32, 77, 0) 200.52%);
 z-index: -1;}
.mission-info figcaption h4{font-family: 'Poppins', sans-serif;font-weight: 700;font-size:45px;text-decoration-line: underline;color: #FFFFFF;}
.mission-info figcaption p{font-family: 'Poppins', sans-serif;font-weight: 600;font-size: 30px;color: #F8F8F8; text-transform: capitalize;}


.ocean-sec{margin: 50px 0;}
.removing-content{padding: 0 50px; margin-top: 150px;}
.removing-content h3{font-family: 'Poppins', sans-serif;font-style: normal;font-weight: 700;font-size: 46px;text-transform: capitalize;color: #fff;max-width: 715px;}
.removing-content p{font-family: 'Poppins', sans-serif; font-style: normal;font-weight: 400;font-size: 22px;color: #fff;max-width: 438px; margin: 0 auto;}
.without-bntx{padding: 60px 0;}

.ourvsnmsn {
	position: relative;
	margin-top: -150px;
}
.wstecolect{padding-top: 42px;}
.wthouthed{text-align: center; margin-bottom: 30px;}
.wthouthed h5{position: relative; font-family: 'Poppins', sans-serif;font-weight: 700;font-size: 30px;color: #132742;}
.wthouthed h5::after {
	content: '';
	position: absolute;
	max-width: 95px;
	height: 1px;
	background: #000000;
	left: 0;
	right: 0;
	margin: auto;
	bottom: -2px;
}
.huse-wste{text-align: center;margin-bottom: 60px;}
.huse-wste img{max-height: 150px;margin-bottom: 10px; max-width: 100%; width: auto; height: auto;}
.huse-wste p{
  font-weight: 500;
}
.fstbdr{position: relative; margin-bottom: 130px;}
.fstbdr::after {
	content: '';
	height: 370px;
	border-left: 3px dashed rgba(32, 77, 54, 0.35);
	position: absolute;
	bottom: -370px;
	left: 50%;
}
.midlbdr{position: relative; }
.midlbdr::after {content: '';width: 182px;border-top: 3px dashed rgba(32, 77, 54, 0.35);position: absolute;right: -97px;top: 50%;}

.huse-wste p{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #1D1D1D;
}
.withbntix-sec {
	position: relative;
	background: #FFFFFF;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
	border-radius: 25px;
	padding: 40px 50px 58px;
	max-width: 741px;
}
.collect-bntx{text-align: center; margin-bottom: 90px; position: relative;}
.collect-bntx img{max-height: 150px;margin-bottom: 10px; max-width: 100%; width: auto; height: auto;}
.collect-bntx p{margin: 0; font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 26px; color: #1D1D1D;}
.collect-bntx::after{content: ''; height: 80px; border-left:3px dashed rgba(32, 77, 54, 0.35); position: absolute; bottom: -82px; left: 50%;}
.withbntix-sec .squarepic {
	position: absolute;
	right: -50px;
	top: -130px;
	left: inherit;
	text-align: right;
	z-index: -1;
}
.withbntix-sec .squarepic img{
  max-width: 100%;
  opacity:0.5;
}
.withbntix-sec .squarepic2 {
	position: absolute;
	bottom: 27px;
	left: -106px;
	text-align: left;
	z-index: -1;
}
.withbntix-sec .squarepic2 img {
	max-width: 100%;
  opacity: 0.6;
}

.recycleinfo{text-align: center;margin-top: 10px;}
.recycleinfo img{max-height: 100px; max-width: 100%; width: auto; height: auto;}
.recycleinfo p{margin: 0; font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 23px; color: #1D1D1D;line-height: 34px;}
.recycleinfo a{margin: 0; font-family: 'Poppins', sans-serif;display: block;text-decoration-line: underline;color: #204D36;font-weight: 500; min-height: 24px; font-size: 23px;}
.recycleinfo span{margin: 0; font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 16px; color: #204D36; opacity: 0.75;line-height: 20px;}

.rckyl{position: relative;}
.rckyl::after{content: ''; height: 30px; border-left:3px dashed rgba(32, 77, 54, 0.35); position: absolute; top: -34px; left: 50%;}

.lstbdr{position: relative;}
.lstbdr::after{content: ''; height: 30px; border-left:3px dashed rgba(32, 77, 54, 0.35); position: absolute; top: -34px; left: 50%;}


.midlbbdr{position: relative;}
.midlbbdr::after {content: '';width: 227%;border-top: 3px dashed rgba(32, 77, 54, 0.35);position: absolute;top: -19%;left: -63%;}

.bloginfo{max-width: 90%; margin: 0 auto;}
.blog-sec{margin: 50px 0;}
.blog-sec .container{max-width: 1500px;}
.bloghedng{max-width: 645px; margin: 0 auto; margin-bottom: 50px;}
.bloghedng h2{font-family: 'Poppins', sans-serif; font-weight: 800;font-size: 45px;text-align: center;text-transform: capitalize;color: #204D36;}

.bloginfo .row > div{
  padding: 0 25px;
}

.blogbox{
  background: #FFFFFF;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 2px;
  margin-top: -50px;
  position: relative;
  min-height: 580px;
  padding-bottom: 8px;
  margin-bottom: 10px;
}
.blogbox .bpic{width: 100%;border-radius: 12px;overflow: hidden;}
.blog-txt{padding: 48px 30px 18px;min-height: 245px;}
.blog-txt span{ display: block;}
.blog-txt span img{ width: auto !important;height: auto;max-width: 100%;}
.blog-txt h4{margin: 30px 0;color: #121212;text-transform: capitalize;font-size: 24px;max-width: 260px;}
.blogpic{display: flex;}
.bpic img {
	max-width: 100%;
	max-height: 275px;
	width: 100%;
	object-fit: cover;
	height: 100%;
}
.blgnmbr{text-align: left;}
.blgnmbr span{color: #f5f5f5;text-align: center; font-family: 'Lato';font-weight: 900;font-size: 170px;opacity: 0.25; 
-webkit-text-stroke: 3px #204D36; line-height: 150px;}


.watch-jurny{margin: 70px 0 0;position: relative;}
.watch-jurny .container{position: relative;}
.wtchbg{position: absolute; left: 0; top: 10%; z-index: -1;opacity: 0.6;}
.wtchbg img{max-width: 100%; height: auto;}
.watch-content {position: relative; max-width:1000px; margin: 0 auto; padding: 40px 50px; border-top: 3px dashed rgba(32, 77, 54, 0.35); border-bottom: 3px dashed rgba(32, 77, 54, 0.35);}
.video-box{padding: 15px; position: relative;}
.video-box video{height: 205px;}
.video-box::before{content: ''; border-right: 2px solid #204D36; border-top: 2px solid #204D36; position: absolute; right: 0; top: 0;height: 84px; width: 148px;}
.video-box::after{content: ''; border-left: 2px solid #204D36; border-bottom: 2px solid #204D36; position: absolute; left: 0; bottom: 0; height: 84px; width: 148px;}
.watch-content h5{color: #262626;font-family: 'Poppins', sans-serif; font-size: 22px;font-weight: 500;text-transform: capitalize;}
.recycled-vdo{position: relative;}
.playbtn{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);cursor: pointer;z-index: 2;}
.waste-challenge{margin:0 0 50px 0; position: relative; min-height: 500px;background: #fff;padding-top: 100px;}
.waste-challenge::before{content: '';  clip-path: polygon(100% 0, 100% 92%, 44% 91%, 0 99%, 0 0);
  position: absolute;
  bottom: -14px;
  top: 310px;
  width: 100%;
  background: linear-gradient(179.98deg, #36204D 16.55%, #000 141.97%);
  z-index: 1;
}
.waste-challenge .container{position: relative;z-index: 2;}
.todyhedng{font-family: 'Poppins', sans-serif; color: #1D4732; font-size: 45px; text-align: center;font-weight: 700;margin-bottom: 30px;text-transform: capitalize;}
.garbej-sec{position: relative; background: #fff; box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1); border-radius: 15px; padding: 15px;}
.garbjpic img{max-width: 100%;}
.garbj-info {
	max-width: 632px;
	width: 100%;
  text-align: center;
  margin: 48px auto;
}
.garbj-info h6{font-family: 'Poppins', sans-serif;font-size: 25px; color: #000;    font-weight: 700;}
.garbj-info p{font-family: 'Poppins', sans-serif;font-size: 24px; color: #454545;font-weight: 400;}
.garbj-info p span{font-size: 45px;font-weight: 700;color: #3A3A3A; display: block;}
.garbj-info p img{max-width: 42px;}
.wstedfrntly{text-align: center; margin: 80px 0;}
.studypic{position: relative;margin-bottom: 60px;}
.garbj-info em{font-family: 'Poppins', sans-serif;font-style:normal;font-size: 45px;color: #717171;font-weight: 700;position: relative;
  top: 8px;}
.studypic img{max-width: 100%; width: auto !important;height: auto !important;}
.garbej-sec .squarepic2 {
	position: absolute;
	bottom: -119px;
	right: -20px;
	text-align: right;
	z-index: -1;
}
.garbej-sec .squarepic2 img{
  max-width: 70%;
  width: auto;
  height: auto;
}
.studypic .squarepic2 {
	position: absolute;
	bottom: -141px;
	right: 80px;
	text-align: right;
	z-index: -1;
}
.studypic .squarepic2 img{
  max-width: 71%;
}

.associate-sec {
	margin: 80px auto 0;
	max-width: 1533px;
}
.associate-sec .row{margin: 0 -15.5px;}
.associate-sec .row > div{padding: 0 14px;}
.resident-sec{background: #fff; position: relative; border-radius: 5px; min-height: 475px; margin: 54px 0 0; box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.1); padding: 55px 70px 20px 55px;}
.tagpic{position: absolute; right: 0; top: 0; text-align: right;}
.tagpic img{max-width: 70%; max-height: 100%; width: auto; height: auto;}
.resident-sec a {
	color: #fff;
	background: #2F1C42;
	border-radius: 7px;
	padding: 6px 20px;
	letter-spacing: -0.025em;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	text-decoration: none;
	display: inline-block;
	margin: 18px 0 0;
	line-height: 50px;
	padding: 0 41px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  bottom: 50px;
  left: 55px;
}
.resident-sec a:hover{color: #fff; background: #204D36;}
.resident-sec h4{line-height: 50px;color: #171717;font-family: 'Poppins', sans-serif;font-size: 35px;line-height: 40px;font-weight: 700;min-height: 100px;
  margin: 0 0 9px;}
.resident-sec p{color: #171717;font-family: 'Poppins', sans-serif;font-size: 20px;font-weight: 400;min-height: auto;line-height: 34px;}

.wstedfrntly {text-align: center;margin: 180px auto;max-width: 660px;}
.wstedfrntly h3{color: #F1EBBB;     font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 30px;text-transform: capitalize;}
.wstedfrntly h4{color: #fff; font-family: 'Proza Libre', sans-serif; font-weight: 600; font-size: 35px; margin-bottom: 20px;}
.study-content{padding-left: 60px; position:relative; }
.study-content h3{color: #fff; font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 35px;max-width: 495px;}
.study-content img{position: relative;bottom: 0;margin-top: -80px;z-index: -1; width: auto; height: auto;}

.unlocking{max-width: 1170px; margin: 0 auto;}
.unlocking h3{color: #fff; font-family: 'Poppins', sans-serif; font-size: 35px; text-align: center; font-weight: 700; text-transform: capitalize;line-height: 67px;}
.ustopmrgn{margin-top: 120px;}
.wste-store{margin: 150px 0 50px; position: relative;z-index: -1;}
.wste-store img{max-width: 99%; position: absolute; left: 0; top: 0;z-index: -1;}
.wste-store > img{width: 100%; max-width: 92%;}
.wststorinfo{display: flex; justify-content: space-between; align-items: center; padding: 50px 0 0 50px;}
.recycletxt{background: rgba(255, 255, 255, 0.85); border-radius: 15px; padding:50px 20px;}
.recycletxt h3{font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 35px; color: #341F4B;}
.recycletxt p{font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 20px; color: #341F4B;}
.recyclepic{max-width: 615px;}
.recyclepic .col-6:nth-child(odd) .recypic{text-align: right;}
.recyclepic .col-6:first-child .recypic{margin-top: 10px;}
.recyclepic .col-6:nth-child(3) .recypic{padding-top: 15px;}
.recypic{margin-bottom: 15px;}
.recypic img{max-width: 100%; position: relative; border: 3px solid #FEFFF9; border-radius: 10px; filter: drop-shadow(10.6428px 10.6428px 26.6069px rgba(0, 0, 0, 0.25)); width: auto !important; height: auto !important;}
.recycle-sec{margin: 0 0 0;}
.recycle-sec .container{position: relative;}
.recycle-sec .squarepic {
	position: relative;
	left: 15px;
	bottom: -27px;
	z-index: -2;
	max-width: 300px;
}
.recycle-sec .squarepic img {
	max-width: 53%;
  width: auto;
  height: auto;
}
.prtnr-heading{max-width: 600px; margin:0 auto 70px; text-align: center;}
.prtnr-heading h3{font-weight: 700;font-size: 40px; color: #262626; font-family: 'Poppins', sans-serif;}
.prtnr-heading p{font-weight: 600; font-size: 20px; color: #36204D; font-family: 'Poppins', sans-serif; border-bottom: 1.8px dashed #1F4B35;}

.partner-sec{margin:0 0 50px 0;}
.prtnviw{text-align: center; position: relative;}


.prtnrlgo {
  width: 200px;
  height: 200px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 7px 20px rgba(31, 75, 52, 0.1);
  display: inline-block;
  position: relative;
  margin-top: -40px;
}

@media (max-width: 768px) {
  .prtnrlgo {
    margin: 10px auto;
    width: 150px; 
    height: 150px;
  }
}

.prtnrlgo img{position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; max-width: 60%;}
.prtnviw .squarepic2 {
	position: absolute;
	bottom: -40px;
	right: -6px;
	text-align: left;
	z-index: -1;
}
.prtnviw .squarepic2 img{
  max-width: 52%;
  width: auto;
  height: auto;
}
.lstlgo{position: relative;z-index: -1;}

.impact-details {
	margin: 150px 0;
	min-height: 500px;
	width: 100%;
	background-size: 100% 83%;
	background-repeat: no-repeat;
  padding-top: 30px;
}
.extrmrgn{margin-top: 150px;}
.impct-hed{max-width: 500px; margin:30px auto 100px; text-align: center;}
.impct-hed h3{font-family: 'Proza Libre', sans-serif; font-size: 55px; color: #fff; font-weight: 700;}
.impct-hed p{font-family: 'Poppins', sans-serif;font-size: 16px; color: #fff; font-weight: 400;text-transform: capitalize;}

.impectN{
  max-width: 1335px;
  margin: 0 auto;
}
.impct-catg{
  background: linear-gradient(196.95deg, #000000 -38.05%, #258E58 4.09%);
  box-shadow: 0px 3.01139px 18.0684px -0.752848px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
  border-radius: 75px;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 8px;
  min-width: 55%;
}
.impct-catg span {
	background: linear-gradient(196.95deg, rgba(255, 255, 255, 0.4) -38.05%, rgba(255, 255, 255, 0.1) 4.09%);
	box-shadow: 0px 3.01139px 18.0684px -0.752848px rgba(0, 0, 0, 0.25);
	-webkit-backdrop-filter: blur(16.9391px);
	backdrop-filter: blur(16.9391px);
	border-radius: 7.52848px;
	display: flex;
  align-items: center;
  justify-content: center;
	padding: 12px;
	position: absolute;
	top: -50%;
  left: 29px;
  width: 60px;
  height: 60px;
}
  .impct-catg span img{max-width: 33px; max-height: 39px;}
  .impct-catg h5{font-family: 'Lato', sans-serif;font-size: 55px; color: #fff; font-weight: 700; margin: 0;}
  .impct-catg h5 sub{font-size: 25px; font-weight: 300;}
  .impectN .row:last-child{justify-content: center; margin: 0 -35px;}
  .impectN .row:last-child > div{width: auto; padding: 0 35px;}
  .impct-catg {min-width: 80%;width: 100%; position: relative;}
  .tones-sec{max-width: 100%; width: 355px;margin: 0 auto 90px;}  
  .tones-sec p {
    font-size: 20px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    opacity: 0.8;
    margin-left: 20px;
    max-width: 240px;
    text-transform: capitalize;
  }
.mrgntop{margin-top: 0;}
.hedbtom{margin-bottom: 0;}
.winner-sec{background: #FEFFF9;box-shadow: 11.6639px 11.6639px 23.3277px rgba(27, 60, 44, 0.1);border-radius: 11.6639px;min-height: 506px; text-align: center; padding:50px 20px 5px; margin: 45px 0 0;}

  .mrgntop .col-lg-6:nth-child(1) .winner-sec p{color: #fff;background: linear-gradient(154.09deg, #36204D 34.21%, #000000 171.97%); 
    box-shadow: 10.5636px 10.5636px 21.1272px rgba(146, 90, 171, 0.35);
    border-radius: 15.8454px;padding: 20px 20px;}
  .mrgntop .col-lg-6:nth-child(2) .winner-sec p{color: #fff; background: linear-gradient(162.46deg, #1F4B35 32.4%, #1B3A2A 86.3%);
    box-shadow: 10.6532px 10.6532px 21.3064px rgba(146, 90, 171, 0.35);
    border-radius: 15.9798px;padding: 20px 20px;text-transform: capitalize;}
  .mrgntop .col-lg-6:nth-child(3) .winner-sec p{color: #fff; background: linear-gradient(154.09deg, #36204D 34.21%, #000000 171.97%);
    box-shadow: 10.6532px 10.6532px 21.3064px rgba(146, 90, 171, 0.35);
    border-radius: 15.9798px;text-transform: capitalize;}
    .mrgntop .col-lg-6:nth-child(4) .winner-sec p{color: #fff; background: linear-gradient(162.46deg, #1F4B35 32.4%, #1B3A2A 86.3%);
      box-shadow: 10.5636px 10.5636px 21.1272px rgba(146, 90, 171, 0.35);
      border-radius: 15.8454px;text-transform: capitalize;}
      .mrgntop .col-lg-6:nth-child(5) .winner-sec p {
        color: #fff;
        background: linear-gradient(154.09deg, #36204D 34.21%, #000000 171.97%);
        box-shadow: 10.6532px 10.6532px 21.3064px rgba(146, 90, 171, 0.35);
        border-radius: 15.9798px;
        padding: 20px 20px;
        text-transform: capitalize;
      }
  .winner-sec span{display: block; margin-bottom: 10px;}
  .winner-sec span img{max-width: 100%; max-height: 100%;width: auto;height: auto;}
  .winner-sec p {
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    font-weight: 700;
    padding: 30px;
    max-width: 473px;
    margin: 0 auto;
    min-height: 273px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 43px;
    padding: 23px;
  }
  .taginfo{display: flex; justify-content: center; align-items: center;}
  .taginfo li{list-style-type: none;}
  .taginfo img{padding: 0 10px; max-width: 100%; max-height: 100px; height: auto; width: auto;}


.making-sec{margin:130px 0 50px 0;}
.making-sec h3{font-family: 'Merriweather', serif; color: #262626; text-align: center; font-size: 45px; font-weight: 700;}
.making-sec h6{font-family: 'Merriweather', serif; color: #262626; text-align: center; font-size: 35px; font-weight: 600;border-bottom: 4px dashed #204C35; padding-bottom: 15px; margin-bottom: 30px;}
.newslettr-sec{text-align: center; padding: 30px 0; max-width: 805px;margin: 0 auto;}
.newslettr-sec span{color: #204D36; font-family: 'Poppins', sans-serif; font-size: 28px; text-transform: uppercase;display: block; margin-bottom: 15px;font-weight: 500;}
.newslettr-sec h5{color: #000; font-family: 'Poppins', sans-serif; font-size: 42px; font-weight: 600;line-height: 50px;text-transform: capitalize;}
.newslettr-sec p{color: #8B8B8B; font-family: 'Poppins', sans-serif; font-size: 17px;}
.newslettr-sec .form-group{margin-top: 40px;}
.newslettr-sec .form-group .inputgroup {
	position: relative;
	margin: 0px auto;
	max-width: 705px;
}
.newslettr-sec .form-group .inputgroup .form-control {
	background: 0 0;
	border: 1.40364px solid #000000;
	font-size: 22px;
	height: 90px;
	padding: 0px 180px 0px 15px;
	-webkit-border-radius: 0px;
	color: #378373;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 0px #fff;
	box-shadow: 0px 0px 0px #fff;
	-webkit-appearance: none;
	-moz-appearance: none;
  
}
.newslettr-sec .form-group .inputgroup .subscribebtn {
	position: absolute;
	right: 7px;
	top: 9px;
	padding: 19px;
	text-align: center;
	background: #36204D;
	border: none;
	outline: none;
	color: #fff;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	font-size: 22px;
	text-shadow: 4px 9px 10px rgba(0, 0, 0, 0.25);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
  min-width: 150px;
}

.map-sec{text-align: right;}
.map-sec img{max-width: 100%;}


.our-team{margin: 80px 0;}
.waste-data{max-width: 900px; margin: 0 auto;}
.team-heding{cursor: pointer;text-align: center; margin: 50px 0;}
.team-heding h3{text-align: center;color: #262626;font-size: 45px;font-family: 'Merriweather', serif; font-weight: 700;}
.team-heding p{text-align: center;color: #262626;font-size: 30px;font-family: 'Merriweather', serif; font-weight: 700;}
.team-pic{margin-bottom: 20px;}
.team-pic img{max-width: 100%;height: auto;}
.team-pic img:first-child {height: auto;}
.mthechmp{text-align: center; margin-top: 50px;}
.mthechmp a{display: inline-block; background: #36204D; border-radius: 10px; padding: 10px 20px; text-decoration: none; color: #fff;}
.mthechmp a:hover{background: #204D36;}

.collect-sec{margin: 60px 0 130px;}
.collect-box {
	background: #FFFFFF;
	box-shadow: 5.5877px 5.5877px 16.7631px 2.23508px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 70px 50px 55px;
	text-align: center;
}
.collect-box h4{color: #000;font-size: 30px;font-family: 'Merriweather', serif; font-weight: 700;}
.collect-box p{color: #5A687B;font-size: 16px;font-family: 'Poppins', sans-serif; font-weight: 500;  margin: 25px auto;max-width: 256px;}
.collect-box a{font-family: 'Poppins', sans-serif;display: inline-block; background: #36204D; border-radius: 10px; padding: 10px 20px; text-decoration: none; color: #fff;}
.collect-box a:hover{background: #204D36;}

.collect-sec .container{
  max-width: 1240px;
}



.footer-sec{background: #132742; padding: 50px 0; display: block;}
.fotrlgo{margin-bottom: 20px;}
.footer-abut p{font-family: 'Poppins', sans-serif; font-size: 15px; color: #fff;}
.footer-abut span{font-family: 'Poppins', sans-serif; font-size: 13px; color: #fff;}
.prvcylnk{padding-left: 0; color: #9d9c9c;}
.prvcylnk li{display: inline-block; padding: 0 8px; color: #fff;}
.prvcylnk li a{font-family: 'Poppins', sans-serif; font-size: 15px; color: #fff; text-decoration: none; font-weight: 400;opacity: 0.5;}
.prvcylnk li a:hover{opacity: 1; color:#F1EBBB;}
.prvcylnk li:first-child{padding-left: 0;}
.social-link{padding-left: 0; margin-top: 10px;}
.social-link li{display: inline-block; margin-right: 15px;}
.social-link li a{color: #fff; opacity: 0.5;}
.social-link li a:hover{color: #fff; opacity: 1;}
.foter-menu h4{color: #fff;font-family: 'Poppins', sans-serif;font-size: 20px; margin-bottom: 20px;}
.foter-menu ul{padding-left: 0; width: 50%; float: left;}
.foter-menu ul li{list-style-type: none;}
.foter-menu ul li a{color: #D0D4D9; text-decoration: none;font-family: 'Poppins', sans-serif;font-size: 16px;margin-bottom: 10px; display: block;}
.foter-menu ul li a:hover{color: #fff;}



.mobile-viw{display: none;}




.awrd-panel{max-width: 1421px;margin: 0 auto;}
.awrd-panel .row{
  margin-left: -18.5px;
  margin-right: -18.5px;
}
.awrd-panel .row > div{
  padding-left: 18.5px;
  padding-right: 18.5px;
}


.mobilimpct{
  display: none;
}



.team-pic {
	-webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}
.image-styled {
  transition: 0.5s linear;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  /* animation: animName 2s linear; */
}
/* @keyframes animName {
  0%{
     transform: rotate(0deg);
    }
 100%{
     transform: rotate(360deg);
    }
 } */

.mobilrecycle{
  display: none;
}

.uplifting{
  width: 100%;
  display: block;
  background: #ECF7F2;
}
.uplifting .container{
  max-width: 1295px;
  padding: 0;
}

.championbx{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.chpiopic{
  position: relative;
}
.uplto {
	position: absolute;
	top: 0;
	left: 100%;
	min-width: 390px;
  max-width: 390px;
	width: 100%;
	margin-left: 40px;
}
.uplto em{
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 49px;
  color: #000000;
}
.uplto p{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 49px;
  color: #000000;
}

.chpiopic:nth-child(2) .uplto{
  margin-right: 40px;
  left: auto;
  right: 100%;
  top: auto;
  bottom: 40px;
  text-align: right;
}
.chpiopic:nth-child(2) .uplto p{
  text-align: right;
}
.chpiopic figure img{
  border-radius: 35px;
}

.uplifting h3{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 82px;
  text-align: center;
  text-transform: capitalize;
  color: #204D36;
  padding: 40px 0;
}


.processing-sec{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 95px 0;
}

.process-prcnt{
  display: flex;
  justify-content: space-between;
  gap:20px;
  max-width: 694px;
  width: 100%;
}
.wrkfrcbx {
	background: #FFFFFF;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	padding: 10px;
	text-align: center;
  width: 100%;
}
.wrkfrcbx h4{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 76px;
  color: #1B3B2B;
}
.wrkfrcbx p{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 28px;
  line-height: 45px;
  color: #000;
}
.scndprcs{
  display: flex;
}
.scndprcs img{
  max-width: 100%;
  object-fit: cover;
}
.scndprcs img:last-child{
  max-width: 100%;
  width: 60%;
}
.procss-pic{
  max-width: 577px;
  width: 100%;
  margin-right: -80px;
}
.procss-pic img{
  width: 100%;
}
.chpiopic figure:first-child{
  display: none;
}
.newimpct{
  margin-bottom: 0;
  padding-bottom: 0;
  background-color: #ECF7F2;;
}
.impctawrsbg {
	background-size: 100% 100%;
	margin: 0 0 150px;
}
.impcmobiltxt{
  display: none;
}

.without-bntx .nav.nav-tabs  .nav-item{
  padding: 0 15px;
}
.without-bntx .nav.nav-tabs .nav-link{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: #132742;
  line-height: 25px;
  padding: 10px 0;

}
.without-bntx .nav.nav-tabs .nav-link.active {
	border-bottom: 1px solid #36204D;
}
.tabbox-sec{
  display: none;
}


.mobile-viwchng{
	display: none;
}



.dataviw-dkstp > .row > div:nth-child(1) .uniqunes-inner,
.dataviw-dkstp > .row > div:nth-child(2) .uniqunes-inner,
.dataviw-dkstp > .row > div:nth-child(3) .uniqunes-inner{min-height: 350px;}


.impactRedsg{min-height: auto !important; position: relative; background-size: cover; padding-bottom: 170px;}
.impactRedsg::before{position: absolute; content: ""; bottom: 0; left: 0; height: 190px;
width: 100%; background: url(../images/blueShape.png) no-repeat 0 0; background-size: 100% 100% !important;}

.impactRedsg.cltmywsteimpct::before,
.impact-detailsinfo.impactRedsg::before {background: url(../images/blueShapeWht.png) no-repeat 0 0;}

