.about {
	height: 80vh;
	padding: 10px;
}

.abutbnr .banner-cap h4 {
	max-width: 100%;
}

.abutbnr .banner-cap a {
	background: #204D36;
	color: #F1EBBB;
	box-shadow: none;
	font-size: 28px;
	font-weight: 600;
}

.abutbnr .banner-cap a:hover {
	background: #36204D;
	color: #F1EBBB;
	box-shadow: none
}

.clctbg {
	background: #f5f5f5 !important;
}

.award-sec {
	padding: 70px 158px 0;
	position: relative;
}

.award-sec .container {
	position: relative;
	z-index: 1;
}

.award-sec::before {
	position: absolute;
	content: '';
	background-image: url(../../images/boxes.png);
	background-repeat: no-repeat;
	bottom: 14px;
	height: 238px;
	width: 100%;
	background-size: 100% 100%;
	left: 0;
}

.awrdtxt {
	position: relative;
	z-index: 1;
}

.awrdtxt h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 50px;
	line-height: 75px;
	color: #262626;
}

.awrdtxt p {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 40px;
	color: #676767;
	margin-bottom: 30px;
}

.rdytogat {
	display: flex;
}

.rdybtn a {
	background: #36204D;
	border-radius: 12px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 22.6961px;
	line-height: 34px;
	color: #FFFFFF;
	text-decoration: none;
	padding: 14px 30px;
	display: inline-block;
}

.rdybtn a:hover {
	box-shadow: rgb(118, 0, 143, 0.5) 0px 3px 20px 4px;
}

.wtchbtn {
	padding-left: 30px;
}

.wtchbtn a {
	display: flex;
	align-items: center;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 20.4265px;
	line-height: 31px;
	color: #132742;
	text-decoration: none;
}

.wtchbtn span {
	width: 68px;
	height: 68px;
	display: flex;
	background: #fff;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	color: #36204D;
	margin-right: 15px;
}

.awrpic-sec img {
	max-width: 100%;
}

.awrpic-sec img:last-child {
	display: none;
}



.digial-waste {
	padding: 80px 0;
}

.digitalbx {
	padding: 48px 35px;
	background: #fff;
	text-align: center;
	min-height: 378px;
	position: relative;
}

.digitalbx::before {
	position: absolute;
	content: '';
	top: 45px;
	bottom: 14px;
	left: -10px;
	right: -10px;
	background: rgba(206, 206, 206, 0.2);
	filter: blur(26.259px);
	z-index: -1;
}

.digitalbx span {
	background: rgba(32, 77, 54, 0.1);
	display: flex;
	width: 100px;
	height: 100px;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	border-radius: 50%;
}

.digitalbx span img {
	max-width: 55px;
}

.digitalbx h5 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 38px;
	text-align: center;
	text-transform: capitalize;
	color: #204D36;
	margin-top: 15px;
}

.digitalbx p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: #5A687B;
}

.digial-waste .bloghedng {
	max-width: 100%;
}



.envrmntl-sec {
	background: #ECF7F2;
	padding: 50px 0;
}

.envrmntl-sec .bloghedng {
	max-width: 820px;
	margin: 0 auto;
	margin-bottom: 30px;
}

.envrmntl-sec .bloghedng p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 31px;
	text-align: center;
	color: #5A687B;
	max-width: 600px;
	margin: 0 auto;
}



.appviw-sec {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 120px;
}

.appviwpic {
	position: relative;
}

.appviwpic img {
	max-width: 600px;
}

.appviwpic p {
	position: absolute;
	bottom: 55px;
	left: -143px;
	font-weight: 500;
	font-size: 25px;
	line-height: 34px;
	color: #000000;
	font-family: 'Poppins', sans-serif;
}

.applink p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 43px;
	color: #5A687B;
	max-width: 410px;
}

.applink a {
	background: #36204D;
	padding: 20px 25px;
	border-radius: 6.23386px;
	font-family: 'clash_displayregular';
	font-weight: bold;
	font-size: 24px;
	line-height: 44px;
	text-transform: capitalize;
	color: #fff;
	text-decoration: none;
	display: inline-block;
	margin-top: 15px;
}

.applink a img {
	max-width: 35px;
	margin-left: 25px;
}



.our-stack {
	padding: 150px 0 100px;
}

.stack-content {
	max-width: 1024px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.stacktxt {
	max-width: 600px;
}

.stackpic img {
	max-width: 100%;
}

.stacktxt h6 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 23.6057px;
	line-height: 35px;
	color: #132742;
	margin-bottom: 40px;
	background: #FAF8FC;
	border-radius: 10px;
	padding: 10px 20px;
	display: inline-block;
}

.stacktxt p {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 40px;
	color: #5A687B;
}

.stacktxt a {
	background: #36204D;
	border-radius: 12px;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 23.6057px;
	line-height: 35px;
	color: #FFFFFF;
	padding: 15px 44px;
	text-decoration: none;
	margin-top: 10px;
	display: inline-block;
}

.customer-sec {
	padding: 150px 15px 50px;
	position: relative;
}

.customer-sec .owl-stage {
	padding-left: 0 !important;
}

.customer-sec::before {
	position: absolute;
	content: '';
	background-image: url(../../images/twoline.png);
	background-repeat: no-repeat;
	bottom: 14px;
	height: 700px;
	width: 100%;
	background-size: contain;
	right: 0;
}

.customer-sec h3 {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 45px;
	line-height: 69px;
	text-align: center;
	letter-spacing: -0.03em;
	color: #262626;
	margin-bottom: 50px;
}

.cutomerbx {
	position: relative;
	padding: 15px 0;
	/* background: #FFFFFF; */
	/* border: 1.44961px solid #EDEDED; */
	/* box-shadow: 0px 11.8117px 9.44932px rgba(49, 49, 49, 0.0392593), 0px 2.68447px 4.56359px rgba(49, 49, 49, 0.0240741); */
	filter: drop-shadow(0px 11.8117px 9.44932px rgba(49, 49, 49, 0.0392593));

	/* box-shadow: 0px 11.8117px 9.44932px rgba(49, 49, 49, 0.0392593), 0px 2.68447px 4.56359px rgba(49, 49, 49, 0.0240741); */
}

.cutomerbx p {
	padding: 0 30px;
	font-family: 'Manrope', sans-serif;
	font-weight: 500;
	font-size: 19px;
	line-height: 32px;
	color: #424242;
	min-height: 200px;
}

.cutomerbx::before {
	clip-path: polygon(100% 0px, 99.09% 80.13%, 84.55% 100.62%, 0px 100%, 0px 0px);
	content: '';
	background: #EDEDED;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	z-index: -1;
	/* filter: drop-shadow(49, 49, 49, 0.0392593) sepia(100%) drop-shadow(49, 49, 49, 0.0240741); */
	border: 1.44961px solid #EDEDED;
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.25));
}

.cutomerbx::after {
	clip-path: polygon(100% 0px, 99.09% 80.13%, 84.55% 100.62%, 0px 100%, 0px 0px);
	content: '';
	background: #fff;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	border: 1.44961px solid #EDEDED;
}

.cutomer-dtl {
	border-top: 1.44961px solid #EAEFF3;
	padding: 20px 30px 0;
}

.abut-page-bg {
	position: relative;
	background: #FEFFF9;
}

.abut-page-bg::before {
	background: #FEFFF9;
	position: relative;
	z-index: -1;
}

.customrinfo {
	position: relative;
	z-index: 1;
	padding: 20px 0;
	min-height: 350px;
}

.cutomerbx ul {
	padding: 0 30px;
	list-style-type: none;
}

.cutomerbx ul li {
	color: #FE8B75;
	font-size: 22px;
	display: inline-block;
	margin-right: 5px;
}

.map-sec span {
	color: #204D36;
	font-family: 'Poppins', sans-serif;
	font-size: 28px;
	text-transform: uppercase;
	display: block;
	margin-bottom: 15px;
	font-weight: 500;
	text-align: center;
	padding-right: 126px;
}





.seggrate-waste {
	padding: 150px 0 100px;
}

.seggrate-inner {
	border: 3px dashed #36204D;
	border-radius: 10px;
	padding: 0;
	min-height: 400px;
	width: 100%;
	background: #FEFFF9;
}

.seggrate-inner h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 55px;
	line-height: 69px;
	text-align: center;
	letter-spacing: -0.03em;
	text-transform: capitalize;
	color: #262626;
	margin-top: 30px;
}

.seggrate-inner p {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 30px;
	line-height: 134%;
	color: #000000;
	text-align: center;
}

.all-wastege {
	display: flex;
	justify-content: space-between;
	gap: 25px;
	position: relative;
	padding: 40px 40px 0 40px;
	margin-bottom: 166px;
}

.paper-waste {
	width: 100%;
	text-align: center;
}

.paper-waste:nth-child(1) {
	position: relative;
}

.paper-waste:nth-child(1)::after {
	position: absolute;
	content: '';
	background-image: url(../../images/arw1.png);
	background-repeat: no-repeat;
	bottom: -114px;
	height: 200px;
	width: 150px;
	background-size: contain;
	right: 0;
}

.paper-waste:nth-child(2) {
	position: relative;
}

.paper-waste:nth-child(2)::after {
	position: absolute;
	content: '';
	background-image: url(../../images/arw2.png);
	background-repeat: no-repeat;
	bottom: -148px;
	height: 200px;
	width: 150px;
	background-size: contain;
	left: 64px;
}

.paper-waste:nth-child(3) {
	position: relative;
}

.paper-waste:nth-child(3)::after {
	position: absolute;
	content: '';
	background-image: url(../../images/arw3.png);
	background-repeat: no-repeat;
	bottom: -203px;
	height: 275px;
	width: 227px;
	background-size: contain;
	left: -71px;
}

.paper-waste:last-child {
	position: absolute;
	right: 40px;
	bottom: -40px;
	max-width: 280px;
}

.paper-waste:last-child img {
	position: absolute;
	left: -120px;
	top: 50px;
	max-width: 106px;
}

.paper-waste:last-child::after {
	position: absolute;
	content: '';
	background-image: url(../../images/arw4.png);
	background-repeat: no-repeat;
	bottom: -294px;
	height: 367px;
	width: 609px;
	background-size: contain;
	left: -277%;
	z-index: 1;
}

.paper-waste img {
	max-width: 100%;
}

.paper-waste h5 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 134%;
	text-decoration-line: underline;
	color: #204D36;
	text-align: center;
}

.platicbx {
	background: #ECF7F2 !important;
	border: 1px solid #204D36;
	border-radius: 10px;
	padding: 12px;
	margin-bottom: 55px;
}

.platicbx h6 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 134%;
	text-align: center;
	color: #000000;

}

.platicbx ul {
	padding: 0;
	list-style-type: none;
}

.platicbx ul li {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 23px;
	line-height: 134%;
	color: #000000;
	text-align: center;
	padding: 8px;
}


.waste-lower {
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
}

.waste-lower::after {
	position: absolute;
	content: '';
	background-image: url(../../images/shpe.png);
	background-repeat: no-repeat;
	bottom: 0;
	width: 100%;
	height: 100px;
	background-size: 100%;
}

.bintx-lower {
	position: relative;
	z-index: 1;
	right: 3%;
}

.bintx-lower img {
	max-width: 608px;

}

.wstecrry {
	position: relative;
	left: 7%;
	top: -22px;
}

.wstecrry img {
	width: auto;
	max-width: 100%;
}

.notcollect {
	padding: 20px 40px;
}

.notcollect h6 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 35px;
	line-height: 134%;
	/* text-decoration-line: underline; */
	color: #F34361;
}

.notcollect h6 span {
	font-size: 25px;
}

.collect-info ul {
	display: inline-block;
	padding: 15px 25px;
	margin-right: 30px;
	position: relative;
}

.collect-info ul li {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 25px;
	line-height: 134%;
	color: #000000;
}

.collect-info ul::after {
	position: absolute;
	content: '';
	background-image: url(../../images/crss.png);
	background-repeat: no-repeat;
	top: 30px;
	width: 100%;
	height: 100px;
	background-size: contain;
	left: 42px;
}




.recycleacpt {
	width: 100%;
	padding: 50px 0;
}

.recycleacordian {
	max-width: 960px;
	margin: 0 auto;
}

.scondacordian {
	margin-top: 100px;
}

.recycleacordian h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 45px;
	line-height: 35px;
	text-align: center;
	letter-spacing: 0.333718px;
	text-transform: capitalize;
	color: #242424;
	margin-bottom: 65px;
}

.scondacordian h3 {
	margin-bottom: 30px;
}

.accordion_container {
	/* filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.25)); */
	/* box-shadow: 2px 6px 11.3263px rgba(184, 187, 216, 0.25); */
	filter: drop-shadow(0px 5.03392px 11.3263px rgba(163, 167, 181, 0.25));
	box-shadow: 2px 6px 11.3263px rgba(184, 187, 216, 0.25);
	background: #FEFFF9;
	padding: 20px;
	margin-bottom: 15px;
	border-radius: 11px;
}

.accordion_head {
	cursor: pointer;
	margin: 0 0 5px 0;
	padding: 5px 0;
	display: block;
	overflow: hidden;
	width: 100%;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	position: relative;
	border: none;
	border-radius: 0;
}

.accordion_head span {
	width: 100%;
	display: block;
	padding-right: 130px;
	/* background: #fff; */
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 22px;
	line-height: 36px;
	letter-spacing: 0.125848px;
	color: #263238;
	position: relative;
	cursor: pointer;
	background-repeat: repeat;
}

.accordion_body {
	border-top: 1.2px solid #ECEDF0;
	padding-top: 15px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 21px;
	line-height: 36px;
	letter-spacing: 0.125848px;
	color: #2B324F;
	position: relative;
	cursor: pointer;
	background-repeat: repeat;
}

.plusminus {
	position: absolute;
	right: 2.1px;
	top: 7px;
	width: 30px;
	bottom: 2px;
	font-size: 30px;
	color: #76008F;
	text-decoration: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 0;
	height: 74%;
}

.impact-details {
	min-height: 1100px;
}

.cltmywsteimpct {
	margin-bottom: 0;
}

.scondacordian .accordion_body {
	font-size: 18px;
	line-height: normal;
}

.viwallfq {
	background: #36204D;
	box-shadow: 0px 9.46667px 9.46667px #E3E9FF;
	border-radius: 10.4005px;
	padding: 21px 60px;
	display: inline-block;
	text-decoration: none;
	font-weight: 700;
	font-size: 20px;
	text-align: center;
	color: #FFFFFF;
	margin-top: 70px;
}

.viwallfq:hover {
	background: #204D36;
	color: #F1EBBB;
}
.viwallfq2 {
	background: #36204D;
	box-shadow: 0px 9.46667px 9.46667px #E3E9FF;
	border-radius: 10.4005px;
	padding: 21px 60px;
	display: inline-block;
	text-decoration: none;
	font-weight: 700;
	font-size: 20px;
	text-align: center;
	color: #FFFFFF;
	margin-bottom: 100px;
}

.viwallfq2:hover {
	background: #204D36;
	color: #F1EBBB;
}

.banner-cap h1 {
	margin-bottom: 50px;
}

.envrmntl-sec .bloghedng {
	max-width: 1140px;
}

.envrmntl-sec .bloghedng p {
	max-width: 827px;
	margin: 10px auto;
	font-size: 25px;
}

.newslettr-sec p {
	text-transform: capitalize;
}

.abmbnr {
	display: none !important;
}

.mobile-wastege .accordion-item .acordiantxt {
	display: block;
}

.mobile-wastege .accordion-item button.collapsed .acordiantxt {
	display: none;
}

.mobile-wastege .accordion-button {
	flex-direction: column;
}

.mobile-wastege .accordion-item button h3 {
	margin: 0 !important;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 134%;
	text-decoration-line: underline;
	color: #204D36;
	text-align: center;
}

.mobile-wastege .accordion-button::after {
	right: 22px;
	top: 22px;
	position: absolute;
	color: #686868;
}

.mobile-wastege {
	display: none;
}

.mobile-wastege .accordion-body {
	text-align: center;
}

.mobile-wastege .accordion-item {
	border: none;
}

.mkingdkstp {
	display: none;
}

.mblabtn {
	display: none;
}

.bintx-lower2 {
	display: none;
}

.mbbntxt {
	display: none;
}

.mbtomr {
	position: relative;
	z-index: 1;
	margin-top: 100px;
}

.rgyget{
	border-radius: 13.001px;
	background: #36204D; 
	padding: 10px 20px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	margin-top: 12px;
	display: inline-block;
}
.rgyget:hover{
	color: #fff;
}
.dnonvdo{
	display: none;
}

@media (min-width: 1920px) {
	.banner-cap a {
		margin-top: 122px;
	}

	.abutbnr .banner-cap h4 {
		font-size: 35px;
		max-width: 800px;
	}

	.platicbx ul li {
		font-size: 20px;
	}

	.paper-waste h5 {
		font-size: 24px;
	}

	.seggrate-inner h3 {
		font-size: 45px;
	}

	.seggrate-inner p {
		font-size: 25px;
	}

	.map-sec span {
		padding-right: 0;
	}


}

@media (max-width: 1700px) {
	.platicbx h6 {
		font-size: 15px;
	}

	.customer-sec::before {
		bottom: -118px;
		height: 774px;
	}

	.awrdtxt p {
		font-size: 24px;
		line-height: 39px;
	}

	.digitalbx {
		padding: 40px 25px;
		min-height: 396px;
	}

	.awrdtxt h3 {
		font-size: 45px;
		line-height: 60px;
	}

	.digitalbx p {
		font-size: 18px;
		line-height: 28px;
	}

	.digitalbx h5 {
		font-size: 22px;
	}

	.applink p {
		font-size: 29px;
		line-height: 44px;
	}

	.cutomerbx p {
		font-size: 18px;
		line-height: 28px;
	}

	.seggrate-inner h3 {
		font-size: 35px;
		line-height: 65px;
	}

	.seggrate-inner p {
		font-size: 24px;
	}

	.paper-waste h5 {
		font-size: 20px;
	}

	.platicbx ul li {
		font-size: 16px;
		padding: 5px;
	}

	.paper-waste:last-child {
		max-width: 223px;
	}

	.bintx-lower img {
		max-width: 532px;
	}

	.wstecrry img {
		max-width: 81%;
	}

	.paper-waste:last-child::after {
		bottom: -221px;
		height: 244px;
		width: 426px;
		left: -252%;
	}

	.paper-waste:nth-child(3)::after {
		bottom: -174px;
		height: 251px;
		width: 198px;
		left: -33px;
	}

	.paper-waste:nth-child(2)::after {
		left: 26px;
	}

	.collect-info ul li {
		font-size: 24px;
	}

	.notcollect h6 {
		font-size: 30px;
	}

	.notcollect h6 span {
		font-size: 22px;
	}

	.recycleacordian h3 {
		font-size: 46px;
	}

	.accordion_head span {
		font-size: 22px;
		line-height: normal;
	}

	.accordion_container {
		padding: 10px 20px;
	}

	.accordion_body {
		font-size: 20px;
		line-height: normal;

	}

	.scondacordian .accordion_body {
		font-size: 15px;
	}

	.applink a {
		font-size: 20px;
	}


}


@media (max-width: 1600px) {
	.cutomerbx p {
		min-height: 170px;
	}

	.customer-sec::before {
		bottom: -156px;
	}

	.awrdtxt h3 {
		font-size: 45px;
		line-height: 60px;
	}

	.awrdtxt p {
		font-size: 20px;
		line-height: 36px;
	}

	.rdybtn a {
		font-size: 18px;
	}

	.digitalbx h5 {
		font-size: 22px;
	}

	.digitalbx p {
		font-size: 18px;
	}

	.customer-sec h3 {
		font-size: 35px;
		line-height: 66px;
		margin-bottom: 29px;
	}

	.collect-info ul li {
		font-size: 20px;
	}

	.collect-info ul::after {
		height: 62px;
	}

	.recycleacordian h3 {
		font-size: 40px;
		line-height: 39px;
		margin-bottom: 40px;
	}

	.accordion_head span {
		font-size: 20px;
		line-height: normal;
	}

	.accordion_body {
		font-size: 18px;
		line-height: normal;
	}

	.viwallfq {
		font-weight: 700;
		font-size: 23px;
	}

	.abutbnr .banner-cap a {
		font-size: 22px;
	}

	.harness-txt p {
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 40px;
	}

}

@media (max-width: 1399px) {
	.cutomerbx p {
		min-height: 183px;
		line-height: 26px;
		font-size: 16px;
	}

	.platicbx h6 {
		font-size: 13px;
	}

	.all-wastege {
		gap: 18px;
		padding: 40px 28px 0 40px;
	}

	.platicbx ul li {
		font-size: 15px;
	}

	.paper-waste h5 {
		font-size: 17px;
	}

	.collect-info ul li {
		font-size: 18px;
	}

	.customer-sec::before {
		bottom: -140px;
	}

	.wste-store img {
		height: 100%;
	}

	.recycletxt p {
		font-size: 22px;
	}


	.watch-content {
		max-width: 765px;
	}

	.awrdtxt h3 {
		font-size: 40px;
		line-height: 63px;
	}

	.awrdtxt p {
		font-size: 22px;
		line-height: 35px;
	}

	.award-sec {
		padding: 70px 0 0;
		position: relative;
	}

	.rdybtn a {
		font-size: 19px;
		line-height: normal;
		padding: 14px 20px;
	}

	.digitalbx p {
		font-size: 16px;
	}

	.appviwpic p {
		left: -105px;
		font-size: 21px;
	}

	.paper-waste img {
		max-width: 72%;
	}

	.paper-waste:last-child::after {
		bottom: -211px;
		height: 206px;
		width: 386px;
		background-size: contain;
		left: -208%;
	}

	.paper-waste:nth-child(3)::after {
		bottom: -181px;
		height: 251px;
		width: 189px;
		left: -42px;
	}

	.paper-waste:nth-child(2)::after {
		height: 172px;
	}

	.bintx-lower img {
		max-width: 414px;
	}

	.wstecrry img {
		max-width: 67%;
	}

	.paper-waste:nth-child(1)::after {
		bottom: -117px;
		height: 153px;
		width: 150px;
		right: -20px;
	}

	.map-sec span {
		font-size: 16px;
		padding-right: 134px;
	}

	.digitalbx p {
		font-size: 15px;
	}

	.digitalbx {
		padding: 40px 23px;
		min-height: 379px;
	}

	.digial-waste .bloghedng h2 {
		font-size: 35px;
	}

}

@media (max-width: 1300px) {
	.customer-sec::before {
		bottom: -157px;
	}
}

@media (max-width: 1199px) {
	.collect-box h4 {
		font-size: 25px;
	}

	.impct-catg span {
		top: -67%;
	}

	.wste-store img {
		height: 100%;
	}


	.rdybtn a {
		font-size: 16px;
		line-height: normal;
		padding: 14px 15px;
	}

	.digitalbx {
		margin: 10px;
	}

	.abutbnr .banner-cap a {
		font-size: 18px;
	}

	.digitalbx {
		min-height: 471px;
	}


.awrdtxt p br{display: none;}
.digitalbx {min-height: 370px; padding-bottom: 10px !important; padding: 30px 15px;}
.appviwpic img {max-width: 455px;}
.appviwpic p {left: -38px; font-size: 17px;}
.appviwpic {margin-left: 22px;}





}

@media (max-width: 991px) {
	.appviwpic p {
		left: 0;
	}

	.stacktxt h6 {
		font-size: 23px;
		margin-bottom: 15px;
	}

	.stacktxt a {
		font-size: 16px;
		line-height: normal;
		padding: 8px 20px;
	}

	.appviw-sec {
		display: block;
		gap: inherit;
	}

	.applink {
		margin-top: 30px;
	}

	.cutomerbx {
		margin-bottom: 20px;
	}

	.seggrate-inner h3 {
		font-size: 30px;
		line-height: normal;
	}

	.seggrate-inner p {
		font-size: 22px;
	}

	.all-wastege {
		display: block;
	}

	.paper-waste::after {
		display: none;
	}

	.paper-waste {
		margin-bottom: 30px;
	}

	.platicbx {
		margin-bottom: 33px;
	}

	.award-sec {
		padding: 0 0 15px;
		background: #fefff9;
		position: relative;
		z-index: 1;
	}

	.awrdtxt h3 {
		font-size: 48px;
		line-height: normal;
	}

	.awrdtxt p {
		font-size: 22px;
		line-height: normal;
	}

	.applink {
		text-align: center;
	}

	.applink p {
		font-size: 20px;
		line-height: normal;
		color: #5A687B;
		text-align: center;
		max-width: 100%;
	}

	.our-stack {
		padding: 56px 0;
	}

	.stack-content {
		display: block;
	}

	.stackpic {
		margin-top: 60px;
	}

	.customer-sec h3 {
		font-size: 46px;
		line-height: normal;
		margin-bottom: 30px;
	}

	.paper-waste:last-child {
		position: relative;
		right: 0;
		bottom: 0;
		max-width: 100%;
	}

	.waste-lower {
		display: block;
	}

	.paper-waste:last-child img {
		position: relative;
		left: 0;
		top: 0;
		max-width: 106px;
	}

	.bintx-lower img {
		max-width: 64%;
	}

	.bintx-lower {
		text-align: center;
		padding-bottom: 50px;
	}

	.notcollect h6 {
		font-size: 30px;
	}

	.collect-info ul li {
		font-size: 20px;
	}

	.recycleacordian h3 {
		font-size: 40px;
	}

	.wstecrry {
		left: 0;
		top: -22px;
		text-align: center;
	}

	.wstecrry img {
		max-width: 67%;
	}

	.recycleacpt {
		padding: 50px 0;
	}

	.accordion_head span {
		padding-right: 62px;
	}

	.stacktxt p {
		font-size: 18px;
		line-height: normal;
	}

	.appviwpic img {
		max-width: 85%;
	}

	.appviwpic {
		position: relative;
		text-align: center;
	}

	.customrinfo {
		min-height: inherit;
	}

	.digitalbx p {
		font-size: 14px;
		line-height: normal;
	}

	.digitalbx h5 {
		font-size: 18px;
	}



.digitalbx {min-height: 310px;}




}

@media (max-width: 768px) {
	.recycleacordian h3 {
		font-size: 31px;
	}

	.awrdtxt h3 {
		font-size: 40px;
		line-height: normal;
	}

	.awrdtxt p {
		font-size: 20px;
		line-height: normal;
	}

	.rdytogat {
		display: block;
		text-align: center;
		width: 100%;
	}

	.wtchbtn a {
		justify-content: center;
		margin-top: 15px;
	}

	.wtchbtn {
		padding-left: 0;
		text-align: center;
	}

	.appviwpic p {
		position: relative;
		bottom: 0;
		left: 0;
		margin-top: 15px;
	}

}

@media (max-width: 767px) {

	.accordion_head span {
		font-size: 17px;
	}

	.accordion_body {
		font-size: 15px;
	}

	.plusminus {
		font-size: 20px;
	}

	.scondacordian {
		margin-top: 40px;
	}

	.recycleacordian h3 {
		font-size: 29px;
		margin-bottom: 20px;
	}

	.customer-sec h3 {
		font-size: 28px;
		margin-bottom: 20px;
	}

	.cutomerbx p {
		font-size: 15px;
		line-height: normal;
	}

	.customrinfo {
		padding: 10px 0;
	}

	.customer-sec {
		padding: 100px 0 30px;
		margin-bottom: 68px;
	}

	.collect-info ul li {
		font-size: 16px;
	}

	.collect-info ul {
		margin-right: 0;
		margin-bottom: 0;
	}

	.notcollect h6 {
		font-size: 22px;
	}

	.notcollect h6 span {
		font-size: 16px;
	}

	.notcollect {
		padding: 20px 20px;
	}

	.recycleacpt {
		padding: 0;
	}

	.impact-details {
		min-height: 1364px;
	}

	.seggrate-inner h3 {
		font-size: 29px;
	}

	.seggrate-inner p {
		font-size: 18px;
	}
	.mbtomr {
		margin-top: 50px !important;
	}


.digitalbx {min-height: 275px;}





}

@media (max-width: 575px) {
	.abmbnr {
		display: block !important;
	}

	.awrpic-sec img:last-child {
		display: block;
	}

	.awrpic-sec img:first-child {
		display: none;
	}

	.applink a {
		font-size: 14px;
		padding: 10px 20px;
	}

	.applink a img {
		max-width: 21px;
		margin-left: 10px;
	}

	.stacktxt p {
		font-size: 16px;
	}

	.cutomerbx ul li {
		color: #FE8B75;
		font-size: 15px;
	}

	.accordion_body {
		font-size: 16px;
	}

	.accordion_head span {
		font-size: 16px;
	}

	.recycleacordian h3 {
		font-size: 26px;
		margin-bottom: 15px;
	}

	.awrdtxt h3 {
		font-size: 30px;
		line-height: normal;
	}

	.awrdtxt p {
		font-size: 16px;
		line-height: 28px;
		margin-bottom: 0;
	}

	.seggrate-inner h3 {
		font-size: 30px;
		line-height: normal;
		margin-bottom: 30px;
	}

	.seggrate-inner p {
		font-size: 14px;
	}

	.envrmntl-sec .bloghedng p {
		font-size: 16px;
		line-height: normal;
		text-align: left;
	}

	.digial-waste .bloghedng h2 {
		font-size: 30px;
	}

	.digitalbx p {
		font-size: 14px;
		line-height: normal;
	}

	.digitalbx h5 {
		font-size: 18px;
	}

	.applink p {
		font-size: 20px;
	}

	.appviwpic p {
		display: none;
	}

	.stacktxt h6 {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.digial-waste .owl-stage {
		padding-left: 0 !important;
	}

	.abutbnr .banner-cap a {
		color: #FEFFF9;
	}

	.mobile-wastege .accordion-item button h3 {
		font-size: 22px;
	}

	.stacktxt a {
		display: none;
	}

	.envrmntl-sec .bloghedng h2 span {
		display: block;
		font-size: 18px;
	}

	.clwstelog {
		display: none;
	}
	.viwallfq2 {
		padding: 15px 50px;
		font-size: 12px;
	}
	.dnonvdo{
		display: block;
	}
}

@media (max-width: 440px) {
	.awrdtxt h3 {
		font-size: 30px;
		line-height: 40px;
		padding-right: 22px;
	}




	.watch-jurny {
		margin: 28px 0;
	}



	.prtnrlgo img {
		max-width: 67%;
	}

	.partner-sec {
		margin: 0 0;
	}

	.impact-details {
		margin: 50px 0 50px;
		overflow: hidden;
	}

	.impct-hed h3 {
		font-size: 30px;
	}

	.prtnr-heading h3 {
		font-size: 25px;
		margin-bottom: 0;
	}

	.midlbbdr::after {
		width: 254%;
		left: -75%;
	}

	.blog-txt {
		padding: 15px 12px;
	}

	.blgnmbr span {
		font-size: 110px;
		line-height: 110px;
	}

	.newslettr-sec h5 {
		font-size: 18px;
		line-height: 26px;
	}

	.newslettr-sec p {
		font-size: 13px;
	}

	.stacktxt h6 {
		font-size: 13px;
	}

	.stackpic {
		margin-top: 10px;
	}



}

@media (max-width: 400px) {

	.digitalbx h5 {
		font-size: 16px;
		line-height: 20px;
	}

	.digitalbx p {
		font-size: 13px;
	}

	.digitalbx {
		padding: 40px 20px;
		min-height: 346px;
	}

	.bloghedng h2 {
		line-height: 142.2%;
	}

	.abutbnr .banner-cap {
		padding: 0 20px;
	}

	.wtchbtn a {
		font-size: 13px;
	}

	.rdybtn a {
		font-size: 14px;
		line-height: normal;
		padding: 14px 15px;
	}

	.digial-waste {
		padding: 80px 0 30px;
	}

	.digial-waste {
		padding: 80px 0 20px;
	}
}


@media (max-width: 767px) {
	.mbbntxt {
		display: block;
	}

	.dkbntxt {
		display: none;
	}

	.abutbnr .banner-cap h4 {
		font-size: 21px;
		padding: 0 31px;
	}


	.impct-hed:first-child h3 {
		display: block;
	}

	.platicbx {
		margin-bottom: 15px;
	}

	.mobile-wastege {
		display: block;
		padding: 0 20px;
	}

	.wstecrry {
		display: none;
	}

	.all-wastege {
		display: none;
	}

	.seggrate-inner p {
		display: none;
	}

	.seggrate-inner {
		border: none;
		background: none;
	}

	.platicbx h6 {
		font-size: 20px;
		margin-top: 10px;
	}

	.platicbx ul li {
		font-size: 20px;
	}

	.bintx-lower {
		display: none;
	}

	.waste-lower::after {
		display: none;
	}

	.bintx-lower2 {
		display: block;
		text-align: center;
		margin-top: 40px;
	}

	.bintx-lower2 img {
		max-width: 100%;
	}

	.notcollect {
		display: none;
	}

	.viwallfq {
		display: none;
	}

	.mkingdkstp {
		display: block;
	}

	.mkingdkstp h3 {
		font-size: 25px !important;
	}

	.mkingdkstp h6 {
		font-size: 20px;
		border-bottom: 2px dashed #204C35;
	}

	.map-sec span {
		display: none;
	}

	.mapwst .map-sec {
		margin: 30px 0;
	}

	.rdytogat {
		display: none;
	}

	.mblabtn {
		display: block;
		margin-top: 50px;
	}

	.cltmywsteimpct {
		display: none !important;
	}

	.mbtomr {
		margin-bottom: 100px;
	}

	.mbtomr .prtnrlgo {
		width: 110px;
		height: 110px;
	}

	.seggrate-waste {
		padding: 50px 0 50px;
	}


}