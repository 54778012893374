


.newsbnr-content {
    width: 100%;
    position: absolute;
    top: 75%;
    transform: translateY(-75%);
}

.newsbnr-content .container {
    max-width: 1280px;
}

.nwsbntxt {
    background: #FFFFFF;
    border: 1.14154px solid #E8E8EA;
    box-shadow: 0px 13.6985px 27.397px -6.84925px rgba(24, 26, 42, 0.12);
    border-radius: 13.6985px;
    max-width: 682px;
    padding: 30px;
}

.nwsbntxt span {
    background: #36204D;
    border-radius: 6.84925px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    padding: 7px 12px;
    margin-bottom: 20px;
    display: inline-block;
}

.nwsbntxt h3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 41.0955px;
    line-height: 46px;
    color: #181A2A;
    margin-bottom: 25px;
}

.nwsbntxt .media {
    display: flex;
    align-items: center;
    color: #fff;
}

.nwsbntxt .mediapic {
    width: 41px;
    height: 41px;
    flex: 0 0 41px;
    border-radius: 100%;
}

.nwsbntxt .mediapic img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.nwsbntxt .media em {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #97989F;
    font-style: normal;
    padding-left: 15px;
}

.nwsbntxt .media i {
    font-style: normal;
    margin-left: 20px;
    font-weight: 400;
}

.latest-news-sec {
    width: 100%;
    padding: 0 0 80px 0;
}

.latest-news-sec .container {
    max-width: 1388px;
}

.news-box {
    border: 1.14154px solid #E8E8EA;
    border-radius: 13.6985px;
    padding: 18px;
}

.newsbx-inner {
    width: 100%;
    position: relative;
}

.newsbx-inner figure {
    position: relative;
    margin: 0;
}

.newsbx-inner figure img {
    border-radius: 6px;
}

.newsbx-inner figure::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(0.6deg, #000000 15.98%, rgba(0, 0, 0, 0) 119.73%);
    opacity: 0.75;
}

.newscont {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
}

.newscont span {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #FFFFFF;
}

.newscont h3 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    color: #FFFFFF;
    margin: 17px 0;
}

.newscont .media {
    display: flex;
    align-items: center;
    color: #fff;
}

.newscont .mediapic {
    width: 41px;
    height: 41px;
    flex: 0 0 41px;
    border-radius: 100%;
}

.newscont .mediapic img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.newscont .media em {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    font-style: normal;
    padding-left: 15px;
}

.newscont .media i {
    font-style: normal;
    margin-left: 20px;
    font-weight: 400;
}

.news-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.news-heading h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 32px;
    color: #181A2A;
}

.news-heading a {
    background: #36204D;
    border-radius: 15px;
    width: 134px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.news-heading a:hover {
    color: #fff;
    background: #204D36;
}

.blog-box {
    border: 1.14154px solid #E8E8EA;
    border-radius: 13.6985px;
    padding: 18px;
    margin-bottom: 18px;
}

.blog-box .newsbx-inner figure::after {
    display: none;
}

.blog-box .newscont {
    position: relative;
}

.blog-box .newscont h3 {
    color: #181A2A;
}

.blog-box .newscont span {
    color: #36204D;
}

.blog-box .newscont .media em {
    color: #97989F;
}


.article-box {
    background: #FFFFFF;
    border: 1.05945px solid rgba(17, 17, 17, 0.1);
    border-radius: 6.35671px;
    margin-bottom: 35px;
}

.articlepic img {
    max-width: 100%;
}

.articletxt {
    padding: 50px;
}

.articletxt em {
    color: #111;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 25px;
    display: block;
}

.articletxt em i {
    background: #36204D;
    border-radius: 2.1189px;
    padding: 10px 12px;
    font-family: 'Apercu';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    margin-right: 12px;
    display: inline-block;
}

.articletxt h3 {
    font-family: 'apercu_probold';
    font-style: normal;
    font-weight: 700;
    font-size: 42.378px;
    line-height: 120%;
    color: #111111;
    margin-bottom: 20px;
}

.articletxt p {
    font-family: 'aperculight';
    font-style: normal;
    font-weight: 400;
    font-size: 16.9px;
    line-height: 150%;
    color: #111111;
    opacity: 0.6;
}

.articletxt a {
    border: 1.05945px solid #111111;
    border-radius: 4.2378px;
    display: flex;
    width: 139px;
    height: 42px;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #111111;
    text-decoration: none;
    margin-top: 50px;
}

.articletxt a svg {
    margin-left: 5px;
}

.articletxt a:hover {
    background: #36204D;
    color: #fff;
}

.newsviw-all {
    text-align: center;
    margin: 20px 0 0;
}

.newsviw-all a {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #696A75;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 54px;
    border: 1.14154px solid rgba(105, 106, 117, 0.3);
    border-radius: 6.84925px;
    margin: 0 auto;
    text-decoration: none;
}
.newsviw-all a:hover {
	color: #fff;
	background: #204D36;
}



@media (max-width: 1800px){
    .details-sec .container {
        max-width: 1200px;
    }
    .newsbnr-content .container {
        max-width: 1200px;
    }
    .detaslheding h3 {
        font-size: 52px;
        line-height: 62px;
    }
}
@media (max-width: 575px){

    .nwsbntxt h3 {
      font-size: 20px;
      line-height: 33px;
    }
    .nwsbntxt {
        padding: 15px;
    }
    .nwsbntxt .media em {
        font-size: 15px;
        padding-left: 8px;
    }
    .nwsbntxt .media i {
        margin-left: 14px;
    }
    .newscont h3 {
        font-size: 20px;
        line-height: 29px;
    }
    .newscont .media em {
        font-size: 15px;
        padding-left: 8px;
    }
    .newscont .media i {
        margin-left: 14px;
    }
    .news-box {
        padding: 10px;
    }
    .newscont .mediapic {
        width: 36px;
        height: 36px;
        flex: 0 0 36px;
    }
    .news-heading a {
        width: 104px;
        height: 50px;
        font-size: 17px;
    }
    .news-heading h2 {
        font-size: 24px
    }
    .newscont {
        padding: 15px 0;
    }
    .articletxt {
        padding: 15px;
    }
    .articletxt h3 {
        font-size: 30px;
    }
  
  }